import React from 'react';
import GardenHead from './components/GardenHead';
import './garden.css';

const Garden = (props) => {
  //Tools
  function nestedIncludes(arr, word) {
    let included = false;
    for (
      let wordArrayIndex = 0;
      wordArrayIndex < arr.length;
      wordArrayIndex++
    ) {
      const wordArray = arr[wordArrayIndex];

      if (wordArray.includes(word)) {
        included = true;
        break;
      }
    }

    return included;
  }

  //Generate plants
  //?Change to e.Hanzi
  const plant = props.plantsList.map((e, i) => (
    <div key={i} className="plant--pair">
      <div>
        <img
          src={require('../../../../images/plant.png')}
          alt=""
          className="plant--pair--image"
        />
      </div>

      <div className="plant--pair--word">{e}</div>
    </div>
  ));

  //Generate seeds

  const seed = props.seedsList
    .map((e, i) => {
      const rest = props.seedsList.slice(i + 1);

      if (nestedIncludes(rest, e[0])) {
        return 'dup';
      }

      return e;
    })
    .filter((e) => e !== 'dup')
    .map((e) => (
      <div key={e} className="seed--pair">
        <div>
          <img
            src={require('../../../../images/seed.png')}
            alt="seed"
            className="seed--pair--image"
          />
        </div>
        <div className="seed--pair--word">{e[0]}</div>
        <div className="seed--pair--detail">
          <div className="seed--pair--pinyin">{e[1]}</div>
          <div className="seed--pair--translation">{e[2]}</div>
        </div>
      </div>
    ));

  return (
    <section className="garden">
      <div className="known--words--container">
        <GardenHead
          streak={props.streak}
          plantsList={props.plantsList}
          seedsList={props.seedsList}
        />

        <div className="plant--container">{plant}</div>
      </div>

      <div className="unknown--words--container">
        <div className="garden--seedsList--head">
          <div className="garden--seedsList--title">
            Seeds ({props.seedsList.length})
          </div>
        </div>
        <div className="seed--container">{seed}</div>
      </div>
    </section>
  );
};

export default Garden;
