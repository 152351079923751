import React from 'react';
import './worddisplay.css';
const WordDisplay = ({ word }) => {
  return (
    <div className="word--display">
      <div className="word">{word}</div>
    </div>
  );
};

export default WordDisplay;
