import React from 'react';
import './wordmeta.css';
import { useHSKLevelContext } from '../../../../../../context/hskLevelContext';

const WordMeta = ({ handleSound, showDetailAndSelect, displayWord }) => {
  const { HSKLevel } = useHSKLevelContext();

  function handleCopy() {
    const word = `${displayWord.Hanzi} | ${displayWord.Pinyin} | ${displayWord.Definitions}`;
    navigator.clipboard.writeText(word);
  }

  return (
    <div className="word--meta--container">
      <div className="word--meta--hsk--level">HSK {HSKLevel}</div>

      {showDetailAndSelect && (
        <div className="word--meta--hidden--container">
          <img
            src={require('../../../../../../images/sound.png')}
            alt=""
            className="word--meta--sound"
            onClick={() => handleSound()}
          />
          <img
            src={require('../../../../../../images/copy.png')}
            alt=""
            className="word--meta--copy"
            onClick={handleCopy}
          ></img>
        </div>
      )}
    </div>
  );
};

export default WordMeta;
