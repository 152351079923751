// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCFcC1xQYo8Zk-aqqaWz6pDqgSeIOQ-_BU',
  authDomain: 'word-garden-91496.firebaseapp.com',
  projectId: 'word-garden-91496',
  storageBucket: 'word-garden-91496.appspot.com',
  messagingSenderId: '461618113857',
  appId: '1:461618113857:web:276e85738790541e8745c0',
  measurementId: 'G-X98G84WLWN',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);
