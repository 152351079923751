import { createContext, useContext, useState } from 'react';

const HSKLevelContext = createContext({
  HSKLevel: 0,
  updateHSKLevel: () => {},
});

export const useHSKLevelContext = () => {
  return useContext(HSKLevelContext);
};

export const HSKLevelProvider = ({ children }) => {
  const [HSKLevel, setHSKLevel] = useState(0);

  function toggleHSKLevel(num) {
    setHSKLevel(num);
  }

  return (
    <HSKLevelContext.Provider value={{ HSKLevel, toggleHSKLevel }}>
      {children}
    </HSKLevelContext.Provider>
  );
};
