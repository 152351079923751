const data = {
  1: [
    {
      Hanzi: '爱',
      Pinyin: 'ài',
      'Speech Part': 'v',
      Definitions: 'love, like',
      hskLevel: 1,
    },
    {
      Hanzi: '八',
      Pinyin: 'bā',
      'Speech Part': 'num',
      Definitions: 'eight',
      hskLevel: 1,
    },
    {
      Hanzi: '爸爸',
      Pinyin: 'bà ba',
      'Speech Part': 'n',
      Definitions: 'dad, father',
      hskLevel: 1,
    },
    {
      Hanzi: '杯子',
      Pinyin: 'bēi zi',
      'Speech Part': 'n',
      Definitions: 'cup, glass',
      hskLevel: 1,
    },
    {
      Hanzi: '北京',
      Pinyin: 'běi jīng',
      'Speech Part': 'n',
      Definitions: 'Beijing',
      hskLevel: 1,
    },
    {
      Hanzi: '本',
      Pinyin: 'běn',
      'Speech Part': 'm',
      Definitions: 'm. (for books)',
      hskLevel: 1,
    },
    {
      Hanzi: '不',
      Pinyin: 'bù',
      'Speech Part': 'adv',
      Definitions: 'not',
      hskLevel: 1,
    },
    {
      Hanzi: '不客气',
      Pinyin: 'bú kè qi',
      'Speech Part': '-',
      Definitions: 'you are welcome',
      hskLevel: 1,
    },
    {
      Hanzi: '菜',
      Pinyin: 'cài',
      'Speech Part': 'n',
      Definitions: 'vegetable',
      hskLevel: 1,
    },
    {
      Hanzi: '茶',
      Pinyin: 'chá',
      'Speech Part': 'n',
      Definitions: 'tea',
      hskLevel: 1,
    },
    {
      Hanzi: '吃',
      Pinyin: 'chī',
      'Speech Part': 'v',
      Definitions: 'eat',
      hskLevel: 1,
    },
    {
      Hanzi: '出租车',
      Pinyin: 'chū zū chē',
      'Speech Part': 'n',
      Definitions: 'taxi',
      hskLevel: 1,
    },
    {
      Hanzi: '打电话',
      Pinyin: 'dǎ diàn huà',
      'Speech Part': '-',
      Definitions: 'give somebody a phone call',
      hskLevel: 1,
    },
    {
      Hanzi: '大',
      Pinyin: 'dà',
      'Speech Part': 'adj',
      Definitions: 'big',
      hskLevel: 1,
    },
    {
      Hanzi: '的',
      Pinyin: 'de',
      'Speech Part': 'part',
      Definitions: 'possessive particle',
      hskLevel: 1,
    },
    {
      Hanzi: '点',
      Pinyin: 'diǎn',
      'Speech Part': 'm',
      Definitions: "o'clock",
      hskLevel: 1,
    },
    {
      Hanzi: '电脑',
      Pinyin: 'diàn nǎo',
      'Speech Part': 'n',
      Definitions: 'computer',
      hskLevel: 1,
    },
    {
      Hanzi: '电视',
      Pinyin: 'diàn shì',
      'Speech Part': 'n',
      Definitions: 'television',
      hskLevel: 1,
    },
    {
      Hanzi: '电影',
      Pinyin: 'diàn yǐng',
      'Speech Part': 'n',
      Definitions: 'movie',
      hskLevel: 1,
    },
    {
      Hanzi: '东西',
      Pinyin: 'dōng xi',
      'Speech Part': 'n',
      Definitions: 'thing',
      hskLevel: 1,
    },
    {
      Hanzi: '都',
      Pinyin: 'dōu',
      'Speech Part': 'adv',
      Definitions: 'all',
      hskLevel: 1,
    },
    {
      Hanzi: '读',
      Pinyin: 'dú',
      'Speech Part': 'v',
      Definitions: 'read',
      hskLevel: 1,
    },
    {
      Hanzi: '对不起',
      Pinyin: 'duì bu qǐ',
      'Speech Part': 'v',
      Definitions: 'sorry',
      hskLevel: 1,
    },
    {
      Hanzi: '多',
      Pinyin: 'duō',
      'Speech Part': 'adj, pron',
      Definitions: 'many, much; how, what',
      hskLevel: 1,
    },
    {
      Hanzi: '多少',
      Pinyin: 'duō shao',
      'Speech Part': 'pron',
      Definitions: 'how many, how much',
      hskLevel: 1,
    },
    {
      Hanzi: '儿子',
      Pinyin: 'ér zi',
      'Speech Part': 'n',
      Definitions: 'son',
      hskLevel: 1,
    },
    {
      Hanzi: '二',
      Pinyin: 'èr',
      'Speech Part': 'num',
      Definitions: 'two',
      hskLevel: 1,
    },
    {
      Hanzi: '饭店',
      Pinyin: 'fàn diàn',
      'Speech Part': 'n',
      Definitions: 'restaurant',
      hskLevel: 1,
    },
    {
      Hanzi: '飞机',
      Pinyin: 'fēi jī',
      'Speech Part': 'n',
      Definitions: 'plane',
      hskLevel: 1,
    },
    {
      Hanzi: '分钟',
      Pinyin: 'fēn zhōng',
      'Speech Part': 'm',
      Definitions: 'minute',
      hskLevel: 1,
    },
    {
      Hanzi: '高兴',
      Pinyin: 'gāo xìng',
      'Speech Part': 'adj',
      Definitions: 'happy',
      hskLevel: 1,
    },
    {
      Hanzi: '个',
      Pinyin: 'gè',
      'Speech Part': 'm',
      Definitions:
        '(used before a noun which does not have a fixed measure word of its own)',
      hskLevel: 1,
    },
    {
      Hanzi: '工作',
      Pinyin: 'gōng zuò',
      'Speech Part': 'v, n',
      Definitions: 'work',
      hskLevel: 1,
    },
    {
      Hanzi: '狗',
      Pinyin: 'gǒu',
      'Speech Part': 'n',
      Definitions: 'dog',
      hskLevel: 1,
    },
    {
      Hanzi: '汉语',
      Pinyin: 'hàn yǔ',
      'Speech Part': 'n',
      Definitions: 'Chinese',
      hskLevel: 1,
    },
    {
      Hanzi: '好',
      Pinyin: 'hǎo',
      'Speech Part': 'adj',
      Definitions: 'good',
      hskLevel: 1,
    },
    {
      Hanzi: '号',
      Pinyin: 'hào',
      'Speech Part': 'm',
      Definitions: 'date',
      hskLevel: 1,
    },
    {
      Hanzi: '喝',
      Pinyin: 'hē',
      'Speech Part': 'v',
      Definitions: 'drink',
      hskLevel: 1,
    },
    {
      Hanzi: '和',
      Pinyin: 'hé',
      'Speech Part': 'conj, prep',
      Definitions: 'and; to, with',
      hskLevel: 1,
    },
    {
      Hanzi: '很',
      Pinyin: 'hěn',
      'Speech Part': 'adv',
      Definitions: 'very',
      hskLevel: 1,
    },
    {
      Hanzi: '后面',
      Pinyin: 'hòu miàn',
      'Speech Part': 'n',
      Definitions: 'back, rear',
      hskLevel: 1,
    },
    {
      Hanzi: '回',
      Pinyin: 'huí',
      'Speech Part': 'v',
      Definitions: 'return, go back',
      hskLevel: 1,
    },
    {
      Hanzi: '会',
      Pinyin: 'huì',
      'Speech Part': 'v',
      Definitions: 'can',
      hskLevel: 1,
    },
    {
      Hanzi: '几',
      Pinyin: 'jǐ',
      'Speech Part': 'pron, num',
      Definitions: 'how many; some (between 2 and 9)',
      hskLevel: 1,
    },
    {
      Hanzi: '家',
      Pinyin: 'jiā',
      'Speech Part': 'n, m',
      Definitions: 'home, family; (used for families or enterprises)',
      hskLevel: 1,
    },
    {
      Hanzi: '叫',
      Pinyin: 'jiào',
      'Speech Part': 'v',
      Definitions: 'name',
      hskLevel: 1,
    },
    {
      Hanzi: '今天',
      Pinyin: 'jīn tiān',
      'Speech Part': 'n',
      Definitions: 'today',
      hskLevel: 1,
    },
    {
      Hanzi: '九',
      Pinyin: 'jiǔ',
      'Speech Part': 'num',
      Definitions: 'nine',
      hskLevel: 1,
    },
    {
      Hanzi: '开',
      Pinyin: 'kāi',
      'Speech Part': 'v',
      Definitions: 'open',
      hskLevel: 1,
    },
    {
      Hanzi: '看',
      Pinyin: 'kàn',
      'Speech Part': 'v',
      Definitions: 'watch',
      hskLevel: 1,
    },
    {
      Hanzi: '看见',
      Pinyin: 'kàn jiàn',
      'Speech Part': 'v',
      Definitions: 'see',
      hskLevel: 1,
    },
    {
      Hanzi: '块',
      Pinyin: 'kuài',
      'Speech Part': 'm',
      Definitions: 'piece',
      hskLevel: 1,
    },
    {
      Hanzi: '来',
      Pinyin: 'lái',
      'Speech Part': 'v',
      Definitions: 'come',
      hskLevel: 1,
    },
    {
      Hanzi: '老师',
      Pinyin: 'lǎo shī',
      'Speech Part': 'n',
      Definitions: 'teacher',
      hskLevel: 1,
    },
    {
      Hanzi: '了',
      Pinyin: 'le',
      'Speech Part': 'part',
      Definitions: 'past tense marker',
      hskLevel: 1,
    },
    {
      Hanzi: '冷',
      Pinyin: 'lěng',
      'Speech Part': 'adj',
      Definitions: 'cold',
      hskLevel: 1,
    },
    {
      Hanzi: '里',
      Pinyin: 'lǐ',
      'Speech Part': 'n',
      Definitions: 'inner, inside',
      hskLevel: 1,
    },
    {
      Hanzi: '六',
      Pinyin: 'liù',
      'Speech Part': 'num',
      Definitions: 'six',
      hskLevel: 1,
    },
    {
      Hanzi: '妈妈',
      Pinyin: 'mā ma',
      'Speech Part': 'n',
      Definitions: 'mom, mother',
      hskLevel: 1,
    },
    {
      Hanzi: '吗',
      Pinyin: 'ma',
      'Speech Part': 'part',
      Definitions: 'question particule',
      hskLevel: 1,
    },
    {
      Hanzi: '买',
      Pinyin: 'mǎi',
      'Speech Part': 'v',
      Definitions: 'buy',
      hskLevel: 1,
    },
    {
      Hanzi: '猫',
      Pinyin: 'māo',
      'Speech Part': 'n',
      Definitions: 'cat',
      hskLevel: 1,
    },
    {
      Hanzi: '没关系',
      Pinyin: 'méi guān xi',
      'Speech Part': '-',
      Definitions: "it doesn't matter, that's all right",
      hskLevel: 1,
    },
    {
      Hanzi: '没有',
      Pinyin: 'méi yǒu',
      'Speech Part': 'adv',
      Definitions: 'have not, did not',
      hskLevel: 1,
    },
    {
      Hanzi: '米饭',
      Pinyin: 'mǐ fàn',
      'Speech Part': 'n',
      Definitions: 'rice',
      hskLevel: 1,
    },
    {
      Hanzi: '名字',
      Pinyin: 'míng zi',
      'Speech Part': 'n',
      Definitions: 'name',
      hskLevel: 1,
    },
    {
      Hanzi: '明天',
      Pinyin: 'míng tiān',
      'Speech Part': 'n',
      Definitions: 'tomorrow',
      hskLevel: 1,
    },
    {
      Hanzi: '哪',
      Pinyin: 'nǎ',
      'Speech Part': 'pron',
      Definitions: 'which',
      hskLevel: 1,
    },
    {
      Hanzi: '哪儿',
      Pinyin: 'nǎ r',
      'Speech Part': 'pron',
      Definitions: 'where',
      hskLevel: 1,
    },
    {
      Hanzi: '那',
      Pinyin: 'nà',
      'Speech Part': 'pron',
      Definitions: 'that',
      hskLevel: 1,
    },
    {
      Hanzi: '呢',
      Pinyin: 'ne',
      'Speech Part': 'part',
      Definitions: '(question particule)',
      hskLevel: 1,
    },
    {
      Hanzi: '能',
      Pinyin: 'néng',
      'Speech Part': 'v',
      Definitions: 'can, be able to',
      hskLevel: 1,
    },
    {
      Hanzi: '你',
      Pinyin: 'nǐ',
      'Speech Part': 'pron',
      Definitions: 'you',
      hskLevel: 1,
    },
    {
      Hanzi: '年',
      Pinyin: 'nián',
      'Speech Part': 'n, m',
      Definitions: 'year',
      hskLevel: 1,
    },
    {
      Hanzi: '女儿',
      Pinyin: 'nǚ ér',
      'Speech Part': 'n',
      Definitions: 'daughter',
      hskLevel: 1,
    },
    {
      Hanzi: '朋友',
      Pinyin: 'péng you',
      'Speech Part': 'n',
      Definitions: 'friend',
      hskLevel: 1,
    },
    {
      Hanzi: '漂亮',
      Pinyin: 'piào liang',
      'Speech Part': 'adj',
      Definitions: 'beautiful',
      hskLevel: 1,
    },
    {
      Hanzi: '苹果',
      Pinyin: 'píng guǒ',
      'Speech Part': 'n',
      Definitions: 'apple',
      hskLevel: 1,
    },
    {
      Hanzi: '七',
      Pinyin: 'qī',
      'Speech Part': 'num',
      Definitions: 'seven',
      hskLevel: 1,
    },
    {
      Hanzi: '前面',
      Pinyin: 'qián miàn',
      'Speech Part': 'n',
      Definitions: 'front',
      hskLevel: 1,
    },
    {
      Hanzi: '钱',
      Pinyin: 'qián',
      'Speech Part': 'n',
      Definitions: 'money',
      hskLevel: 1,
    },
    {
      Hanzi: '请',
      Pinyin: 'qǐng',
      'Speech Part': 'v',
      Definitions: 'please',
      hskLevel: 1,
    },
    {
      Hanzi: '去',
      Pinyin: 'qù',
      'Speech Part': 'v',
      Definitions: 'go',
      hskLevel: 1,
    },
    {
      Hanzi: '热',
      Pinyin: 'rè',
      'Speech Part': 'adj',
      Definitions: 'hot',
      hskLevel: 1,
    },
    {
      Hanzi: '人',
      Pinyin: 'rén',
      'Speech Part': 'n',
      Definitions: 'person',
      hskLevel: 1,
    },
    {
      Hanzi: '认识',
      Pinyin: 'rèn shi',
      'Speech Part': 'v',
      Definitions: 'know',
      hskLevel: 1,
    },
    {
      Hanzi: '三',
      Pinyin: 'sān',
      'Speech Part': 'num',
      Definitions: 'three',
      hskLevel: 1,
    },
    {
      Hanzi: '商店',
      Pinyin: 'shāng diàn',
      'Speech Part': 'n',
      Definitions: 'store, shop',
      hskLevel: 1,
    },
    {
      Hanzi: '上',
      Pinyin: 'shàng',
      'Speech Part': 'n',
      Definitions: 'up, on',
      hskLevel: 1,
    },
    {
      Hanzi: '上午',
      Pinyin: 'shàng wǔ',
      'Speech Part': 'n',
      Definitions: 'morning',
      hskLevel: 1,
    },
    {
      Hanzi: '少',
      Pinyin: 'shǎo',
      'Speech Part': 'adj',
      Definitions: 'little, few',
      hskLevel: 1,
    },
    {
      Hanzi: '谁',
      Pinyin: 'shéi',
      'Speech Part': 'pron',
      Definitions: 'who',
      hskLevel: 1,
    },
    {
      Hanzi: '什么',
      Pinyin: 'shén me',
      'Speech Part': 'pron',
      Definitions: 'what',
      hskLevel: 1,
    },
    {
      Hanzi: '十',
      Pinyin: 'shí',
      'Speech Part': 'num',
      Definitions: 'ten',
      hskLevel: 1,
    },
    {
      Hanzi: '时候',
      Pinyin: 'shí hou',
      'Speech Part': 'n',
      Definitions: 'time, moment',
      hskLevel: 1,
    },
    {
      Hanzi: '是',
      Pinyin: 'shì',
      'Speech Part': 'v',
      Definitions: 'be',
      hskLevel: 1,
    },
    {
      Hanzi: '书',
      Pinyin: 'shū',
      'Speech Part': 'n',
      Definitions: 'book',
      hskLevel: 1,
    },
    {
      Hanzi: '水',
      Pinyin: 'shuǐ',
      'Speech Part': 'n',
      Definitions: 'water',
      hskLevel: 1,
    },
    {
      Hanzi: '水果',
      Pinyin: 'shuǐ guǒ',
      'Speech Part': 'n',
      Definitions: 'fruit',
      hskLevel: 1,
    },
    {
      Hanzi: '睡觉',
      Pinyin: 'shuì jiào',
      'Speech Part': 'v',
      Definitions: 'sleep',
      hskLevel: 1,
    },
    {
      Hanzi: '说',
      Pinyin: 'shuō',
      'Speech Part': 'v',
      Definitions: 'speak, talk, say',
      hskLevel: 1,
    },
    {
      Hanzi: '四',
      Pinyin: 'sì',
      'Speech Part': 'num',
      Definitions: 'four',
      hskLevel: 1,
    },
    {
      Hanzi: '岁',
      Pinyin: 'suì',
      'Speech Part': 'm',
      Definitions: 'year (of age)',
      hskLevel: 1,
    },
    {
      Hanzi: '他',
      Pinyin: 'tā',
      'Speech Part': 'pron',
      Definitions: 'he, him',
      hskLevel: 1,
    },
    {
      Hanzi: '她',
      Pinyin: 'tā',
      'Speech Part': 'pron',
      Definitions: 'she, her',
      hskLevel: 1,
    },
    {
      Hanzi: '太',
      Pinyin: 'tài',
      'Speech Part': 'adv',
      Definitions: 'too',
      hskLevel: 1,
    },
    {
      Hanzi: '天气',
      Pinyin: 'tiān qì',
      'Speech Part': 'n',
      Definitions: 'weather',
      hskLevel: 1,
    },
    {
      Hanzi: '听',
      Pinyin: 'tīng',
      'Speech Part': 'v',
      Definitions: 'listen',
      hskLevel: 1,
    },
    {
      Hanzi: '同学',
      Pinyin: 'tóng xué',
      'Speech Part': 'n',
      Definitions: 'classmate',
      hskLevel: 1,
    },
    {
      Hanzi: '喂',
      Pinyin: 'wèi',
      'Speech Part': 'int',
      Definitions: 'hello',
      hskLevel: 1,
    },
    {
      Hanzi: '我',
      Pinyin: 'wǒ',
      'Speech Part': 'pron',
      Definitions: 'I, me',
      hskLevel: 1,
    },
    {
      Hanzi: '我们',
      Pinyin: 'wǒ men',
      'Speech Part': 'pron',
      Definitions: 'we, us',
      hskLevel: 1,
    },
    {
      Hanzi: '五',
      Pinyin: 'wǔ',
      'Speech Part': 'num',
      Definitions: 'five',
      hskLevel: 1,
    },
    {
      Hanzi: '喜欢',
      Pinyin: 'xǐ huan',
      'Speech Part': 'v',
      Definitions: 'like',
      hskLevel: 1,
    },
    {
      Hanzi: '下',
      Pinyin: 'xià',
      'Speech Part': 'n',
      Definitions: 'under, below',
      hskLevel: 1,
    },
    {
      Hanzi: '下午',
      Pinyin: 'xià wǔ',
      'Speech Part': 'n',
      Definitions: 'afternoon',
      hskLevel: 1,
    },
    {
      Hanzi: '下雨',
      Pinyin: 'xià yǔ',
      'Speech Part': 'v',
      Definitions: 'rain',
      hskLevel: 1,
    },
    {
      Hanzi: '先生',
      Pinyin: 'xiān sheng',
      'Speech Part': 'n',
      Definitions: 'mister',
      hskLevel: 1,
    },
    {
      Hanzi: '现在',
      Pinyin: 'xiàn zài',
      'Speech Part': 'n',
      Definitions: 'now',
      hskLevel: 1,
    },
    {
      Hanzi: '想',
      Pinyin: 'xiǎng',
      'Speech Part': 'v',
      Definitions: 'want',
      hskLevel: 1,
    },
    {
      Hanzi: '小',
      Pinyin: 'xiǎo',
      'Speech Part': 'adj',
      Definitions: 'small, little',
      hskLevel: 1,
    },
    {
      Hanzi: '小姐',
      Pinyin: 'xiǎo jiě',
      'Speech Part': 'n',
      Definitions: 'miss',
      hskLevel: 1,
    },
    {
      Hanzi: '些',
      Pinyin: 'xiē',
      'Speech Part': 'm',
      Definitions: 'some',
      hskLevel: 1,
    },
    {
      Hanzi: '写',
      Pinyin: 'xiě',
      'Speech Part': 'v',
      Definitions: 'write',
      hskLevel: 1,
    },
    {
      Hanzi: '谢谢',
      Pinyin: 'xiè xie',
      'Speech Part': 'v',
      Definitions: 'thanks',
      hskLevel: 1,
    },
    {
      Hanzi: '星期',
      Pinyin: 'xīng qī',
      'Speech Part': 'n',
      Definitions: 'week',
      hskLevel: 1,
    },
    {
      Hanzi: '学生',
      Pinyin: 'xué shēng',
      'Speech Part': 'n',
      Definitions: 'student',
      hskLevel: 1,
    },
    {
      Hanzi: '学习',
      Pinyin: 'xué xí',
      'Speech Part': 'v',
      Definitions: 'study, learn',
      hskLevel: 1,
    },
    {
      Hanzi: '学校',
      Pinyin: 'xué xiào',
      'Speech Part': 'n',
      Definitions: 'school',
      hskLevel: 1,
    },
    {
      Hanzi: '一',
      Pinyin: 'yī',
      'Speech Part': 'num',
      Definitions: 'one',
      hskLevel: 1,
    },
    {
      Hanzi: '衣服',
      Pinyin: 'yī fu',
      'Speech Part': 'n',
      Definitions: 'clothes',
      hskLevel: 1,
    },
    {
      Hanzi: '医生',
      Pinyin: 'yī shēng',
      'Speech Part': 'n',
      Definitions: 'doctor',
      hskLevel: 1,
    },
    {
      Hanzi: '医院',
      Pinyin: 'yī yuàn',
      'Speech Part': 'n',
      Definitions: 'hospital',
      hskLevel: 1,
    },
    {
      Hanzi: '椅子',
      Pinyin: 'yǐ zi',
      'Speech Part': 'n',
      Definitions: 'chair',
      hskLevel: 1,
    },
    {
      Hanzi: '一点儿',
      Pinyin: 'yì diǎn r',
      'Speech Part': '-',
      Definitions: 'a bit, a little',
      hskLevel: 1,
    },
    {
      Hanzi: '有',
      Pinyin: 'yǒu',
      'Speech Part': 'v',
      Definitions: 'have',
      hskLevel: 1,
    },
    {
      Hanzi: '月',
      Pinyin: 'yuè',
      'Speech Part': 'n',
      Definitions: 'month',
      hskLevel: 1,
    },
    {
      Hanzi: '再见',
      Pinyin: 'zài jiàn',
      'Speech Part': 'v',
      Definitions: 'goodbye',
      hskLevel: 1,
    },
    {
      Hanzi: '在',
      Pinyin: 'zài',
      'Speech Part': 'v, prep',
      Definitions: 'be at (a place); in (a place)',
      hskLevel: 1,
    },
    {
      Hanzi: '怎么',
      Pinyin: 'zěn me',
      'Speech Part': 'pron',
      Definitions: 'how',
      hskLevel: 1,
    },
    {
      Hanzi: '怎么样',
      Pinyin: 'zěn me yàng',
      'Speech Part': 'pron',
      Definitions: 'how',
      hskLevel: 1,
    },
    {
      Hanzi: '这',
      Pinyin: 'zhè',
      'Speech Part': 'pron',
      Definitions: 'this',
      hskLevel: 1,
    },
    {
      Hanzi: '中国',
      Pinyin: 'zhōng guó',
      'Speech Part': 'n',
      Definitions: 'China',
      hskLevel: 1,
    },
    {
      Hanzi: '中午',
      Pinyin: 'zhōng wǔ',
      'Speech Part': 'n',
      Definitions: 'noon',
      hskLevel: 1,
    },
    {
      Hanzi: '住',
      Pinyin: 'zhù',
      'Speech Part': 'v',
      Definitions: 'live',
      hskLevel: 1,
    },
    {
      Hanzi: '桌子',
      Pinyin: 'zhuō zi',
      'Speech Part': 'n',
      Definitions: 'desk, table',
      hskLevel: 1,
    },
    {
      Hanzi: '字',
      Pinyin: 'zì',
      'Speech Part': 'n',
      Definitions: 'character',
      hskLevel: 1,
    },
    {
      Hanzi: '昨天',
      Pinyin: 'zuó tiān',
      'Speech Part': 'n',
      Definitions: 'yesterday',
      hskLevel: 1,
    },
    {
      Hanzi: '坐',
      Pinyin: 'zuò',
      'Speech Part': 'v',
      Definitions: 'sit',
      hskLevel: 1,
    },
    {
      Hanzi: '做',
      Pinyin: 'zuò',
      'Speech Part': 'v',
      Definitions: 'do',
      hskLevel: 1,
    },
  ],
  2: [
    {
      Hanzi: '吧',
      Pinyin: 'ba',
      'Speech Part': 'part',
      Definitions: '(suggestion or request particle)',
      hskLevel: 2,
    },
    {
      Hanzi: '白',
      Pinyin: 'bái',
      'Speech Part': 'adj',
      Definitions: 'white',
      hskLevel: 2,
    },
    {
      Hanzi: '百',
      Pinyin: 'bǎi',
      'Speech Part': 'num',
      Definitions: 'hundred',
      hskLevel: 2,
    },
    {
      Hanzi: '帮助',
      Pinyin: 'bāng zhù',
      'Speech Part': 'v',
      Definitions: 'help, assist',
      hskLevel: 2,
    },
    {
      Hanzi: '报纸',
      Pinyin: 'bào zhǐ',
      'Speech Part': 'n',
      Definitions: 'newspaper',
      hskLevel: 2,
    },
    {
      Hanzi: '比',
      Pinyin: 'bǐ',
      'Speech Part': 'prep',
      Definitions: 'than',
      hskLevel: 2,
    },
    {
      Hanzi: '别',
      Pinyin: 'bié',
      'Speech Part': 'adv',
      Definitions: "don't",
      hskLevel: 2,
    },
    {
      Hanzi: '宾馆',
      Pinyin: 'bīng guǎn',
      'Speech Part': 'n',
      Definitions: 'hotel',
      hskLevel: 2,
    },
    {
      Hanzi: '长',
      Pinyin: 'cháng',
      'Speech Part': 'adj',
      Definitions: 'long',
      hskLevel: 2,
    },
    {
      Hanzi: '唱歌',
      Pinyin: 'chàng gē',
      'Speech Part': 'v',
      Definitions: 'sing',
      hskLevel: 2,
    },
    {
      Hanzi: '出',
      Pinyin: 'chū',
      'Speech Part': 'v',
      Definitions: 'go out',
      hskLevel: 2,
    },
    {
      Hanzi: '穿',
      Pinyin: 'chuān',
      'Speech Part': 'v',
      Definitions: 'wear',
      hskLevel: 2,
    },
    {
      Hanzi: '次',
      Pinyin: 'cì',
      'Speech Part': 'm',
      Definitions: 'time',
      hskLevel: 2,
    },
    {
      Hanzi: '从',
      Pinyin: 'cóng',
      'Speech Part': 'prep',
      Definitions: 'from',
      hskLevel: 2,
    },
    {
      Hanzi: '错',
      Pinyin: 'cuò',
      'Speech Part': 'adj',
      Definitions: 'wrong',
      hskLevel: 2,
    },
    {
      Hanzi: '打篮球',
      Pinyin: 'dǎ lán qiú',
      'Speech Part': '-',
      Definitions: 'play basketball',
      hskLevel: 2,
    },
    {
      Hanzi: '大家',
      Pinyin: 'dà jiā',
      'Speech Part': 'pron',
      Definitions: 'everyone',
      hskLevel: 2,
    },
    {
      Hanzi: '但是',
      Pinyin: 'dàn shì',
      'Speech Part': 'conj',
      Definitions: 'but, still, yet',
      hskLevel: 2,
    },
    {
      Hanzi: '到',
      Pinyin: 'dào',
      'Speech Part': 'v',
      Definitions: 'arrive, reach',
      hskLevel: 2,
    },
    {
      Hanzi: '得',
      Pinyin: 'de',
      'Speech Part': 'part',
      Definitions: '(complement of result or degree)',
      hskLevel: 2,
    },
    {
      Hanzi: '弟弟',
      Pinyin: 'dì di',
      'Speech Part': 'n',
      Definitions: 'younger brother',
      hskLevel: 2,
    },
    {
      Hanzi: '第一',
      Pinyin: 'dì yī',
      'Speech Part': 'num',
      Definitions: 'first',
      hskLevel: 2,
    },
    {
      Hanzi: '懂',
      Pinyin: 'dǒng',
      'Speech Part': 'v',
      Definitions: 'understand',
      hskLevel: 2,
    },
    {
      Hanzi: '对',
      Pinyin: 'duì',
      'Speech Part': 'prep, adj',
      Definitions: 'to, towards; right, correct',
      hskLevel: 2,
    },
    {
      Hanzi: '房间',
      Pinyin: 'fáng jiān',
      'Speech Part': 'n',
      Definitions: 'room',
      hskLevel: 2,
    },
    {
      Hanzi: '非常',
      Pinyin: 'fēi cháng',
      'Speech Part': 'adv',
      Definitions: 'very',
      hskLevel: 2,
    },
    {
      Hanzi: '服务员',
      Pinyin: 'fú wù yuán',
      'Speech Part': 'n',
      Definitions: 'attendent, waiter/waitress',
      hskLevel: 2,
    },
    {
      Hanzi: '高',
      Pinyin: 'gāo',
      'Speech Part': 'adj',
      Definitions: 'tall, high',
      hskLevel: 2,
    },
    {
      Hanzi: '告诉',
      Pinyin: 'gào su',
      'Speech Part': 'v',
      Definitions: 'tell',
      hskLevel: 2,
    },
    {
      Hanzi: '哥哥',
      Pinyin: 'gē ge',
      'Speech Part': 'n',
      Definitions: 'elder brother',
      hskLevel: 2,
    },
    {
      Hanzi: '给',
      Pinyin: 'gěi',
      'Speech Part': 'v',
      Definitions: 'give',
      hskLevel: 2,
    },
    {
      Hanzi: '公共汽车',
      Pinyin: 'gōng gòng qì chē',
      'Speech Part': '-',
      Definitions: 'bus',
      hskLevel: 2,
    },
    {
      Hanzi: '公司',
      Pinyin: 'gōng sī',
      'Speech Part': 'n',
      Definitions: 'company',
      hskLevel: 2,
    },
    {
      Hanzi: '贵',
      Pinyin: 'guì',
      'Speech Part': 'adj',
      Definitions: 'expensive',
      hskLevel: 2,
    },
    {
      Hanzi: '过',
      Pinyin: 'guo',
      'Speech Part': 'part',
      Definitions: '(indication of past experience)',
      hskLevel: 2,
    },
    {
      Hanzi: '还',
      Pinyin: 'hái',
      'Speech Part': 'adv',
      Definitions: 'still',
      hskLevel: 2,
    },
    {
      Hanzi: '孩子',
      Pinyin: 'hái zi',
      'Speech Part': 'n',
      Definitions: 'child',
      hskLevel: 2,
    },
    {
      Hanzi: '好吃',
      Pinyin: 'hǎo chī',
      'Speech Part': 'adj',
      Definitions: 'delicious',
      hskLevel: 2,
    },
    {
      Hanzi: '黑',
      Pinyin: 'hēi',
      'Speech Part': 'adj',
      Definitions: 'black',
      hskLevel: 2,
    },
    {
      Hanzi: '红',
      Pinyin: 'hóng',
      'Speech Part': 'adj',
      Definitions: 'red',
      hskLevel: 2,
    },
    {
      Hanzi: '火车站',
      Pinyin: 'huǒ chē zhàn',
      'Speech Part': 'n',
      Definitions: 'train station',
      hskLevel: 2,
    },
    {
      Hanzi: '机场',
      Pinyin: 'jī chǎng',
      'Speech Part': 'n',
      Definitions: 'airport',
      hskLevel: 2,
    },
    {
      Hanzi: '鸡蛋',
      Pinyin: 'jī dàn',
      'Speech Part': 'n',
      Definitions: 'chicken egg',
      hskLevel: 2,
    },
    {
      Hanzi: '件',
      Pinyin: 'jiàn',
      'Speech Part': 'm',
      Definitions: 'piece ( for clothes and items)',
      hskLevel: 2,
    },
    {
      Hanzi: '教室',
      Pinyin: 'jiào shì',
      'Speech Part': 'n',
      Definitions: 'classroom',
      hskLevel: 2,
    },
    {
      Hanzi: '姐姐',
      Pinyin: 'jiě jie',
      'Speech Part': 'n',
      Definitions: 'elder sister',
      hskLevel: 2,
    },
    {
      Hanzi: '介绍',
      Pinyin: 'jiè shào',
      'Speech Part': 'v',
      Definitions: 'introduce',
      hskLevel: 2,
    },
    {
      Hanzi: '进',
      Pinyin: 'jìn',
      'Speech Part': 'v',
      Definitions: 'enter',
      hskLevel: 2,
    },
    {
      Hanzi: '近',
      Pinyin: 'jìn',
      'Speech Part': 'adj',
      Definitions: 'near, close',
      hskLevel: 2,
    },
    {
      Hanzi: '就',
      Pinyin: 'jiù',
      'Speech Part': 'adv',
      Definitions: 'already, at once',
      hskLevel: 2,
    },
    {
      Hanzi: '觉得',
      Pinyin: 'jué de',
      'Speech Part': 'v',
      Definitions: 'feel, think',
      hskLevel: 2,
    },
    {
      Hanzi: '咖啡',
      Pinyin: 'kā fēi',
      'Speech Part': 'n',
      Definitions: 'coffee',
      hskLevel: 2,
    },
    {
      Hanzi: '开始',
      Pinyin: 'kāi shǐ',
      'Speech Part': 'v',
      Definitions: 'begin, start',
      hskLevel: 2,
    },
    {
      Hanzi: '考试',
      Pinyin: 'kǎo shì',
      'Speech Part': 'n',
      Definitions: 'test, exam',
      hskLevel: 2,
    },
    {
      Hanzi: '可能',
      Pinyin: 'kě néng',
      'Speech Part': 'v',
      Definitions: 'maybe, probably',
      hskLevel: 2,
    },
    {
      Hanzi: '可以',
      Pinyin: 'kě yǐ',
      'Speech Part': 'v',
      Definitions: 'can, may',
      hskLevel: 2,
    },
    {
      Hanzi: '课',
      Pinyin: 'kè',
      'Speech Part': 'n',
      Definitions: 'class, lesson',
      hskLevel: 2,
    },
    {
      Hanzi: '快',
      Pinyin: 'kuài',
      'Speech Part': 'adj',
      Definitions: 'quick, fast',
      hskLevel: 2,
    },
    {
      Hanzi: '快乐',
      Pinyin: 'kuài lè',
      'Speech Part': 'adj',
      Definitions: 'happy',
      hskLevel: 2,
    },
    {
      Hanzi: '累',
      Pinyin: 'lèi',
      'Speech Part': 'adj',
      Definitions: 'tired',
      hskLevel: 2,
    },
    {
      Hanzi: '离',
      Pinyin: 'lí',
      'Speech Part': 'v',
      Definitions: 'be away from',
      hskLevel: 2,
    },
    {
      Hanzi: '两',
      Pinyin: 'liǎng',
      'Speech Part': 'num',
      Definitions: 'two',
      hskLevel: 2,
    },
    {
      Hanzi: '零',
      Pinyin: 'líng',
      'Speech Part': 'num',
      Definitions: 'zero',
      hskLevel: 2,
    },
    {
      Hanzi: '路',
      Pinyin: 'lù',
      'Speech Part': 'n',
      Definitions: 'road',
      hskLevel: 2,
    },
    {
      Hanzi: '旅游',
      Pinyin: 'lǚ yóu',
      'Speech Part': 'v',
      Definitions: 'travel',
      hskLevel: 2,
    },
    {
      Hanzi: '卖',
      Pinyin: 'mài',
      'Speech Part': 'v',
      Definitions: 'sell',
      hskLevel: 2,
    },
    {
      Hanzi: '慢',
      Pinyin: 'màn',
      'Speech Part': 'adj',
      Definitions: 'slow',
      hskLevel: 2,
    },
    {
      Hanzi: '忙',
      Pinyin: 'máng',
      'Speech Part': 'adj',
      Definitions: 'busy',
      hskLevel: 2,
    },
    {
      Hanzi: '每',
      Pinyin: 'měi',
      'Speech Part': 'pron',
      Definitions: 'every, each',
      hskLevel: 2,
    },
    {
      Hanzi: '妹妹',
      Pinyin: 'mèi mei',
      'Speech Part': 'n',
      Definitions: 'younger sister',
      hskLevel: 2,
    },
    {
      Hanzi: '门',
      Pinyin: 'mén',
      'Speech Part': 'n',
      Definitions: 'door',
      hskLevel: 2,
    },
    {
      Hanzi: '面条',
      Pinyin: 'miàn tiáo',
      'Speech Part': 'n',
      Definitions: 'noodle',
      hskLevel: 2,
    },
    {
      Hanzi: '男',
      Pinyin: 'nán',
      'Speech Part': 'adj',
      Definitions: 'man, male',
      hskLevel: 2,
    },
    {
      Hanzi: '您',
      Pinyin: 'nín',
      'Speech Part': 'pron',
      Definitions: 'you (formal)',
      hskLevel: 2,
    },
    {
      Hanzi: '牛奶',
      Pinyin: 'niú nǎi',
      'Speech Part': 'n',
      Definitions: 'milk',
      hskLevel: 2,
    },
    {
      Hanzi: '女',
      Pinyin: 'nǚ',
      'Speech Part': 'adj',
      Definitions: 'woman, female',
      hskLevel: 2,
    },
    {
      Hanzi: '旁边',
      Pinyin: 'páng biān',
      'Speech Part': 'n',
      Definitions: 'side',
      hskLevel: 2,
    },
    {
      Hanzi: '跑步',
      Pinyin: 'pǎo bù',
      'Speech Part': 'v',
      Definitions: 'run',
      hskLevel: 2,
    },
    {
      Hanzi: '便宜',
      Pinyin: 'pián yi',
      'Speech Part': 'adj',
      Definitions: 'cheap',
      hskLevel: 2,
    },
    {
      Hanzi: '票',
      Pinyin: 'piào',
      'Speech Part': 'n',
      Definitions: 'ticket',
      hskLevel: 2,
    },
    {
      Hanzi: '妻子',
      Pinyin: 'qī zi',
      'Speech Part': 'n',
      Definitions: 'wife',
      hskLevel: 2,
    },
    {
      Hanzi: '起床',
      Pinyin: 'qǐ chuáng',
      'Speech Part': 'v',
      Definitions: 'wake up',
      hskLevel: 2,
    },
    {
      Hanzi: '千',
      Pinyin: 'qiān',
      'Speech Part': 'num',
      Definitions: 'thousand',
      hskLevel: 2,
    },
    {
      Hanzi: '铅笔',
      Pinyin: 'qiān bǐ',
      'Speech Part': 'n',
      Definitions: 'pencil',
      hskLevel: 2,
    },
    {
      Hanzi: '晴',
      Pinyin: 'qíng',
      'Speech Part': 'adj',
      Definitions: 'sunny',
      hskLevel: 2,
    },
    {
      Hanzi: '去年',
      Pinyin: 'qù nián',
      'Speech Part': 'n',
      Definitions: 'last year',
      hskLevel: 2,
    },
    {
      Hanzi: '让',
      Pinyin: 'ràng',
      'Speech Part': 'v',
      Definitions: 'let',
      hskLevel: 2,
    },
    {
      Hanzi: '日',
      Pinyin: 'rì',
      'Speech Part': 'm',
      Definitions: 'day',
      hskLevel: 2,
    },
    {
      Hanzi: '上班',
      Pinyin: 'shàng bān',
      'Speech Part': 'v',
      Definitions: 'go to work',
      hskLevel: 2,
    },
    {
      Hanzi: '身体',
      Pinyin: 'shēng tǐ',
      'Speech Part': 'n',
      Definitions: 'body, health',
      hskLevel: 2,
    },
    {
      Hanzi: '生病',
      Pinyin: 'shēng bìng',
      'Speech Part': 'v',
      Definitions: 'fall ill',
      hskLevel: 2,
    },
    {
      Hanzi: '生日',
      Pinyin: 'shēng rì',
      'Speech Part': 'n',
      Definitions: 'birthday',
      hskLevel: 2,
    },
    {
      Hanzi: '时间',
      Pinyin: 'shí jiān',
      'Speech Part': 'n',
      Definitions: 'time',
      hskLevel: 2,
    },
    {
      Hanzi: '事情',
      Pinyin: 'shì qing',
      'Speech Part': 'n',
      Definitions: 'thing, matter, affair',
      hskLevel: 2,
    },
    {
      Hanzi: '手表',
      Pinyin: 'shǒu biǎo',
      'Speech Part': 'n',
      Definitions: 'wrist watch',
      hskLevel: 2,
    },
    {
      Hanzi: '手机',
      Pinyin: 'shǒu jī',
      'Speech Part': 'n',
      Definitions: 'mobile phone',
      hskLevel: 2,
    },
    {
      Hanzi: '说话',
      Pinyin: 'shuō huà',
      'Speech Part': 'v',
      Definitions: 'talk, speak',
      hskLevel: 2,
    },
    {
      Hanzi: '送',
      Pinyin: 'sòng',
      'Speech Part': 'v',
      Definitions: 'send, deliver, give (as gift)',
      hskLevel: 2,
    },
    {
      Hanzi: '虽然',
      Pinyin: 'suī rán',
      'Speech Part': 'conj',
      Definitions: 'although, though',
      hskLevel: 2,
    },
    {
      Hanzi: '所以',
      Pinyin: 'suǒ yǐ',
      'Speech Part': 'conj',
      Definitions: 'so, therefore',
      hskLevel: 2,
    },
    {
      Hanzi: '它',
      Pinyin: 'tā',
      'Speech Part': 'pron',
      Definitions: 'it',
      hskLevel: 2,
    },
    {
      Hanzi: '踢足球',
      Pinyin: 'tī zú qiú',
      'Speech Part': '-',
      Definitions: 'play football (soccer)',
      hskLevel: 2,
    },
    {
      Hanzi: '题',
      Pinyin: 'tí',
      'Speech Part': 'n',
      Definitions: 'question',
      hskLevel: 2,
    },
    {
      Hanzi: '跳舞',
      Pinyin: 'tiào wǔ',
      'Speech Part': 'v',
      Definitions: 'dance',
      hskLevel: 2,
    },
    {
      Hanzi: '外',
      Pinyin: 'wài',
      'Speech Part': 'n',
      Definitions: 'outside, outer',
      hskLevel: 2,
    },
    {
      Hanzi: '完',
      Pinyin: 'wán',
      'Speech Part': 'v',
      Definitions: 'finish',
      hskLevel: 2,
    },
    {
      Hanzi: '玩',
      Pinyin: 'wán',
      'Speech Part': 'v',
      Definitions: 'play',
      hskLevel: 2,
    },
    {
      Hanzi: '晚上',
      Pinyin: 'wǎn shang',
      'Speech Part': 'n',
      Definitions: 'evening, night',
      hskLevel: 2,
    },
    {
      Hanzi: '往',
      Pinyin: 'wǎng',
      'Speech Part': 'prep',
      Definitions: 'toward, to',
      hskLevel: 2,
    },
    {
      Hanzi: '为什么',
      Pinyin: 'wèi shén me',
      'Speech Part': '-',
      Definitions: 'why',
      hskLevel: 2,
    },
    {
      Hanzi: '问',
      Pinyin: 'wèn',
      'Speech Part': 'v',
      Definitions: 'ask',
      hskLevel: 2,
    },
    {
      Hanzi: '问题',
      Pinyin: 'wèn tí',
      'Speech Part': 'n',
      Definitions: 'question, problem',
      hskLevel: 2,
    },
    {
      Hanzi: '西瓜',
      Pinyin: 'xī guā',
      'Speech Part': 'n',
      Definitions: 'watermelon',
      hskLevel: 2,
    },
    {
      Hanzi: '希望',
      Pinyin: 'xī wàng',
      'Speech Part': 'v',
      Definitions: 'hope, wish',
      hskLevel: 2,
    },
    {
      Hanzi: '洗',
      Pinyin: 'xǐ',
      'Speech Part': 'v',
      Definitions: 'wash',
      hskLevel: 2,
    },
    {
      Hanzi: '小时',
      Pinyin: 'xiǎo shí',
      'Speech Part': 'n',
      Definitions: 'hour',
      hskLevel: 2,
    },
    {
      Hanzi: '笑',
      Pinyin: 'xiào',
      'Speech Part': 'v',
      Definitions: 'smile, laugh',
      hskLevel: 2,
    },
    {
      Hanzi: '新',
      Pinyin: 'xīn',
      'Speech Part': 'adj',
      Definitions: 'new',
      hskLevel: 2,
    },
    {
      Hanzi: '姓',
      Pinyin: 'xìng',
      'Speech Part': 'n, v',
      Definitions: 'family name; be surnamed',
      hskLevel: 2,
    },
    {
      Hanzi: '休息',
      Pinyin: 'xiū xi',
      'Speech Part': 'v',
      Definitions: 'take a rest',
      hskLevel: 2,
    },
    {
      Hanzi: '雪',
      Pinyin: 'xuě',
      'Speech Part': 'n',
      Definitions: 'snow',
      hskLevel: 2,
    },
    {
      Hanzi: '颜色',
      Pinyin: 'yán sè',
      'Speech Part': 'n',
      Definitions: 'color',
      hskLevel: 2,
    },
    {
      Hanzi: '眼睛',
      Pinyin: 'yǎn jing',
      'Speech Part': 'n',
      Definitions: 'eye',
      hskLevel: 2,
    },
    {
      Hanzi: '羊肉',
      Pinyin: 'yáng ròu',
      'Speech Part': 'n',
      Definitions: 'mutton',
      hskLevel: 2,
    },
    {
      Hanzi: '药',
      Pinyin: 'yào',
      'Speech Part': 'n',
      Definitions: 'medicine, drug',
      hskLevel: 2,
    },
    {
      Hanzi: '要',
      Pinyin: 'yào',
      'Speech Part': 'v',
      Definitions: 'want, must, shall',
      hskLevel: 2,
    },
    {
      Hanzi: '也',
      Pinyin: 'yě',
      'Speech Part': 'adv',
      Definitions: 'also, too',
      hskLevel: 2,
    },
    {
      Hanzi: '一下',
      Pinyin: 'yí xià',
      'Speech Part': '-',
      Definitions: 'once, a bit',
      hskLevel: 2,
    },
    {
      Hanzi: '已经',
      Pinyin: 'yǐ jīng',
      'Speech Part': 'adv',
      Definitions: 'already',
      hskLevel: 2,
    },
    {
      Hanzi: '一起',
      Pinyin: 'yì qǐ',
      'Speech Part': 'adv',
      Definitions: 'together',
      hskLevel: 2,
    },
    {
      Hanzi: '意思',
      Pinyin: 'yì si',
      'Speech Part': 'n',
      Definitions: 'meaning',
      hskLevel: 2,
    },
    {
      Hanzi: '因为',
      Pinyin: 'yīn wèi',
      'Speech Part': 'conj',
      Definitions: 'because',
      hskLevel: 2,
    },
    {
      Hanzi: '阴',
      Pinyin: 'yīn',
      'Speech Part': 'adj',
      Definitions: 'overcast, cloudy',
      hskLevel: 2,
    },
    {
      Hanzi: '游泳',
      Pinyin: 'yóu yǒng',
      'Speech Part': 'v',
      Definitions: 'swim',
      hskLevel: 2,
    },
    {
      Hanzi: '右边',
      Pinyin: 'yòu bian',
      'Speech Part': 'n',
      Definitions: 'right side',
      hskLevel: 2,
    },
    {
      Hanzi: '鱼',
      Pinyin: 'yú',
      'Speech Part': 'n',
      Definitions: 'fish',
      hskLevel: 2,
    },
    {
      Hanzi: '远',
      Pinyin: 'yuǎn',
      'Speech Part': 'adj',
      Definitions: 'far, distant',
      hskLevel: 2,
    },
    {
      Hanzi: '运动',
      Pinyin: 'yùn dòng',
      'Speech Part': 'n, v',
      Definitions: 'sports; do physical exercise',
      hskLevel: 2,
    },
    {
      Hanzi: '再',
      Pinyin: 'zài',
      'Speech Part': 'adv',
      Definitions: 'again',
      hskLevel: 2,
    },
    {
      Hanzi: '早上',
      Pinyin: 'zǎo shang',
      'Speech Part': 'n',
      Definitions: 'morning',
      hskLevel: 2,
    },
    {
      Hanzi: '丈夫',
      Pinyin: 'zhàng fu',
      'Speech Part': 'n',
      Definitions: 'husband',
      hskLevel: 2,
    },
    {
      Hanzi: '找',
      Pinyin: 'zhǎo',
      'Speech Part': 'v',
      Definitions: 'look for',
      hskLevel: 2,
    },
    {
      Hanzi: '着',
      Pinyin: 'zhe',
      'Speech Part': 'part',
      Definitions: '(used to indicate a state)',
      hskLevel: 2,
    },
    {
      Hanzi: '真',
      Pinyin: 'zhēn',
      'Speech Part': 'adv',
      Definitions: 'really, truly',
      hskLevel: 2,
    },
    {
      Hanzi: '正在',
      Pinyin: 'zhèng zài',
      'Speech Part': 'adv',
      Definitions: 'in the process of',
      hskLevel: 2,
    },
    {
      Hanzi: '知道',
      Pinyin: 'zhī dào',
      'Speech Part': 'v',
      Definitions: 'know',
      hskLevel: 2,
    },
    {
      Hanzi: '准备',
      Pinyin: 'zhǔn bèi',
      'Speech Part': 'v',
      Definitions: 'prepare, plan',
      hskLevel: 2,
    },
    {
      Hanzi: '走',
      Pinyin: 'zǒu',
      'Speech Part': 'v',
      Definitions: 'walk, go',
      hskLevel: 2,
    },
    {
      Hanzi: '只',
      Pinyin: 'zhī',
      'Speech Part': 'm',
      Definitions: '(for small boat/bird/animal)',
      hskLevel: 2,
    },
    {
      Hanzi: '最',
      Pinyin: 'zuì',
      'Speech Part': 'adv',
      Definitions: 'the most, to the greatest extent',
      hskLevel: 2,
    },
    {
      Hanzi: '左边',
      Pinyin: 'zuǒ bian',
      'Speech Part': 'n',
      Definitions: 'left side',
      hskLevel: 2,
    },
  ],
  3: [
    {
      Hanzi: '啊',
      Pinyin: 'a',
      Definitions:
        'modal particle ending sentence, showing affirmation, approval, or consent',
      hskLevel: 3,
    },
    {
      Hanzi: '阿姨',
      Pinyin: 'ā yí',
      Definitions:
        "maternal aunt; step-mother; childcare worker; nursemaid; woman of similar age to one's parents (term of address used by child); ",
      hskLevel: 3,
    },
    {
      Hanzi: '矮',
      Pinyin: 'ǎi',
      Definitions: 'low; short (in length)',
      hskLevel: 3,
    },
    {
      Hanzi: '爱好',
      Pinyin: 'ài hào',
      Definitions: 'interest; hobby; appetite for; ',
      hskLevel: 3,
    },
    {
      Hanzi: '安静',
      Pinyin: 'ān jìng',
      Definitions: 'quiet; peaceful; calm',
      hskLevel: 3,
    },
    {
      Hanzi: '把',
      Pinyin: 'bǎ',
      Definitions:
        'to hold; to contain; to grasp; to take hold of; a handle; particle marking the following noun as a direct object; classifier for objects with handle',
      hskLevel: 3,
    },
    {
      Hanzi: '搬',
      Pinyin: 'bān',
      Definitions:
        'to move; to shift; to remove; to transport; to apply indiscriminately; to copy mechanically',
      hskLevel: 3,
    },
    {
      Hanzi: '班',
      Pinyin: 'bān',
      Definitions:
        'team; class; squad; work shift; classifier for groups; ranking; surname Ban; ',
      hskLevel: 3,
    },
    {
      Hanzi: '办法',
      Pinyin: 'bàn fǎ',
      Definitions: 'means; method; way (of doing sth); ',
      hskLevel: 3,
    },
    {
      Hanzi: '办公室',
      Pinyin: 'bàn gōng shì',
      Definitions: 'an office; business premises; a bureau; ',
      hskLevel: 3,
    },
    {
      Hanzi: '半',
      Pinyin: 'bàn',
      Definitions: 'half; semi-; incomplete; (after a number) and a half',
      hskLevel: 3,
    },
    {
      Hanzi: '帮忙',
      Pinyin: 'bāng máng',
      Definitions: 'to help; to lend a hand; to do a favor; to do a good turn',
      hskLevel: 3,
    },
    {
      Hanzi: '包',
      Pinyin: 'bāo',
      Definitions:
        'to cover; to wrap; to hold; to include; to take charge of; package; wrapper; container; bag; to hold or embrace; bundle; packet; to contract (to or for); surname Bao; ',
      hskLevel: 3,
    },
    {
      Hanzi: '饱',
      Pinyin: 'bǎo',
      Definitions: 'to eat till full; satisfied',
      hskLevel: 3,
    },
    {
      Hanzi: '北方',
      Pinyin: 'běi fāng',
      Definitions:
        'north; the northern part a country; China north of the Yellow River',
      hskLevel: 3,
    },
    {
      Hanzi: '被',
      Pinyin: 'bèi',
      Definitions:
        'by (indicates passive-voice sentences or clauses); quilt; to cover (literary)',
      hskLevel: 3,
    },
    {
      Hanzi: '鼻子',
      Pinyin: 'bí zi',
      Definitions: 'nose; ',
      hskLevel: 3,
    },
    {
      Hanzi: '比较',
      Pinyin: 'bǐ jiào',
      Definitions:
        'compare; contrast; fairly; comparatively; relatively; quite; rather',
      hskLevel: 3,
    },
    {
      Hanzi: '比赛',
      Pinyin: 'bǐ sài',
      Definitions: 'competition (sports etc); match; ',
      hskLevel: 3,
    },
    {
      Hanzi: '笔记本',
      Pinyin: 'bǐ jì běn',
      Definitions: 'notebook; ',
      hskLevel: 3,
    },
    {
      Hanzi: '必须',
      Pinyin: 'bì xū',
      Definitions: 'to have to; must; compulsory; necessarily',
      hskLevel: 3,
    },
    {
      Hanzi: '变化',
      Pinyin: 'biàn huà',
      Definitions: 'change; variation; to change; to vary; ',
      hskLevel: 3,
    },
    { Hanzi: '别人', Pinyin: 'bié rén', Definitions: 'others', hskLevel: 3 },
    {
      Hanzi: '冰箱',
      Pinyin: 'bīng xiāng',
      Definitions: 'icebox; freezer cabinet; refrigerator; ',
      hskLevel: 3,
    },
    {
      Hanzi: '不但…而且…',
      Pinyin: '……            bù dàn …ér qiě …',
      Definitions: 'not only ... but also...',
      hskLevel: 3,
    },
    {
      Hanzi: '菜单',
      Pinyin: 'cài dān',
      Definitions: 'menu; ',
      hskLevel: 3,
    },
    {
      Hanzi: '参加',
      Pinyin: 'cān jiā',
      Definitions: 'to participate; to take part; to join',
      hskLevel: 3,
    },
    {
      Hanzi: '草',
      Pinyin: 'cǎo',
      Definitions:
        'grass; straw; manuscript; draft (of a document); careless; rough; ',
      hskLevel: 3,
    },
    {
      Hanzi: '层',
      Pinyin: 'céng',
      Definitions:
        'layer; stratum; laminated; floor (of a building); storey; classifier for layers; repeated; sheaf (math.)',
      hskLevel: 3,
    },
    {
      Hanzi: '差',
      Pinyin: 'chà',
      Definitions: 'differ from; short of; to lack; poor',
      hskLevel: 3,
    },
    {
      Hanzi: '超市',
      Pinyin: 'chāo shì',
      Definitions: 'supermarket (abbr.); ',
      hskLevel: 3,
    },
    {
      Hanzi: '衬衫',
      Pinyin: 'chèn shān',
      Definitions: 'shirt; blouse; ',
      hskLevel: 3,
    },
    {
      Hanzi: '城市',
      Pinyin: 'chéng shì',
      Definitions: 'city; town; ',
      hskLevel: 3,
    },
    {
      Hanzi: '成绩',
      Pinyin: 'chéng jì',
      Definitions: 'achievement; performance records; grades; ',
      hskLevel: 3,
    },
    {
      Hanzi: '迟到',
      Pinyin: 'chí dào',
      Definitions: 'to arrive late',
      hskLevel: 3,
    },
    {
      Hanzi: '除了',
      Pinyin: 'chú le',
      Definitions:
        'besides; apart from (... also...); in addition to; except (for)',
      hskLevel: 3,
    },
    {
      Hanzi: '船',
      Pinyin: 'chuán',
      Definitions: 'a boat; vessel; ship; ',
      hskLevel: 3,
    },
    {
      Hanzi: '春',
      Pinyin: 'chūn',
      Definitions: 'spring (time); gay; joyful; youthful; love; lust; life',
      hskLevel: 3,
    },
    {
      Hanzi: '词典',
      Pinyin: 'cí diǎn',
      Definitions: 'dictionary (of Chinese compound words); ',
      hskLevel: 3,
    },
    {
      Hanzi: '聪明',
      Pinyin: 'cōng ming',
      Definitions:
        'acute (of sight and hearing); clever; intelligent; bright; smart',
      hskLevel: 3,
    },
    {
      Hanzi: '打扫',
      Pinyin: 'dǎ sǎo',
      Definitions: 'to clean; to sweep',
      hskLevel: 3,
    },
    {
      Hanzi: '打算',
      Pinyin: 'dǎ suàn',
      Definitions:
        'to plan; to intend; to calculate; plan; intention; calculation; ',
      hskLevel: 3,
    },
    {
      Hanzi: '带',
      Pinyin: 'dài',
      Definitions:
        'band; belt; girdle; ribbon; tire; area; zone; region; to wear; to carry; to lead; to bring; to look after; to raise; ',
      hskLevel: 3,
    },
    {
      Hanzi: '担心',
      Pinyin: 'dān xīn',
      Definitions: 'anxious; worried; uneasy; to worry; to be anxious',
      hskLevel: 3,
    },
    {
      Hanzi: '蛋糕',
      Pinyin: 'dàn gāo',
      Definitions: 'cake; ',
      hskLevel: 3,
    },
    {
      Hanzi: '当然',
      Pinyin: 'dāng rán',
      Definitions:
        'of course; without doubt; only natural; as it should be; certainly;',
      hskLevel: 3,
    },
    {
      Hanzi: '地(助词)',
      Pinyin: 'de',
      Definitions:
        '-ly; structural particle: used before a verb or adjective, linking it to preceding modifying adverbial adjunct;',
      hskLevel: 3,
    },
    {
      Hanzi: '灯',
      Pinyin: 'dēng',
      Definitions: 'lamp; light; lantern; ',
      hskLevel: 3,
    },
    {
      Hanzi: '地方',
      Pinyin: 'dì fang',
      Definitions: 'region; regional (away from the central administration)',
      hskLevel: 3,
    },
    {
      Hanzi: '地铁',
      Pinyin: 'dì tiě',
      Definitions: 'subway; metro',
      hskLevel: 3,
    },
    {
      Hanzi: '地图',
      Pinyin: 'dì tú',
      Definitions: 'map; ',
      hskLevel: 3,
    },
    {
      Hanzi: '电梯',
      Pinyin: 'diàn tī',
      Definitions: 'elevator; ',
      hskLevel: 3,
    },
    {
      Hanzi: '电子邮件',
      Pinyin: 'diàn zǐ yóu jiàn',
      Definitions: 'electronic mail; email; ',
      hskLevel: 3,
    },
    {
      Hanzi: '东',
      Pinyin: 'dōng',
      Definitions:
        'east; host (i.e. sitting on east side of guest); landlord; surname Dong',
      hskLevel: 3,
    },
    { Hanzi: '冬', Pinyin: 'dōng', Definitions: 'winter', hskLevel: 3 },
    {
      Hanzi: '动物',
      Pinyin: 'dòng wù',
      Definitions: 'animal; ',
      hskLevel: 3,
    },
    {
      Hanzi: '短',
      Pinyin: 'duǎn',
      Definitions: 'short or brief; to lack; weak point; fault',
      hskLevel: 3,
    },
    {
      Hanzi: '段',
      Pinyin: 'duàn',
      Definitions:
        'paragraph; section; segment; stage (of a process); classifier for stories, periods of time, lengths of thread etc',
      hskLevel: 3,
    },
    {
      Hanzi: '锻炼',
      Pinyin: 'duàn liàn',
      Definitions: 'to engage in physical exercise; to toughen; to temper',
      hskLevel: 3,
    },
    {
      Hanzi: '多么',
      Pinyin: 'duō me',
      Definitions:
        'how (wonderful etc); what (a great idea etc); however (difficult it may be etc)',
      hskLevel: 3,
    },
    {
      Hanzi: '饿',
      Pinyin: 'è a',
      Definitions: 'to be hungry; hungry',
      hskLevel: 3,
    },
    {
      Hanzi: '耳朵',
      Pinyin: 'ěr duo',
      Definitions: 'ear; ',
      hskLevel: 3,
    },
    {
      Hanzi: '发',
      Pinyin: 'fā',
      Definitions:
        "to send out; to show (one's feeling); to issue; to develop; classifier for gunshots (rounds)",
      hskLevel: 3,
    },
    {
      Hanzi: '发烧',
      Pinyin: 'fā shāo',
      Definitions: 'have a high temperature (from illness); have a fever',
      hskLevel: 3,
    },
    {
      Hanzi: '发现',
      Pinyin: 'fā xiàn',
      Definitions: 'to find; to discover',
      hskLevel: 3,
    },
    {
      Hanzi: '方便',
      Pinyin: 'fāng biàn',
      Definitions:
        'convenient; to help out; to make things easy for people; convenience; suitable; having money to spare; (euphemism) to go to the toilet',
      hskLevel: 3,
    },
    {
      Hanzi: '放',
      Pinyin: 'fàng',
      Definitions:
        'to release; to free; to let go; to put; to place; to let out; to set off (fireworks)',
      hskLevel: 3,
    },
    {
      Hanzi: '放心',
      Pinyin: 'fàng xīn',
      Definitions: "to set one's mind at rest; to be at ease; to rest",
      hskLevel: 3,
    },
    {
      Hanzi: '分',
      Pinyin: 'fēn',
      Definitions:
        'to divide; to separate; to allocate; to distinguish (good and bad); part or subdivision; fraction; one tenth (of certain units); unit of length equivalent to 0.33 cm; minute; a point (in sports or games); 0.01 yuan (unit of money)',
      hskLevel: 3,
    },
    {
      Hanzi: '复习',
      Pinyin: 'fù xí',
      Definitions: 'to revise; to review; revision; ',
      hskLevel: 3,
    },
    {
      Hanzi: '附近',
      Pinyin: 'fù jìn',
      Definitions: '(in the) vicinity; nearby; neighboring; next to',
      hskLevel: 3,
    },
    {
      Hanzi: '干净',
      Pinyin: 'gān jìng',
      Definitions: 'neat and tidy; clean',
      hskLevel: 3,
    },
    {
      Hanzi: '感冒',
      Pinyin: 'gǎn mào',
      Definitions: 'to catch cold; (common) cold; ',
      hskLevel: 3,
    },
    {
      Hanzi: '感兴趣',
      Pinyin: 'gǎn xìng qù',
      Definitions: 'to be interested;',
      hskLevel: 3,
    },
    {
      Hanzi: '刚才',
      Pinyin: 'gāng cái',
      Definitions: 'just now; a moment ago',
      hskLevel: 3,
    },
    {
      Hanzi: '个子',
      Pinyin: 'gè zi',
      Definitions: 'height; stature; build; size',
      hskLevel: 3,
    },
    {
      Hanzi: '根据',
      Pinyin: 'gēn jù',
      Definitions: 'according to; based on; basis; foundation; ',
      hskLevel: 3,
    },
    {
      Hanzi: '跟',
      Pinyin: 'gēn',
      Definitions:
        'with (someone); towards; as (compared to); and (in addition to); heel; to follow closely; to go with; to marry sb (of woman)',
      hskLevel: 3,
    },
    {
      Hanzi: '更',
      Pinyin: 'gèng',
      Definitions: 'more; still more; even more',
      hskLevel: 3,
    },
    {
      Hanzi: '公斤',
      Pinyin: 'gōng jīn',
      Definitions: 'kilogram (kg)',
      hskLevel: 3,
    },
    {
      Hanzi: '公园',
      Pinyin: 'gōng yuán',
      Definitions: 'public park; ',
      hskLevel: 3,
    },
    {
      Hanzi: '故事',
      Pinyin: 'gù shi',
      Definitions: 'story; tale; narrative',
      hskLevel: 3,
    },
    {
      Hanzi: '刮风',
      Pinyin: 'guā fēng',
      Definitions: 'to be windy',
      hskLevel: 3,
    },
    {
      Hanzi: '关',
      Pinyin: 'guān',
      Definitions:
        'to close; to shut; to turn off; mountain pass; to concern; to involve',
      hskLevel: 3,
    },
    {
      Hanzi: '关系',
      Pinyin: 'guān xì',
      Definitions:
        'relationship (network); relation; to concern; to affect; to have to do with; guanxi; ',
      hskLevel: 3,
    },
    {
      Hanzi: '关心',
      Pinyin: 'guān xīn',
      Definitions: 'to care for sth; caring; concerned',
      hskLevel: 3,
    },
    {
      Hanzi: '关于',
      Pinyin: 'guān yú',
      Definitions:
        'pertaining to; concerning; regarding; with regards to; about; a matter of',
      hskLevel: 3,
    },
    {
      Hanzi: '国家',
      Pinyin: 'guó jiā',
      Definitions: 'country; nation; state; ',
      hskLevel: 3,
    },
    {
      Hanzi: '过(动词)',
      Pinyin: 'guò',
      Definitions: 'to cross; to go over; to pass (time); to get along',
      hskLevel: 3,
    },
    {
      Hanzi: '过去',
      Pinyin: 'guò qu',
      Definitions: '(in the) past; former; previous; to go over; to pass by',
      hskLevel: 3,
    },
    {
      Hanzi: '还是',
      Pinyin: 'hái shì',
      Definitions: 'or; still; nevertheless',
      hskLevel: 3,
    },
    {
      Hanzi: '害怕',
      Pinyin: 'hài pà',
      Definitions: 'to be afraid; to be scared',
      hskLevel: 3,
    },
    {
      Hanzi: '黑板',
      Pinyin: 'hēi bǎn',
      Definitions: 'blackboard; ',
      hskLevel: 3,
    },
    {
      Hanzi: '后来',
      Pinyin: 'hòu lái',
      Definitions: 'afterwards; later',
      hskLevel: 3,
    },
    {
      Hanzi: '护照',
      Pinyin: 'hù zhào',
      Definitions: 'passport; ',
      hskLevel: 3,
    },
    {
      Hanzi: '花(动词)',
      Pinyin: 'huā',
      Definitions: 'to blossom; to spend (money, time)',
      hskLevel: 3,
    },
    {
      Hanzi: '花(名词)',
      Pinyin: 'huā',
      Definitions: 'flower; blossoms; bloom: pattern: design',
      hskLevel: 3,
    },
    {
      Hanzi: '画',
      Pinyin: 'huà',
      Definitions: 'to draw; picture; painting; ',
      hskLevel: 3,
    },
    {
      Hanzi: '坏',
      Pinyin: 'huài',
      Definitions: 'bad; spoiled; broken; to break down',
      hskLevel: 3,
    },
    {
      Hanzi: '欢迎',
      Pinyin: 'huān yíng',
      Definitions: 'to welcome; welcome',
      hskLevel: 3,
    },
    {
      Hanzi: '环境',
      Pinyin: 'huán jìng',
      Definitions: 'environment; circumstances; surroundings; ambient; ',
      hskLevel: 3,
    },
    {
      Hanzi: '还(动词)',
      Pinyin: 'huán',
      Definitions: 'to come back; to give back; to return; to pay back',
      hskLevel: 3,
    },
    {
      Hanzi: '换',
      Pinyin: 'huàn',
      Definitions: 'change; exchange',
      hskLevel: 3,
    },
    {
      Hanzi: '黄河',
      Pinyin: 'huáng hé',
      Definitions: 'Yellow River',
      hskLevel: 3,
    },
    {
      Hanzi: '回答',
      Pinyin: 'huí dá',
      Definitions: 'to reply; to answer; the answer; ',
      hskLevel: 3,
    },
    {
      Hanzi: '会议',
      Pinyin: 'huì yì',
      Definitions: 'meeting; conference; ',
      hskLevel: 3,
    },
    {
      Hanzi: '或者',
      Pinyin: 'huò zhě',
      Definitions: 'or; possibly; maybe; perhaps',
      hskLevel: 3,
    },
    {
      Hanzi: '几乎',
      Pinyin: 'jī hū',
      Definitions: 'almost; nearly; practically',
      hskLevel: 3,
    },
    {
      Hanzi: '机会',
      Pinyin: 'jī huì',
      Definitions: 'opportunity; chance; occasion; ',
      hskLevel: 3,
    },
    {
      Hanzi: '极',
      Pinyin: 'jí',
      Definitions: 'extremely; pole (geography, physics); utmost; top',
      hskLevel: 3,
    },
    {
      Hanzi: '季节',
      Pinyin: 'jì jié',
      Definitions: 'time; season; period; ',
      hskLevel: 3,
    },
    { Hanzi: '记得', Pinyin: 'jì de', Definitions: 'to remember', hskLevel: 3 },
    {
      Hanzi: '检查',
      Pinyin: 'jiǎn chá',
      Definitions: 'inspection; to examine; to inspect; ',
      hskLevel: 3,
    },
    {
      Hanzi: '简单',
      Pinyin: 'jiǎn dān',
      Definitions: 'simple; not complicated',
      hskLevel: 3,
    },
    {
      Hanzi: '健康',
      Pinyin: 'jiàn kāng',
      Definitions: 'health; healthy',
      hskLevel: 3,
    },
    {
      Hanzi: '见面',
      Pinyin: 'jiàn miàn',
      Definitions: 'to meet; to see sb; ',
      hskLevel: 3,
    },
    {
      Hanzi: '讲',
      Pinyin: 'jiǎng',
      Definitions:
        'to explain; to speak; to negotiate; to emphasise; to be particular about; as far as sth is concerned; speech; lecture',
      hskLevel: 3,
    },
    { Hanzi: '教', Pinyin: 'jiào', Definitions: 'to teach', hskLevel: 3 },
    {
      Hanzi: '脚',
      Pinyin: 'jiǎo',
      Definitions: 'foot; leg; base; kick; ',
      hskLevel: 3,
    },
    {
      Hanzi: '角',
      Pinyin: 'jiǎo',
      Definitions:
        'horn; angle; corner ; horn-shaped; unit of money equal to 0.10 yuan; ',
      hskLevel: 3,
    },
    {
      Hanzi: '接',
      Pinyin: 'jiē',
      Definitions:
        "to receive; to answer (the phone); to meet or welcome sb; to connect; to catch; to join; to extend; to take one's turn on duty; take over for sb",
      hskLevel: 3,
    },
    {
      Hanzi: '街道',
      Pinyin: 'jiē dào',
      Definitions: 'street; ',
      hskLevel: 3,
    },
    {
      Hanzi: '结婚',
      Pinyin: 'jié hūn',
      Definitions: 'to marry; to get married; ',
      hskLevel: 3,
    },
    {
      Hanzi: '结束',
      Pinyin: 'jié shù',
      Definitions: 'to finish; to end; to conclude; to close; termination',
      hskLevel: 3,
    },
    {
      Hanzi: '节目',
      Pinyin: 'jié mù',
      Definitions: 'program; item (on a program); ',
      hskLevel: 3,
    },
    {
      Hanzi: '节日',
      Pinyin: 'jié rì',
      Definitions: 'holiday; festival; ',
      hskLevel: 3,
    },
    {
      Hanzi: '解决',
      Pinyin: 'jiě jué',
      Definitions: 'to settle (a dispute); to resolve; to solve',
      hskLevel: 3,
    },
    {
      Hanzi: '借',
      Pinyin: 'jiè',
      Definitions:
        'to lend; to borrow; excuse; pretext; by means of; to seize (an opportunity); to take (an opportunity)',
      hskLevel: 3,
    },
    {
      Hanzi: '经常',
      Pinyin: 'jīng cháng',
      Definitions:
        'day to day; everyday; daily; frequently; constantly; regularly; often',
      hskLevel: 3,
    },
    {
      Hanzi: '经过',
      Pinyin: 'jīng guò',
      Definitions: 'to pass; to go through; process; course; ',
      hskLevel: 3,
    },
    {
      Hanzi: '经理',
      Pinyin: 'jīng lǐ',
      Definitions: 'manager; director; ',
      hskLevel: 3,
    },
    {
      Hanzi: '久',
      Pinyin: 'jiǔ',
      Definitions: '(long) time; (long) duration of time',
      hskLevel: 3,
    },
    {
      Hanzi: '旧',
      Pinyin: 'jiù',
      Definitions: 'old; opposite: new 新; former; worn (with age)',
      hskLevel: 3,
    },
    {
      Hanzi: '句子',
      Pinyin: 'jù zi',
      Definitions: 'sentence; ',
      hskLevel: 3,
    },
    {
      Hanzi: '决定',
      Pinyin: 'jué dìng',
      Definitions:
        'to decide (to do something); to resolve; decision; certainly; ',
      hskLevel: 3,
    },
    {
      Hanzi: '可爱',
      Pinyin: 'kě ài',
      Definitions: 'amiable; cute; lovely',
      hskLevel: 3,
    },
    { Hanzi: '渴', Pinyin: 'kě', Definitions: 'thirsty', hskLevel: 3 },
    {
      Hanzi: '刻',
      Pinyin: 'kè',
      Definitions:
        'quarter (hour); moment; to carve; to engrave; to cut; oppressive; classifier for short time intervals',
      hskLevel: 3,
    },
    {
      Hanzi: '客人',
      Pinyin: 'kè rén',
      Definitions: 'visitor; guest; customer; client; ',
      hskLevel: 3,
    },
    {
      Hanzi: '空调',
      Pinyin: 'kōng tiáo',
      Definitions: 'air conditioning',
      hskLevel: 3,
    },
    {
      Hanzi: '口',
      Pinyin: 'kǒu',
      Definitions:
        'mouth; classifier for things with mouths (people, domestic animals, cannons, wells etc)',
      hskLevel: 3,
    },
    { Hanzi: '哭', Pinyin: 'kū', Definitions: 'to cry; to weep', hskLevel: 3 },
    {
      Hanzi: '裤子',
      Pinyin: 'kù zi',
      Definitions: 'trousers; pants; ',
      hskLevel: 3,
    },
    {
      Hanzi: '筷子',
      Pinyin: 'kuài zi',
      Definitions: 'chopsticks; ',
      hskLevel: 3,
    },
    {
      Hanzi: '蓝',
      Pinyin: 'lán',
      Definitions: 'blue; indigo plant',
      hskLevel: 3,
    },
    {
      Hanzi: '老',
      Pinyin: 'lǎo',
      Definitions:
        'old (of people); venerable (person); prefix used before the surname of a person or a numeral indicating the order of birth of the children in a family or to indicate affection or familiarity; experienced; of long standing; always; all the time; of the past; very; outdated; (of meat etc) tough',
      hskLevel: 3,
    },
    {
      Hanzi: '离开',
      Pinyin: 'lí kāi',
      Definitions: 'to depart; to leave',
      hskLevel: 3,
    },
    {
      Hanzi: '礼物',
      Pinyin: 'lǐ wù',
      Definitions: 'gift; present; ',
      hskLevel: 3,
    },
    {
      Hanzi: '历史',
      Pinyin: 'lì shǐ',
      Definitions: 'History; ',
      hskLevel: 3,
    },
    {
      Hanzi: '脸',
      Pinyin: 'liǎn',
      Definitions: 'face; ',
      hskLevel: 3,
    },
    {
      Hanzi: '练习',
      Pinyin: 'liàn xí',
      Definitions: 'exercise; drill; practice; ',
      hskLevel: 3,
    },
    {
      Hanzi: '辆',
      Pinyin: 'liàng',
      Definitions: 'classifier for vehicles',
      hskLevel: 3,
    },
    {
      Hanzi: '聊天',
      Pinyin: 'liáo tiān',
      Definitions: 'to chat; to gossip',
      hskLevel: 3,
    },
    {
      Hanzi: '了解',
      Pinyin: 'liǎo jiě',
      Definitions: 'to understand; to realize; to find out',
      hskLevel: 3,
    },
    {
      Hanzi: '邻居',
      Pinyin: 'lín jū',
      Definitions: 'neighbor; next door; ',
      hskLevel: 3,
    },
    {
      Hanzi: '留学',
      Pinyin: 'liú xué',
      Definitions: 'to study abroad',
      hskLevel: 3,
    },
    {
      Hanzi: '楼',
      Pinyin: 'lóu',
      Definitions: 'house with more than 1 story; storied building; floor; ',
      hskLevel: 3,
    },
    { Hanzi: '绿', Pinyin: 'lv4', Definitions: 'green', hskLevel: 3 },
    {
      Hanzi: '马',
      Pinyin: 'mǎ',
      Definitions: 'horse; abbr. for Malaysia,',
      hskLevel: 3,
    },
    {
      Hanzi: '马上',
      Pinyin: 'mǎ shàng',
      Definitions: 'right away; at once; immediately; lit. on horseback',
      hskLevel: 3,
    },
    {
      Hanzi: '满意',
      Pinyin: 'mǎn yì',
      Definitions: "satisfied; pleased; to one's satisfaction",
      hskLevel: 3,
    },
    {
      Hanzi: '帽子',
      Pinyin: 'mào zi',
      Definitions: 'hat; cap; ',
      hskLevel: 3,
    },
    {
      Hanzi: '米',
      Pinyin: 'mǐ',
      Definitions: 'rice; meter (classifier); ',
      hskLevel: 3,
    },
    {
      Hanzi: '面包',
      Pinyin: 'miàn bāo',
      Definitions: 'bread; ',
      hskLevel: 3,
    },
    {
      Hanzi: '明白',
      Pinyin: 'míng bai',
      Definitions:
        'to understand (clearly); to realize; clear; obvious; unequivocal;',
      hskLevel: 3,
    },
    {
      Hanzi: '拿',
      Pinyin: 'ná',
      Definitions: 'to hold; to seize; to catch; to apprehend; to take',
      hskLevel: 3,
    },
    {
      Hanzi: '奶奶',
      Pinyin: 'nǎi nai',
      Definitions: "(informal) father's mother; paternal grandmother; ",
      hskLevel: 3,
    },
    { Hanzi: '南', Pinyin: 'nán', Definitions: 'south', hskLevel: 3 },
    {
      Hanzi: '难',
      Pinyin: 'nán',
      Definitions:
        'difficult (to...); problem; difficulty; difficult; not good',
      hskLevel: 3,
    },
    {
      Hanzi: '难过',
      Pinyin: 'nán guò',
      Definitions: 'feel sorry; be grieved',
      hskLevel: 3,
    },
    {
      Hanzi: '年级',
      Pinyin: 'nián jí',
      Definitions: 'grade; ',
      hskLevel: 3,
    },
    { Hanzi: '年轻', Pinyin: 'nián qīng', Definitions: 'young', hskLevel: 3 },
    {
      Hanzi: '鸟',
      Pinyin: 'niǎo',
      Definitions: 'bird; ',
      hskLevel: 3,
    },
    {
      Hanzi: '努力',
      Pinyin: 'nǔ lì',
      Definitions: 'great effort; to strive; to try hard',
      hskLevel: 3,
    },
    {
      Hanzi: '爬山',
      Pinyin: 'pá shān',
      Definitions:
        'to climb a mountain; to mountaineer; hiking; mountaineering',
      hskLevel: 3,
    },
    {
      Hanzi: '盘子',
      Pinyin: 'pán zi',
      Definitions: 'plate; tray; dish',
      hskLevel: 3,
    },
    { Hanzi: '胖', Pinyin: 'pàng', Definitions: 'fat; plump', hskLevel: 3 },
    {
      Hanzi: '啤酒',
      Pinyin: 'pí jiǔ',
      Definitions: 'beer; ',
      hskLevel: 3,
    },
    {
      Hanzi: '皮鞋',
      Pinyin: 'pí xié',
      Definitions: 'leather shoes',
      hskLevel: 3,
    },
    {
      Hanzi: '瓶子',
      Pinyin: 'píng zi',
      Definitions: 'bottle; ',
      hskLevel: 3,
    },
    {
      Hanzi: '其实',
      Pinyin: 'qí shí',
      Definitions: 'actually; in fact; really',
      hskLevel: 3,
    },
    {
      Hanzi: '其他',
      Pinyin: 'qí tā',
      Definitions:
        'other; the others; else; other than (that person); in addition to the person mentioned above',
      hskLevel: 3,
    },
    {
      Hanzi: '奇怪',
      Pinyin: 'qí guài',
      Definitions: 'strange; odd',
      hskLevel: 3,
    },
    {
      Hanzi: '骑',
      Pinyin: 'qí',
      Definitions: 'to ride (an animal or bike); to sit astride',
      hskLevel: 3,
    },
    {
      Hanzi: '起飞',
      Pinyin: 'qǐ fēi',
      Definitions: 'to take off (in an airplane)',
      hskLevel: 3,
    },
    {
      Hanzi: '起来',
      Pinyin: 'qǐ lái',
      Definitions:
        'beginning or continuing an action; upward movement; stand up',
      hskLevel: 3,
    },
    {
      Hanzi: '清楚',
      Pinyin: 'qīng chu',
      Definitions: '（be) clear (about); clearly understood; clear; distinct',
      hskLevel: 3,
    },
    {
      Hanzi: '请假',
      Pinyin: 'qǐng jià',
      Definitions: 'ask for time off',
      hskLevel: 3,
    },
    {
      Hanzi: '秋',
      Pinyin: 'qiū',
      Definitions: 'autumn; fall; harvest time; a swing; surname Qiu',
      hskLevel: 3,
    },
    {
      Hanzi: '裙子',
      Pinyin: 'qún zi',
      Definitions: 'skirt; ',
      hskLevel: 3,
    },
    {
      Hanzi: '然后',
      Pinyin: 'rán hòu',
      Definitions: 'then (afterwards); after that; afterwards; after',
      hskLevel: 3,
    },
    {
      Hanzi: '热情',
      Pinyin: 'rè qíng',
      Definitions: 'cordial; enthusiastic; passion; passionate; passionately',
      hskLevel: 3,
    },
    {
      Hanzi: '认为',
      Pinyin: 'rèn wéi',
      Definitions: 'to believe; to think; to consider; to feel',
      hskLevel: 3,
    },
    {
      Hanzi: '认真',
      Pinyin: 'rèn zhēn',
      Definitions:
        'conscientious; earnest; serious; to take seriously; to take to heart',
      hskLevel: 3,
    },
    {
      Hanzi: '容易',
      Pinyin: 'róng yì',
      Definitions: 'easy; likely; liable (to)',
      hskLevel: 3,
    },
    {
      Hanzi: '如果',
      Pinyin: 'rú guǒ',
      Definitions: 'if; in case; in the event that',
      hskLevel: 3,
    },
    {
      Hanzi: '伞',
      Pinyin: 'sǎn',
      Definitions: 'umbrella; parasol; ',
      hskLevel: 3,
    },
    {
      Hanzi: '上网',
      Pinyin: 'shàng wǎng',
      Definitions:
        'to be on the internet; to stretch a net (in a sports game or for covering sth); to be netted (of fish)',
      hskLevel: 3,
    },
    {
      Hanzi: '声音',
      Pinyin: 'shēng yīn',
      Definitions: 'voice; sound; ',
      hskLevel: 3,
    },
    {
      Hanzi: '生气',
      Pinyin: 'shēng qì',
      Definitions:
        'angry; mad; offended; animated; to get angry; to be enraged; to take offense; animation',
      hskLevel: 3,
    },
    {
      Hanzi: '世界',
      Pinyin: 'shì jiè',
      Definitions: 'world; ',
      hskLevel: 3,
    },
    {
      Hanzi: '试',
      Pinyin: 'shì',
      Definitions: 'to test; to try; experiment; examination; test',
      hskLevel: 3,
    },
    {
      Hanzi: '瘦',
      Pinyin: 'shòu',
      Definitions: 'tight; thin; lean',
      hskLevel: 3,
    },
    {
      Hanzi: '叔叔',
      Pinyin: 'shū shu',
      Definitions: "father's younger brother; uncle; ",
      hskLevel: 3,
    },
    {
      Hanzi: '舒服',
      Pinyin: 'shū fu',
      Definitions: 'comfortable; feeling well',
      hskLevel: 3,
    },
    {
      Hanzi: '数学',
      Pinyin: 'shù xué',
      Definitions: 'mathematics; mathematical',
      hskLevel: 3,
    },
    {
      Hanzi: '树',
      Pinyin: 'shù',
      Definitions: 'tree; ',
      hskLevel: 3,
    },
    {
      Hanzi: '刷牙',
      Pinyin: 'shuā yá',
      Definitions: 'to brush teeth',
      hskLevel: 3,
    },
    {
      Hanzi: '双',
      Pinyin: 'shuāng',
      Definitions: 'pair; double; two; both',
      hskLevel: 3,
    },
    {
      Hanzi: '水平',
      Pinyin: 'shuǐ píng',
      Definitions: 'level (of achievement etc); standard; horizontal',
      hskLevel: 3,
    },
    {
      Hanzi: '司机',
      Pinyin: 'sī jī',
      Definitions: 'chauffeur; driver; ',
      hskLevel: 3,
    },
    {
      Hanzi: '太阳',
      Pinyin: 'tài yáng',
      Definitions: 'sun; ',
      hskLevel: 3,
    },
    {
      Hanzi: '特别',
      Pinyin: 'tè bié',
      Definitions: 'especially; special; particular; unusual',
      hskLevel: 3,
    },
    {
      Hanzi: '疼',
      Pinyin: 'téng',
      Definitions: '(it) hurts; love fondly; ache; pain; sore',
      hskLevel: 3,
    },
    {
      Hanzi: '提高',
      Pinyin: 'tí gāo',
      Definitions: 'to raise; to increase',
      hskLevel: 3,
    },
    {
      Hanzi: '体育',
      Pinyin: 'tǐ yù',
      Definitions: 'sports; physical education',
      hskLevel: 3,
    },
    { Hanzi: '甜', Pinyin: 'tián', Definitions: 'sweet', hskLevel: 3 },
    {
      Hanzi: '条',
      Pinyin: 'tiáo',
      Definitions:
        'strip; item; article; clause (of law or treaty); classifier for long thin things (ribbon, river, road, trousers etc)',
      hskLevel: 3,
    },
    {
      Hanzi: '同事',
      Pinyin: 'tóng shì',
      Definitions: 'colleague; co-worker; ',
      hskLevel: 3,
    },
    {
      Hanzi: '同意',
      Pinyin: 'tóng yì',
      Definitions: 'to agree; to consent; to approve',
      hskLevel: 3,
    },
    {
      Hanzi: '头发',
      Pinyin: 'tóu fa',
      Definitions: 'hair (on the head)',
      hskLevel: 3,
    },
    {
      Hanzi: '突然',
      Pinyin: 'tū rán',
      Definitions: 'sudden; abrupt; unexpected',
      hskLevel: 3,
    },
    {
      Hanzi: '图书馆',
      Pinyin: 'tú shū guǎn',
      Definitions: 'library; ',
      hskLevel: 3,
    },
    {
      Hanzi: '腿',
      Pinyin: 'tuǐ',
      Definitions: 'leg; ',
      hskLevel: 3,
    },
    {
      Hanzi: '完成',
      Pinyin: 'wán chéng',
      Definitions: 'complete; accomplish; perfect tense (grammar)',
      hskLevel: 3,
    },
    {
      Hanzi: '碗',
      Pinyin: 'wǎn',
      Definitions: 'bowl; cup; ',
      hskLevel: 3,
    },
    {
      Hanzi: '万',
      Pinyin: 'wàn',
      Definitions: 'ten thousand; a great number',
      hskLevel: 3,
    },
    { Hanzi: '忘记', Pinyin: 'wàng jì', Definitions: 'to forget', hskLevel: 3 },
    {
      Hanzi: '为',
      Pinyin: 'wèi',
      Definitions: 'because of; for; to',
      hskLevel: 3,
    },
    {
      Hanzi: '为了',
      Pinyin: 'wèi le',
      Definitions: 'in order to; for the purpose of; so as to',
      hskLevel: 3,
    },
    {
      Hanzi: '位',
      Pinyin: 'wèi',
      Definitions:
        'position; location; place; seat; classifier for people (honorific); classifier for binary bits (e.g. 十六位 16-bit or 2 bytes)',
      hskLevel: 3,
    },
    {
      Hanzi: '文化',
      Pinyin: 'wén huà',
      Definitions: 'culture; civilization; cultural; ',
      hskLevel: 3,
    },
    { Hanzi: '西', Pinyin: 'xī', Definitions: 'west', hskLevel: 3 },
    {
      Hanzi: '习惯',
      Pinyin: 'xí guàn',
      Definitions: 'habit; custom; usual practice; to be used to; ',
      hskLevel: 3,
    },
    {
      Hanzi: '洗手间',
      Pinyin: 'xǐ shǒu jiān',
      Definitions: 'toilet; lavatory; washroom',
      hskLevel: 3,
    },
    {
      Hanzi: '洗澡',
      Pinyin: 'xǐ zǎo',
      Definitions: 'to bathe; to take a shower',
      hskLevel: 3,
    },
    { Hanzi: '夏', Pinyin: 'xià', Definitions: 'summer', hskLevel: 3 },
    {
      Hanzi: '先',
      Pinyin: 'xiān',
      Definitions: 'early; prior; former; in advance; first',
      hskLevel: 3,
    },
    {
      Hanzi: '相信',
      Pinyin: 'xiāng xìn',
      Definitions:
        'to believe; to be convinced (that something is true); to accept sth as true',
      hskLevel: 3,
    },
    {
      Hanzi: '香蕉',
      Pinyin: 'xiāng jiāo',
      Definitions: 'banana; ',
      hskLevel: 3,
    },
    {
      Hanzi: '像',
      Pinyin: 'xiàng',
      Definitions:
        '(look) like; similar (to); appearance; to appear; to seem; image; portrait; resemble; seem',
      hskLevel: 3,
    },
    {
      Hanzi: '向',
      Pinyin: 'xiàng',
      Definitions:
        'direction; orientation; to face; to turn toward; to; towards; shortly before; formerly; to side with; to be partial to; all along (previously); surname Xiang',
      hskLevel: 3,
    },
    {
      Hanzi: '小心',
      Pinyin: 'xiǎo xīn',
      Definitions: 'to be careful; to take care',
      hskLevel: 3,
    },
    {
      Hanzi: '校长',
      Pinyin: 'xiào zhǎng',
      Definitions: '(college, university) president; headmaster; ',
      hskLevel: 3,
    },
    {
      Hanzi: '新闻',
      Pinyin: 'xīn wén',
      Definitions: 'news; ',
      hskLevel: 3,
    },
    {
      Hanzi: '新鲜',
      Pinyin: 'xīn xiān',
      Definitions: 'fresh (experience, food etc); freshness',
      hskLevel: 3,
    },
    {
      Hanzi: '信用卡',
      Pinyin: 'xìn yòng kǎ',
      Definitions: 'credit card',
      hskLevel: 3,
    },
    {
      Hanzi: '行李箱',
      Pinyin: 'xíng lǐ xiāng',
      Definitions: 'suitcase',
      hskLevel: 3,
    },
    {
      Hanzi: '熊猫',
      Pinyin: 'xióng māo',
      Definitions: 'panda; ',
      hskLevel: 3,
    },
    {
      Hanzi: '需要',
      Pinyin: 'xū yào',
      Definitions: 'to need; to want; to demand; needs; to require',
      hskLevel: 3,
    },
    {
      Hanzi: '选择',
      Pinyin: 'xuǎn zé',
      Definitions: 'to select; to pick; choice; option; alternative',
      hskLevel: 3,
    },
    {
      Hanzi: '要求',
      Pinyin: 'yāo qiú',
      Definitions:
        'to request; to require; to stake a claim; to ask; to demand; ',
      hskLevel: 3,
    },
    {
      Hanzi: '爷爷',
      Pinyin: 'yé ye',
      Definitions: "(informal) father's father; paternal grandfather; ",
      hskLevel: 3,
    },
    {
      Hanzi: '一直',
      Pinyin: 'yī zhí',
      Definitions:
        'straight (in a straight line); continuously; always; from the beginning of... up to...; all along',
      hskLevel: 3,
    },
    {
      Hanzi: '一定',
      Pinyin: 'yí dìng',
      Definitions:
        'surely; certainly; necessarily; fixed; a certain (extent etc); given; particular; must',
      hskLevel: 3,
    },
    {
      Hanzi: '一共',
      Pinyin: 'yī gòng',
      Definitions: 'altogether',
      hskLevel: 3,
    },
    {
      Hanzi: '一会儿',
      Pinyin: 'yī huì er',
      Definitions: 'a while',
      hskLevel: 3,
    },
    {
      Hanzi: '一样',
      Pinyin: 'yī yàng',
      Definitions: 'same; like; equal to; the same as; just like',
      hskLevel: 3,
    },
    {
      Hanzi: '以前',
      Pinyin: 'yǐ qián',
      Definitions: 'before; formerly; previous; ago',
      hskLevel: 3,
    },
    {
      Hanzi: '一般',
      Pinyin: 'yī bān',
      Definitions: 'ordinary; common; general; generally; in general; same',
      hskLevel: 3,
    },
    {
      Hanzi: '一边',
      Pinyin: 'yī biān',
      Definitions:
        'one side; either side; on the one hand; on the other hand; doing while',
      hskLevel: 3,
    },
    {
      Hanzi: '音乐',
      Pinyin: 'yīn yuè',
      Definitions: 'music; ',
      hskLevel: 3,
    },
    {
      Hanzi: '银行',
      Pinyin: 'yín háng',
      Definitions: 'bank; ',
      hskLevel: 3,
    },
    {
      Hanzi: '饮料',
      Pinyin: 'yǐn liào',
      Definitions: '(a) drink; beverage',
      hskLevel: 3,
    },
    {
      Hanzi: '应该',
      Pinyin: 'yīng gāi',
      Definitions: 'ought to; should; must',
      hskLevel: 3,
    },
    {
      Hanzi: '影响',
      Pinyin: 'yǐng xiǎng',
      Definitions:
        'influence; effect; to influence; to affect (usually adversely); to disturb; ',
      hskLevel: 3,
    },
    {
      Hanzi: '用',
      Pinyin: 'yòng',
      Definitions:
        'to use; to employ; to have to; to eat or drink; expense or outlay; usefulness; hence; therefore',
      hskLevel: 3,
    },
    {
      Hanzi: '游戏',
      Pinyin: 'yóu xì',
      Definitions: 'game; play; ',
      hskLevel: 3,
    },
    {
      Hanzi: '有名',
      Pinyin: 'yǒu míng',
      Definitions: 'famous; well-known',
      hskLevel: 3,
    },
    {
      Hanzi: '又',
      Pinyin: 'yòu',
      Definitions: '(once) again; also; both... and...; again',
      hskLevel: 3,
    },
    {
      Hanzi: '遇到',
      Pinyin: 'yù dào',
      Definitions: 'to meet; to run into; to come across',
      hskLevel: 3,
    },
    {
      Hanzi: '元',
      Pinyin: 'yuán',
      Definitions: 'Yuan (RMB); first; primary; basic',
      hskLevel: 3,
    },
    {
      Hanzi: '愿意',
      Pinyin: 'yuàn yì',
      Definitions: 'to wish; to want; ready; willing (to do sth)',
      hskLevel: 3,
    },
    { Hanzi: '月亮', Pinyin: 'yuè liang', Definitions: 'moon', hskLevel: 3 },
    {
      Hanzi: '越',
      Pinyin: 'yuè',
      Definitions: 'to exceed; to climb over; to surpass; the more... the more',
      hskLevel: 3,
    },
    {
      Hanzi: '站',
      Pinyin: 'zhàn',
      Definitions:
        'station; to stand; to halt; to stop; branch of a company or organisation',
      hskLevel: 3,
    },
    {
      Hanzi: '张',
      Pinyin: 'zhāng',
      Definitions:
        'classifier for flat objects, sheet; sheet of paper; classifier for votes; to open up; to spread;',
      hskLevel: 3,
    },
    {
      Hanzi: '长(动词)',
      Pinyin: 'zhǎng',
      Definitions:
        'elder; senior; to grow; to develop; to come into being; to acquire; to enhance; to increase',
      hskLevel: 3,
    },
    {
      Hanzi: '着急',
      Pinyin: 'zháo jí',
      Definitions: 'to worry; to feel anxious',
      hskLevel: 3,
    },
    {
      Hanzi: '照顾',
      Pinyin: 'zhào gu',
      Definitions:
        'to take care of; to show consideration; to attend to; to look after',
      hskLevel: 3,
    },
    {
      Hanzi: '照片',
      Pinyin: 'zhào piàn',
      Definitions: 'photo; photograph; picture; ',
      hskLevel: 3,
    },
    {
      Hanzi: '照相机',
      Pinyin: 'zhào xiàng jī',
      Definitions: 'camera; ',
      hskLevel: 3,
    },
    {
      Hanzi: '只(副词)',
      Pinyin: 'zhǐ',
      Definitions: 'only; just; merely',
      hskLevel: 3,
    },
    {
      Hanzi: '只(量词)',
      Pinyin: 'zhǐ',
      Definitions: 'single; one-only',
      hskLevel: 3,
    },
    {
      Hanzi: '只有…才…',
      Pinyin: '……            zhǐ yǒu …cái …',
      Definitions: 'only if… then…',
      hskLevel: 3,
    },
    {
      Hanzi: '中间',
      Pinyin: 'zhōng jiān',
      Definitions: 'between; intermediate; mid; middle',
      hskLevel: 3,
    },
    {
      Hanzi: '中文',
      Pinyin: 'zhōng wén',
      Definitions: 'Chinese written language; Chinese writing; Chinese',
      hskLevel: 3,
    },
    {
      Hanzi: '终于',
      Pinyin: 'zhōng yú',
      Definitions: 'at last; in the end; finally; eventually',
      hskLevel: 3,
    },
    {
      Hanzi: '种(量词)',
      Pinyin: 'zhǒng',
      Definitions: 'kind; sort; type',
      hskLevel: 3,
    },
    {
      Hanzi: '重要',
      Pinyin: 'zhòng yào',
      Definitions: 'important; significant; major',
      hskLevel: 3,
    },
    { Hanzi: '周末', Pinyin: 'zhōu mò', Definitions: 'weekend', hskLevel: 3 },
    {
      Hanzi: '主要',
      Pinyin: 'zhǔ yào',
      Definitions: 'main; principal; major; primary',
      hskLevel: 3,
    },
    {
      Hanzi: '注意',
      Pinyin: 'zhù yì',
      Definitions: 'to take note of; to pay attention to',
      hskLevel: 3,
    },
    {
      Hanzi: '自己',
      Pinyin: 'zì jǐ',
      Definitions: 'self; (reflexive pronoun); own',
      hskLevel: 3,
    },
    {
      Hanzi: '自行车',
      Pinyin: 'zì xíng chē',
      Definitions: 'bicycle; bike; ',
      hskLevel: 3,
    },
    { Hanzi: '总是', Pinyin: 'zǒng shì', Definitions: 'always', hskLevel: 3 },
    {
      Hanzi: '嘴',
      Pinyin: 'zuǐ',
      Definitions: 'mouth; beak; spout (of teapot etc); ',
      hskLevel: 3,
    },
    {
      Hanzi: '最后',
      Pinyin: 'zuì hòu',
      Definitions: 'final; last; finally; ultimate',
      hskLevel: 3,
    },
    {
      Hanzi: '最近',
      Pinyin: 'zuì jìn',
      Definitions:
        'recent; recently; these days; latest; soon; nearest (of locations); shortest (of routes)',
      hskLevel: 3,
    },
    {
      Hanzi: '作业',
      Pinyin: 'zuò yè',
      Definitions:
        'school assignment; homework; work; task; operation; to operate; ',
      hskLevel: 3,
    },
  ],
  4: [
    {
      Hanzi: '爱情',
      Pinyin: 'ài qíng',
      Definitions: 'romance; love (romantic); ',
      hskLevel: 4,
    },
    {
      Hanzi: '安排',
      Pinyin: 'ān pái',
      Definitions: 'to arrange; to plan; to set up',
      hskLevel: 4,
    },
    {
      Hanzi: '安全',
      Pinyin: 'ān quán',
      Definitions: 'safe; secure; safety; security',
      hskLevel: 4,
    },
    {
      Hanzi: '按时',
      Pinyin: 'àn shí',
      Definitions: 'on time; before deadline; on schedule',
      hskLevel: 4,
    },
    {
      Hanzi: '按照',
      Pinyin: 'àn zhào',
      Definitions:
        'according to; in accordance with; in the light of; on the basis of',
      hskLevel: 4,
    },
    {
      Hanzi: '百分之',
      Pinyin: 'bǎi fēn zhī',
      Definitions: 'percent;',
      hskLevel: 4,
    },
    {
      Hanzi: '棒',
      Pinyin: 'bàng',
      Definitions:
        'a stick; club or cudgel; smart; capable; strong; wonderful; classifier for legs of a relay race',
      hskLevel: 4,
    },
    {
      Hanzi: '包子',
      Pinyin: 'bāo zi',
      Definitions: 'steamed stuffed bun; ',
      hskLevel: 4,
    },
    {
      Hanzi: '保护',
      Pinyin: 'bǎo hù',
      Definitions: 'to protect; to defend; to safeguard; protection; ',
      hskLevel: 4,
    },
    {
      Hanzi: '保证',
      Pinyin: 'bǎo zhèng',
      Definitions:
        'guarantee; to guarantee; to ensure; to safeguard; to pledge; ',
      hskLevel: 4,
    },
    {
      Hanzi: '报名',
      Pinyin: 'bào míng',
      Definitions:
        "to sign up; to enter one's name; to apply; to register; to enroll; to enlist",
      hskLevel: 4,
    },
    {
      Hanzi: '抱',
      Pinyin: 'bào',
      Definitions:
        "to hold; to carry (in one's arms); to hug or embrace; surround; cherish",
      hskLevel: 4,
    },
    {
      Hanzi: '抱歉',
      Pinyin: 'bào qiàn',
      Definitions: 'sorry; We apologize!; to feel apologetic; to regret',
      hskLevel: 4,
    },
    {
      Hanzi: '倍',
      Pinyin: 'bèi',
      Definitions:
        '(two, three etc) -fold; times (multiplier); double; to increase or multiply',
      hskLevel: 4,
    },
    {
      Hanzi: '本来',
      Pinyin: 'běn lái',
      Definitions:
        'original; originally; at first; it goes without saying; of course',
      hskLevel: 4,
    },
    {
      Hanzi: '笨',
      Pinyin: 'bèn',
      Definitions: 'stupid; foolish; silly; slow-witted; clumsy',
      hskLevel: 4,
    },
    {
      Hanzi: '比如',
      Pinyin: 'bǐ rú',
      Definitions: 'for example; for instance; such as',
      hskLevel: 4,
    },
    {
      Hanzi: '毕业',
      Pinyin: 'bì yè',
      Definitions: 'to graduate; graduation; to finish school',
      hskLevel: 4,
    },
    {
      Hanzi: '遍',
      Pinyin: 'biàn',
      Definitions: 'a time; everywhere; turn; all over; one time',
      hskLevel: 4,
    },
    {
      Hanzi: '标准',
      Pinyin: 'biāo zhǔn',
      Definitions: '(an official) standard; norm; criterion; ',
      hskLevel: 4,
    },
    {
      Hanzi: '表格',
      Pinyin: 'biǎo gé',
      Definitions: 'form; table; ',
      hskLevel: 4,
    },
    {
      Hanzi: '表示',
      Pinyin: 'biǎo shì',
      Definitions:
        'to express; to show; to say; to state; to indicate; to mean',
      hskLevel: 4,
    },
    {
      Hanzi: '表演',
      Pinyin: 'biǎo yǎn',
      Definitions:
        'play; show; performance; exhibition; to perform; to act; to demonstrate; ',
      hskLevel: 4,
    },
    {
      Hanzi: '表扬',
      Pinyin: 'biǎo yáng',
      Definitions: 'to praise; to commend',
      hskLevel: 4,
    },
    {
      Hanzi: '饼干',
      Pinyin: 'bǐng gān',
      Definitions: 'biscuit; cracker; cookie; ',
      hskLevel: 4,
    },
    {
      Hanzi: '并且',
      Pinyin: 'bìng qiě',
      Definitions: 'moreover; furthermore; in addition; besides; and',
      hskLevel: 4,
    },
    {
      Hanzi: '博士',
      Pinyin: 'bó shì',
      Definitions: 'doctor; court academician (in feudal China); Ph.D.',
      hskLevel: 4,
    },
    {
      Hanzi: '不过',
      Pinyin: 'bú guò',
      Definitions:
        'only; merely; no more than; but; however; anyway (to get back to a previous topic)',
      hskLevel: 4,
    },
    {
      Hanzi: '不得不',
      Pinyin: 'bù dé bù',
      Definitions:
        "have no choice or option but to; cannot but; have to; can't help it; can't avoid",
      hskLevel: 4,
    },
    {
      Hanzi: '不管',
      Pinyin: 'bù guǎn',
      Definitions: 'no matter (what, how); regardless of; no matter',
      hskLevel: 4,
    },
    {
      Hanzi: '不仅',
      Pinyin: 'bù jǐn',
      Definitions: 'not only (this one); not just (...) but also',
      hskLevel: 4,
    },
    {
      Hanzi: '部分',
      Pinyin: 'bù fen',
      Definitions: 'part; share; section; piece; ',
      hskLevel: 4,
    },
    {
      Hanzi: '擦',
      Pinyin: 'cā',
      Definitions:
        'to wipe; to erase; rubbing (brush stroke in painting); to clean; to polish',
      hskLevel: 4,
    },
    {
      Hanzi: '猜',
      Pinyin: 'cāi',
      Definitions: 'to guess',
      hskLevel: 4,
    },
    {
      Hanzi: '材料',
      Pinyin: 'cái liào',
      Definitions: 'material; data; makings; stuff; ',
      hskLevel: 4,
    },
    {
      Hanzi: '参观',
      Pinyin: 'cān guān',
      Definitions: 'to look around; to inspect; visit and observe',
      hskLevel: 4,
    },
    {
      Hanzi: '餐厅',
      Pinyin: 'cān tīng',
      Definitions: 'dining-hall; dining-room; restaurant; ',
      hskLevel: 4,
    },
    {
      Hanzi: '厕所',
      Pinyin: 'cè suǒ',
      Definitions: 'toilet; lavatory; ',
      hskLevel: 4,
    },
    {
      Hanzi: '差不多',
      Pinyin: 'chà bu duō',
      Definitions: 'almost; nearly; more or less',
      hskLevel: 4,
    },
    {
      Hanzi: '尝',
      Pinyin: 'cháng',
      Definitions:
        'to try (to taste); to experience; flavor; (past tense marker); already; formerly; ever; once; test',
      hskLevel: 4,
    },
    {
      Hanzi: '长城',
      Pinyin: 'cháng chéng',
      Definitions: 'the Great Wall',
      hskLevel: 4,
    },
    {
      Hanzi: '长江',
      Pinyin: 'cháng jiāng',
      Definitions: 'Changjiang river; Yangtze river',
      hskLevel: 4,
    },
    {
      Hanzi: '场',
      Pinyin: 'chǎng',
      Definitions:
        'large place used for a specific purpose; stage; scene (of a play); threshing floor; classifier for sporting or recreational activities; classifier for number of exams',
      hskLevel: 4,
    },
    {
      Hanzi: '超过',
      Pinyin: 'chāo guò',
      Definitions: 'to surpass; to exceed; to outstrip',
      hskLevel: 4,
    },
    {
      Hanzi: '乘坐',
      Pinyin: 'chéng zuò',
      Definitions: 'to ride (in a vehicle)',
      hskLevel: 4,
    },
    {
      Hanzi: '成功',
      Pinyin: 'chéng gōng',
      Definitions: 'success; to succeed; ',
      hskLevel: 4,
    },
    {
      Hanzi: '成为',
      Pinyin: 'chéng wéi',
      Definitions: 'to become; to turn into',
      hskLevel: 4,
    },
    {
      Hanzi: '诚实',
      Pinyin: 'chéng shí',
      Definitions: 'honest; honesty; honorable; truthful',
      hskLevel: 4,
    },
    {
      Hanzi: '吃惊',
      Pinyin: 'chī jīng',
      Definitions: 'to be startled; to be shocked; to be amazed',
      hskLevel: 4,
    },
    {
      Hanzi: '重新',
      Pinyin: 'chóng xīn',
      Definitions: 'again; once more; re-',
      hskLevel: 4,
    },
    {
      Hanzi: '抽烟',
      Pinyin: 'chōu yān',
      Definitions: 'to smoke (a cigarette, tobacco)',
      hskLevel: 4,
    },
    {
      Hanzi: '出差',
      Pinyin: 'chū chāi',
      Definitions: 'to go on an official or business trip',
      hskLevel: 4,
    },
    {
      Hanzi: '出发',
      Pinyin: 'chū fā',
      Definitions: 'to start out; to set off',
      hskLevel: 4,
    },
    {
      Hanzi: '出生',
      Pinyin: 'chū shēng',
      Definitions: 'to be born',
      hskLevel: 4,
    },
    {
      Hanzi: '出现',
      Pinyin: 'chū xiàn',
      Definitions: 'to appear; to arise; to emerge; to show up',
      hskLevel: 4,
    },
    {
      Hanzi: '厨房',
      Pinyin: 'chú fáng',
      Definitions: 'kitchen; ',
      hskLevel: 4,
    },
    {
      Hanzi: '传真',
      Pinyin: 'chuán zhēn',
      Definitions: 'fax; facsimile',
      hskLevel: 4,
    },
    {
      Hanzi: '窗户',
      Pinyin: 'chuāng hu',
      Definitions: 'window; ',
      hskLevel: 4,
    },
    {
      Hanzi: '词语',
      Pinyin: 'cí yǔ',
      Definitions:
        'word (general term including monosyllables through to short phrases); term (e.g. technical term); expression',
      hskLevel: 4,
    },
    {
      Hanzi: '从来',
      Pinyin: 'cóng lái',
      Definitions: 'always; at all times; never (if used in negative sentence)',
      hskLevel: 4,
    },
    {
      Hanzi: '粗心',
      Pinyin: 'cū xīn',
      Definitions: 'careless; thoughtless',
      hskLevel: 4,
    },
    {
      Hanzi: '存',
      Pinyin: 'cún',
      Definitions: 'exist; deposit; store; keep; survive',
      hskLevel: 4,
    },
    {
      Hanzi: '错误',
      Pinyin: 'cuò wù',
      Definitions: 'error; mistake; mistaken; ',
      hskLevel: 4,
    },
    {
      Hanzi: '答案',
      Pinyin: 'dá àn',
      Definitions: 'answer; solution; ',
      hskLevel: 4,
    },
    {
      Hanzi: '打扮',
      Pinyin: 'dǎ ban',
      Definitions:
        'to decorate; to dress; to make up; to adorn; manner of dressing; style of dress',
      hskLevel: 4,
    },
    {
      Hanzi: '打扰',
      Pinyin: 'dǎ rǎo',
      Definitions: 'to disturb; to bother; to trouble',
      hskLevel: 4,
    },
    {
      Hanzi: '打印',
      Pinyin: 'dǎ yìn',
      Definitions: 'to print; to seal; to stamp',
      hskLevel: 4,
    },
    {
      Hanzi: '打招呼',
      Pinyin: 'dǎ zhāo hu',
      Definitions: 'to greet sb by word or action; to give prior notice',
      hskLevel: 4,
    },
    {
      Hanzi: '打折',
      Pinyin: 'dǎ zhé',
      Definitions: 'to give a discount',
      hskLevel: 4,
    },
    {
      Hanzi: '打针',
      Pinyin: 'dǎ zhēn',
      Definitions: 'to give or have an injection',
      hskLevel: 4,
    },
    {
      Hanzi: '大概',
      Pinyin: 'dà gài',
      Definitions: 'roughly; probably; rough; approximate; about',
      hskLevel: 4,
    },
    {
      Hanzi: '大使馆',
      Pinyin: 'dà shǐ guǎn',
      Definitions: 'embassy; ',
      hskLevel: 4,
    },
    {
      Hanzi: '大约',
      Pinyin: 'dà yuē',
      Definitions: 'approximately; about',
      hskLevel: 4,
    },
    {
      Hanzi: '大夫',
      Pinyin: 'dài fu',
      Definitions: 'doctor; minister of state (in pre-Han states); ',
      hskLevel: 4,
    },
    {
      Hanzi: '戴',
      Pinyin: 'dài',
      Definitions:
        'to put on or wear (glasses, hat, gloves etc); to respect; to bear; to support',
      hskLevel: 4,
    },
    {
      Hanzi: '当',
      Pinyin: 'dāng',
      Definitions:
        'to be; manage; withstand; when; during; ought; should; match equally; equal; same; obstruct; just at (a time or place); on the spot; right; just at',
      hskLevel: 4,
    },
    {
      Hanzi: '当时',
      Pinyin: 'dāng shí',
      Definitions: 'then; at that time; while',
      hskLevel: 4,
    },
    {
      Hanzi: '刀',
      Pinyin: 'dāo',
      Definitions: 'knife; ',
      hskLevel: 4,
    },
    {
      Hanzi: '导游',
      Pinyin: 'dǎo yóu',
      Definitions: 'tour guide; to conduct a tour; a guidebook',
      hskLevel: 4,
    },
    {
      Hanzi: '倒',
      Pinyin: 'dào',
      Definitions: 'to move backwards; converse',
      hskLevel: 4,
    },
    {
      Hanzi: '到处',
      Pinyin: 'dào chù',
      Definitions: 'in all places; everywhere',
      hskLevel: 4,
    },
    {
      Hanzi: '到底',
      Pinyin: 'dào dǐ',
      Definitions:
        'finally; in the end; when all is said and done; after all; to the end; to the last',
      hskLevel: 4,
    },
    {
      Hanzi: '道歉',
      Pinyin: 'dào qiàn',
      Definitions: 'to apologize; to make an apology',
      hskLevel: 4,
    },
    {
      Hanzi: '得(助动词)',
      Pinyin: 'dé',
      Definitions:
        'to have to, to need to (particle used to express capability, possibility, degree, effect...); must; ought to; to need to',
      hskLevel: 4,
    },
    {
      Hanzi: '得意',
      Pinyin: 'dé yì',
      Definitions: 'proud of oneself; pleased with oneself; complacent',
      hskLevel: 4,
    },
    {
      Hanzi: '登机牌',
      Pinyin: 'dēng jī pái',
      Definitions: 'boarding pass',
      hskLevel: 4,
    },
    {
      Hanzi: '等(助词)',
      Pinyin: 'děng',
      Definitions: 'etc (indicating the end of an enumeration)',
      hskLevel: 4,
    },
    {
      Hanzi: '低',
      Pinyin: 'dī',
      Definitions:
        "low; beneath; to lower (one's head); to let droop; to hang down; to incline",
      hskLevel: 4,
    },
    {
      Hanzi: '底',
      Pinyin: 'dǐ',
      Definitions:
        'background; bottom; base; the end of a period of time; towards the end of (last month)',
      hskLevel: 4,
    },
    {
      Hanzi: '地点',
      Pinyin: 'dì diǎn',
      Definitions: 'place; site; location; venue; ',
      hskLevel: 4,
    },
    {
      Hanzi: '地球',
      Pinyin: 'dì qiú',
      Definitions: 'the Earth; planet; ',
      hskLevel: 4,
    },
    {
      Hanzi: '地址',
      Pinyin: 'dì zhǐ',
      Definitions: 'address; ',
      hskLevel: 4,
    },
    {
      Hanzi: '掉',
      Pinyin: 'diào',
      Definitions:
        'to fall; to drop; to lag behind; to lose; to go missing; to reduce; fall (in prices); to lose (value, weight etc); to wag; to swing; to turn; to change; to exchange; to swap; to show off; to shed (hair)',
      hskLevel: 4,
    },
    {
      Hanzi: '调查',
      Pinyin: 'diào chá',
      Definitions:
        'investigation; inquiry; to investigate; to survey; survey; (opinion) poll; ',
      hskLevel: 4,
    },
    {
      Hanzi: '丢',
      Pinyin: 'diū',
      Definitions: 'to lose; to put aside; to throw',
      hskLevel: 4,
    },
    {
      Hanzi: '动作',
      Pinyin: 'dòng zuò',
      Definitions: 'movement; motion; action; ',
      hskLevel: 4,
    },
    {
      Hanzi: '堵车',
      Pinyin: 'dǔ chē',
      Definitions: 'traffic jam; choking',
      hskLevel: 4,
    },
    {
      Hanzi: '肚子',
      Pinyin: 'dù zi',
      Definitions: 'belly; abdomen; stomach; ',
      hskLevel: 4,
    },
    {
      Hanzi: '短信',
      Pinyin: 'duǎn xìn',
      Definitions: 'text message; SMS',
      hskLevel: 4,
    },
    {
      Hanzi: '对话',
      Pinyin: 'duì huà',
      Definitions: 'dialog; ',
      hskLevel: 4,
    },
    {
      Hanzi: '对面',
      Pinyin: 'duì miàn',
      Definitions: 'opposite',
      hskLevel: 4,
    },
    {
      Hanzi: '对于',
      Pinyin: 'duì yú',
      Definitions: 'regarding; as far as sth is concerned; with regards to',
      hskLevel: 4,
    },
    {
      Hanzi: '儿童',
      Pinyin: 'ér tóng',
      Definitions: 'child; ',
      hskLevel: 4,
    },
    {
      Hanzi: '而',
      Pinyin: 'ér',
      Definitions:
        'yet (not); and; as well as; and so; but (not); (indicates causal relation); (indicates change of state); (indicates contrast)',
      hskLevel: 4,
    },
    {
      Hanzi: '发生',
      Pinyin: 'fā shēng',
      Definitions: 'to happen; to occur; to take place; to break out',
      hskLevel: 4,
    },
    {
      Hanzi: '发展',
      Pinyin: 'fā zhǎn',
      Definitions: 'development; growth; to develop; to grow; to expand',
      hskLevel: 4,
    },
    {
      Hanzi: '法律',
      Pinyin: 'fǎ lv4',
      Definitions: 'law; ',
      hskLevel: 4,
    },
    {
      Hanzi: '翻译',
      Pinyin: 'fān yì',
      Definitions:
        'to translate; to interpret; translator; interpreter; translation; interpretation; ',
      hskLevel: 4,
    },
    {
      Hanzi: '烦恼',
      Pinyin: 'fán nǎo',
      Definitions: 'agonize; agony; annoyance; upset; vexation; worries',
      hskLevel: 4,
    },
    {
      Hanzi: '反对',
      Pinyin: 'fǎn duì',
      Definitions: 'to fight against; to oppose; to be opposed to; opposition',
      hskLevel: 4,
    },
    {
      Hanzi: '方法',
      Pinyin: 'fāng fǎ',
      Definitions: 'method; way; means; ',
      hskLevel: 4,
    },
    {
      Hanzi: '方面',
      Pinyin: 'fāng miàn',
      Definitions: 'respect; aspect; field; side; ',
      hskLevel: 4,
    },
    {
      Hanzi: '方向',
      Pinyin: 'fāng xiàng',
      Definitions: 'direction; orientation; path to follow; ',
      hskLevel: 4,
    },
    {
      Hanzi: '房东',
      Pinyin: 'fáng dōng',
      Definitions: 'landlord',
      hskLevel: 4,
    },
    {
      Hanzi: '放弃',
      Pinyin: 'fàng qì',
      Definitions: 'to renounce; to abandon; to give up',
      hskLevel: 4,
    },
    {
      Hanzi: '放暑假',
      Pinyin: 'fàng shǔ jià',
      Definitions: 'take summer vacation',
      hskLevel: 4,
    },
    {
      Hanzi: '放松',
      Pinyin: 'fàng sōng',
      Definitions: 'to loosen; to relax',
      hskLevel: 4,
    },
    {
      Hanzi: '份',
      Pinyin: 'fèn',
      Definitions:
        'part; share; portion; copy; classifier for gifts, newspaper, magazine, papers, reports, contracts etc',
      hskLevel: 4,
    },
    {
      Hanzi: '丰富',
      Pinyin: 'fēng fù',
      Definitions: 'rich; plentiful; abundant',
      hskLevel: 4,
    },
    {
      Hanzi: '否则',
      Pinyin: 'fǒu zé',
      Definitions: 'if not; otherwise; else; or else',
      hskLevel: 4,
    },
    {
      Hanzi: '符合',
      Pinyin: 'fú hé',
      Definitions:
        'in keeping with; in accordance with; tallying with; in line with; to agree with; to accord with; to conform to; to correspond with; to manage; to handle',
      hskLevel: 4,
    },
    {
      Hanzi: '付款',
      Pinyin: 'fù kuǎn',
      Definitions: 'pay; payment',
      hskLevel: 4,
    },
    {
      Hanzi: '复印',
      Pinyin: 'fù yìn',
      Definitions: 'to photocopy; to duplicate a document',
      hskLevel: 4,
    },
    {
      Hanzi: '复杂',
      Pinyin: 'fù zá',
      Definitions: 'complicated; complex',
      hskLevel: 4,
    },
    {
      Hanzi: '富',
      Pinyin: 'fù',
      Definitions: 'rich; surname Fu',
      hskLevel: 4,
    },
    {
      Hanzi: '父亲',
      Pinyin: 'fù qīn',
      Definitions: 'father; also pr. with light tone [fu4 qin5]; ',
      hskLevel: 4,
    },
    {
      Hanzi: '负责',
      Pinyin: 'fù zé',
      Definitions:
        'to be in charge of; to take responsibility for; to be to blame; conscientious',
      hskLevel: 4,
    },
    {
      Hanzi: '改变',
      Pinyin: 'gǎi biàn',
      Definitions: 'to change; to alter; to transform',
      hskLevel: 4,
    },
    {
      Hanzi: '干杯',
      Pinyin: 'gān bēi',
      Definitions:
        "to drink a toast; Cheers! (proposing a toast); Here's to you!; Bottoms up!; lit. dry cup",
      hskLevel: 4,
    },
    {
      Hanzi: '感动',
      Pinyin: 'gǎn dòng',
      Definitions: 'to move (sb); to touch (sb emotionally); moving',
      hskLevel: 4,
    },
    {
      Hanzi: '感觉',
      Pinyin: 'gǎn jué',
      Definitions: 'to feel; to become aware of; feeling; sense; perception; ',
      hskLevel: 4,
    },
    {
      Hanzi: '感情',
      Pinyin: 'gǎn qíng',
      Definitions:
        'feeling; emotion; sensation; likes and dislikes; deep affection for sb or sth; relationship (i.e. love affair); ',
      hskLevel: 4,
    },
    {
      Hanzi: '感谢',
      Pinyin: 'gǎn xiè',
      Definitions: '(express) thanks; gratitude; grateful; thankful; thanks',
      hskLevel: 4,
    },
    {
      Hanzi: '敢',
      Pinyin: 'gǎn',
      Definitions: 'to dare; daring; (polite) may I venture',
      hskLevel: 4,
    },
    {
      Hanzi: '赶',
      Pinyin: 'gǎn',
      Definitions:
        'to overtake; to catch up with; to hurry; to rush; to try to catch (the bus etc); to drive (cattle etc) forward; to drive (sb) away; to avail oneself of (an opportunity); until;',
      hskLevel: 4,
    },
    {
      Hanzi: '干',
      Pinyin: 'gàn',
      Definitions: 'to do (some work); to manage; to work',
      hskLevel: 4,
    },
    {
      Hanzi: '刚',
      Pinyin: 'gāng',
      Definitions: 'just; barely; exactly; hard; firm; strong',
      hskLevel: 4,
    },
    {
      Hanzi: '高速公路',
      Pinyin: 'gāo sù gōng lù',
      Definitions: 'expressway; highway; freeway',
      hskLevel: 4,
    },
    {
      Hanzi: '胳膊',
      Pinyin: 'gē bo',
      Definitions: 'arm; ',
      hskLevel: 4,
    },
    {
      Hanzi: '各',
      Pinyin: 'gè',
      Definitions: 'each; every',
      hskLevel: 4,
    },
    {
      Hanzi: '公里',
      Pinyin: 'gōng lǐ',
      Definitions: 'kilometer',
      hskLevel: 4,
    },
    {
      Hanzi: '功夫',
      Pinyin: 'gōng fu',
      Definitions: 'skill; art; kung fu; labor; effort',
      hskLevel: 4,
    },
    {
      Hanzi: '工资',
      Pinyin: 'gōng zī',
      Definitions: 'wages; pay; ',
      hskLevel: 4,
    },
    {
      Hanzi: '共同',
      Pinyin: 'gòng tóng',
      Definitions: 'common; joint; jointly; together; collaborative',
      hskLevel: 4,
    },
    {
      Hanzi: '够',
      Pinyin: 'gòu',
      Definitions: 'to reach; to be enough',
      hskLevel: 4,
    },
    {
      Hanzi: '购物',
      Pinyin: 'gòu wù',
      Definitions: 'shopping',
      hskLevel: 4,
    },
    {
      Hanzi: '估计',
      Pinyin: 'gū jì',
      Definitions: 'to estimate; to reckon; ',
      hskLevel: 4,
    },
    {
      Hanzi: '鼓励',
      Pinyin: 'gǔ lì',
      Definitions: 'to encourage',
      hskLevel: 4,
    },
    {
      Hanzi: '故意',
      Pinyin: 'gù yì',
      Definitions: 'deliberately; on purpose',
      hskLevel: 4,
    },
    {
      Hanzi: '顾客',
      Pinyin: 'gù kè',
      Definitions: 'client; customer; ',
      hskLevel: 4,
    },
    {
      Hanzi: '挂',
      Pinyin: 'guà',
      Definitions:
        'to hang or suspend (from a hook etc); (of a telephone call) to hang up; to be worried or concerned; to make a phone call (topolect); to register or record; to hitch; classifier for sets or clusters of objects',
      hskLevel: 4,
    },
    {
      Hanzi: '关键',
      Pinyin: 'guān jiàn',
      Definitions: 'crucial point; crux; ',
      hskLevel: 4,
    },
    {
      Hanzi: '观众',
      Pinyin: 'guān zhòng',
      Definitions: 'spectators; audience; visitors (to an exhibition etc)',
      hskLevel: 4,
    },
    {
      Hanzi: '管理',
      Pinyin: 'guǎn lǐ',
      Definitions:
        'to supervise; to manage; to administer; management; administration; ',
      hskLevel: 4,
    },
    {
      Hanzi: '光',
      Pinyin: 'guāng',
      Definitions: 'light (ray); light; ray; ',
      hskLevel: 4,
    },
    {
      Hanzi: '广播',
      Pinyin: 'guǎng bō',
      Definitions: 'broadcast; widely spread; ',
      hskLevel: 4,
    },
    {
      Hanzi: '广告',
      Pinyin: 'guǎng gào',
      Definitions: 'to advertise; a commercial; advertisement; ',
      hskLevel: 4,
    },
    {
      Hanzi: '逛',
      Pinyin: 'guàng',
      Definitions: 'to stroll; to visit',
      hskLevel: 4,
    },
    {
      Hanzi: '规定',
      Pinyin: 'guī dìng',
      Definitions:
        'to stipulate; to fix; to set; to formulate; to provide; regulation; rule; ',
      hskLevel: 4,
    },
    {
      Hanzi: '国籍',
      Pinyin: 'guó jí',
      Definitions: 'nationality',
      hskLevel: 4,
    },
    {
      Hanzi: '国际',
      Pinyin: 'guó jì',
      Definitions: 'international',
      hskLevel: 4,
    },
    {
      Hanzi: '果汁',
      Pinyin: 'guǒ zhī',
      Definitions: 'fruit juice',
      hskLevel: 4,
    },
    {
      Hanzi: '过程',
      Pinyin: 'guò chéng',
      Definitions: 'course of events; process; ',
      hskLevel: 4,
    },
    {
      Hanzi: '海洋',
      Pinyin: 'hǎi yáng',
      Definitions: 'ocean; ',
      hskLevel: 4,
    },
    {
      Hanzi: '害羞',
      Pinyin: 'hài xiū',
      Definitions: 'blush; shy',
      hskLevel: 4,
    },
    {
      Hanzi: '寒假',
      Pinyin: 'hán jià',
      Definitions: 'winter vacation',
      hskLevel: 4,
    },
    {
      Hanzi: '汗',
      Pinyin: 'hàn',
      Definitions:
        'perspiration; sweat; Khan (Persian or Mongol king or emperor); Khan (name); to be speechless (out of helplessness, embarrassment etc) (Internet slang used as an interjection); ',
      hskLevel: 4,
    },
    {
      Hanzi: '航班',
      Pinyin: 'háng bān',
      Definitions:
        'scheduled flight; flight number; plane; scheduled sailing; sailing number; passenger ship',
      hskLevel: 4,
    },
    {
      Hanzi: '好处',
      Pinyin: 'hǎo chu',
      Definitions:
        'benefit; advantage; gain; profit; also pronounced hao3 chu4; ',
      hskLevel: 4,
    },
    {
      Hanzi: '好像',
      Pinyin: 'hǎo xiàng',
      Definitions: 'as if; to seem like',
      hskLevel: 4,
    },
    {
      Hanzi: '号码',
      Pinyin: 'hào mǎ',
      Definitions: 'number; ',
      hskLevel: 4,
    },
    {
      Hanzi: '合格',
      Pinyin: 'hé gé',
      Definitions: 'qualified; meeting a standard; eligible (voter)',
      hskLevel: 4,
    },
    {
      Hanzi: '合适',
      Pinyin: 'hé shì',
      Definitions: 'suitable; fitting; decent; to fit',
      hskLevel: 4,
    },
    {
      Hanzi: '盒子',
      Pinyin: 'hé zi',
      Definitions: 'box',
      hskLevel: 4,
    },
    {
      Hanzi: '厚',
      Pinyin: 'hòu',
      Definitions:
        'thick; deep or profound; kind; generous; rich or strong in flavor; to favor; to stress',
      hskLevel: 4,
    },
    {
      Hanzi: '后悔',
      Pinyin: 'hòu huǐ',
      Definitions: 'to regret; to repent',
      hskLevel: 4,
    },
    {
      Hanzi: '互联网',
      Pinyin: 'hù lián wǎng',
      Definitions: 'the Internet',
      hskLevel: 4,
    },
    {
      Hanzi: '互相',
      Pinyin: 'hù xiāng',
      Definitions: 'each other; mutually; mutual',
      hskLevel: 4,
    },
    {
      Hanzi: '护士',
      Pinyin: 'hù shi',
      Definitions: 'nurse; ',
      hskLevel: 4,
    },
    {
      Hanzi: '怀疑',
      Pinyin: 'huái yí',
      Definitions: 'to suspect; to doubt; doubt; suspicion; skeptical',
      hskLevel: 4,
    },
    {
      Hanzi: '回忆',
      Pinyin: 'huí yì',
      Definitions: 'to recall; recollection; ',
      hskLevel: 4,
    },
    {
      Hanzi: '活动',
      Pinyin: 'huó dòng',
      Definitions:
        'to exercise; to move about; to operate; activity; loose; shaky; active; movable; maneuver; to use connections; ',
      hskLevel: 4,
    },
    {
      Hanzi: '活泼',
      Pinyin: 'huó po',
      Definitions: 'lively; vivacious; brisk; active',
      hskLevel: 4,
    },
    {
      Hanzi: '火',
      Pinyin: 'huǒ',
      Definitions: 'fire; ',
      hskLevel: 4,
    },
    {
      Hanzi: '获得',
      Pinyin: 'huò dé',
      Definitions: 'to obtain; to receive; to get',
      hskLevel: 4,
    },
    {
      Hanzi: '基础',
      Pinyin: 'jī chǔ',
      Definitions: 'base; foundation; basis; underlying; ',
      hskLevel: 4,
    },
    {
      Hanzi: '激动',
      Pinyin: 'jī dòng',
      Definitions: 'to excite; to agitate; exciting',
      hskLevel: 4,
    },
    {
      Hanzi: '积极',
      Pinyin: 'jī jí',
      Definitions: 'active; energetic; vigorous; positive (outlook); proactive',
      hskLevel: 4,
    },
    {
      Hanzi: '积累',
      Pinyin: 'jī lěi',
      Definitions: 'to accumulate; accumulation; cumulative; cumulatively',
      hskLevel: 4,
    },
    {
      Hanzi: '即使',
      Pinyin: 'jí shǐ',
      Definitions: 'even if; even though; given that',
      hskLevel: 4,
    },
    {
      Hanzi: '及时',
      Pinyin: 'jí shí',
      Definitions: 'in time; promptly; without delay; timely',
      hskLevel: 4,
    },
    {
      Hanzi: '寄',
      Pinyin: 'jì',
      Definitions:
        'to live (in a house); to lodge; to mail; to send; to entrust; to depend',
      hskLevel: 4,
    },
    {
      Hanzi: '技术',
      Pinyin: 'jì shù',
      Definitions: 'technology; technique; skill; ',
      hskLevel: 4,
    },
    {
      Hanzi: '既然',
      Pinyin: 'jì rán',
      Definitions: 'since; as; this being the case',
      hskLevel: 4,
    },
    {
      Hanzi: '继续',
      Pinyin: 'jì xù',
      Definitions: 'to continue; to proceed with; to go on with',
      hskLevel: 4,
    },
    {
      Hanzi: '计划',
      Pinyin: 'jì huà',
      Definitions: 'plan; project; program; to plan; to map out; ',
      hskLevel: 4,
    },
    {
      Hanzi: '记者',
      Pinyin: 'jì zhě',
      Definitions: 'reporter; journalist; ',
      hskLevel: 4,
    },
    {
      Hanzi: '加班',
      Pinyin: 'jiā bān',
      Definitions: 'to work overtime',
      hskLevel: 4,
    },
    {
      Hanzi: '加油站',
      Pinyin: 'jiā yóu zhàn',
      Definitions: 'gas station',
      hskLevel: 4,
    },
    {
      Hanzi: '家具',
      Pinyin: 'jiā jù',
      Definitions: 'furniture; ',
      hskLevel: 4,
    },
    {
      Hanzi: '假',
      Pinyin: 'jiǎ',
      Definitions: 'fake; false; artificial; to borrow; if; suppose',
      hskLevel: 4,
    },
    {
      Hanzi: '价格',
      Pinyin: 'jià gé',
      Definitions: 'price; ',
      hskLevel: 4,
    },
    {
      Hanzi: '坚持',
      Pinyin: 'jiān chí',
      Definitions:
        'to continue upholding; to remain committed to; persistence; to persist; to uphold; to insist on; persevere',
      hskLevel: 4,
    },
    {
      Hanzi: '减肥',
      Pinyin: 'jiǎn féi',
      Definitions: 'to lose weight',
      hskLevel: 4,
    },
    {
      Hanzi: '减少',
      Pinyin: 'jiǎn shǎo',
      Definitions: 'to lessen; to decrease; to reduce; to lower',
      hskLevel: 4,
    },
    {
      Hanzi: '建议',
      Pinyin: 'jiàn yì',
      Definitions:
        'to propose; to suggest; to recommend; proposal; suggestion; recommendation; ',
      hskLevel: 4,
    },
    {
      Hanzi: '将来',
      Pinyin: 'jiāng lái',
      Definitions: 'in the future; future; the future; ',
      hskLevel: 4,
    },
    {
      Hanzi: '奖金',
      Pinyin: 'jiǎng jīn',
      Definitions: 'award money; premium; a bonus',
      hskLevel: 4,
    },
    {
      Hanzi: '降低',
      Pinyin: 'jiàng dī',
      Definitions: 'to reduce; to lower; to bring down',
      hskLevel: 4,
    },
    {
      Hanzi: '降落',
      Pinyin: 'jiàng luò',
      Definitions: 'to descend; to land',
      hskLevel: 4,
    },
    {
      Hanzi: '交',
      Pinyin: 'jiāo',
      Definitions:
        'to hand over; to deliver; to pay (money); to turn over; to make friends; to intersect (lines)',
      hskLevel: 4,
    },
    {
      Hanzi: '交流',
      Pinyin: 'jiāo liú',
      Definitions:
        'exchange; give-and-take; to exchange; to alternate; communication; alternating current (electricity)',
      hskLevel: 4,
    },
    {
      Hanzi: '交通',
      Pinyin: 'jiāo tōng',
      Definitions: 'traffic; communications; liaison; to be connected',
      hskLevel: 4,
    },
    {
      Hanzi: '郊区',
      Pinyin: 'jiāo qū',
      Definitions: 'suburban district; outskirts; suburbs; ',
      hskLevel: 4,
    },
    {
      Hanzi: '骄傲',
      Pinyin: 'jiāo ào',
      Definitions: 'arrogant; full of oneself; conceited; proud of sth; ',
      hskLevel: 4,
    },
    {
      Hanzi: '饺子',
      Pinyin: 'jiǎo zi',
      Definitions: 'dumpling; pot-sticker; ',
      hskLevel: 4,
    },
    {
      Hanzi: '教授',
      Pinyin: 'jiào shòu',
      Definitions: 'professor; to instruct; to lecture on; ',
      hskLevel: 4,
    },
    {
      Hanzi: '教育',
      Pinyin: 'jiào yù',
      Definitions: 'to educate; to teach; education',
      hskLevel: 4,
    },
    {
      Hanzi: '接受',
      Pinyin: 'jiē shòu',
      Definitions: 'to accept; to receive',
      hskLevel: 4,
    },
    {
      Hanzi: '接着',
      Pinyin: 'jiē zhe',
      Definitions:
        "to catch and hold on; to continue; to go on to do sth; to follow; to carry on; then; after that; subsequently; to proceed; to ensue; in turn; in one's turn",
      hskLevel: 4,
    },
    {
      Hanzi: '结果',
      Pinyin: 'jié guǒ',
      Definitions: 'result; outcome; effect; in the event; so that',
      hskLevel: 4,
    },
    {
      Hanzi: '节',
      Pinyin: 'jié',
      Definitions:
        'festival; holiday; node; joint; section; segment; part; to economize; to save; to abridge; moral integrity; classifier for segments, e.g. lessons, train wagons, biblical verses; ',
      hskLevel: 4,
    },
    {
      Hanzi: '节约',
      Pinyin: 'jié yuē',
      Definitions: 'to economize; to conserve (resources); economy; frugal',
      hskLevel: 4,
    },
    {
      Hanzi: '解释',
      Pinyin: 'jiě shì',
      Definitions: 'explanation; to explain; to interpret; to resolve; ',
      hskLevel: 4,
    },
    {
      Hanzi: '尽管',
      Pinyin: 'jǐn guǎn',
      Definitions:
        'despite; although; even though; in spite of; unhesitatingly; do not hesitate (to ask, complain etc); (go ahead and do it) without hesitating',
      hskLevel: 4,
    },
    {
      Hanzi: '紧张',
      Pinyin: 'jǐn zhāng',
      Definitions:
        'nervous; keyed up; intense; tense; strained; in short supply; scarce; ',
      hskLevel: 4,
    },
    {
      Hanzi: '禁止',
      Pinyin: 'jìn zhǐ',
      Definitions: 'to prohibit; to forbid; to ban',
      hskLevel: 4,
    },
    {
      Hanzi: '进行',
      Pinyin: 'jìn xíng',
      Definitions:
        'to advance; to conduct; underway; in progress; to do; to carry out; to carry on; to execute',
      hskLevel: 4,
    },
    {
      Hanzi: '京剧',
      Pinyin: 'jīng jù',
      Definitions: 'Beijing opera; ',
      hskLevel: 4,
    },
    {
      Hanzi: '精彩',
      Pinyin: 'jīng cǎi',
      Definitions: 'splendid; brilliant',
      hskLevel: 4,
    },
    {
      Hanzi: '经济',
      Pinyin: 'jīng jì',
      Definitions: 'economy; economic',
      hskLevel: 4,
    },
    {
      Hanzi: '经历',
      Pinyin: 'jīng lì',
      Definitions: 'experience; to experience; to go through; ',
      hskLevel: 4,
    },
    {
      Hanzi: '经验',
      Pinyin: 'jīng yàn',
      Definitions: 'to experience; experience',
      hskLevel: 4,
    },
    {
      Hanzi: '景色',
      Pinyin: 'jǐng sè',
      Definitions: 'scenery; scene; landscape; view',
      hskLevel: 4,
    },
    {
      Hanzi: '警察',
      Pinyin: 'jǐng chá',
      Definitions: 'police; policeman; policewoman; ',
      hskLevel: 4,
    },
    {
      Hanzi: '竞争',
      Pinyin: 'jìng zhēng',
      Definitions: 'to compete; competition',
      hskLevel: 4,
    },
    {
      Hanzi: '竟然',
      Pinyin: 'jìng rán',
      Definitions:
        "unexpectedly; to one's surprise; in spite of everything; in that crazy way; actually; to go as far as to",
      hskLevel: 4,
    },
    {
      Hanzi: '镜子',
      Pinyin: 'jìng zi',
      Definitions: 'mirror; ',
      hskLevel: 4,
    },
    {
      Hanzi: '究竟',
      Pinyin: 'jiū jìng',
      Definitions:
        'after all (when all is said and done); actually; outcome; result',
      hskLevel: 4,
    },
    {
      Hanzi: '举',
      Pinyin: 'jǔ',
      Definitions:
        'to lift; to hold up; to cite; to enumerate; to act; to raise; to choose; to elect',
      hskLevel: 4,
    },
    {
      Hanzi: '举办',
      Pinyin: 'jǔ bàn',
      Definitions: 'to conduct; to hold',
      hskLevel: 4,
    },
    {
      Hanzi: '举行',
      Pinyin: 'jǔ xíng',
      Definitions: 'to hold (a meeting, ceremony etc)',
      hskLevel: 4,
    },
    {
      Hanzi: '拒绝',
      Pinyin: 'jù jué',
      Definitions: 'to refuse; to decline; to reject',
      hskLevel: 4,
    },
    {
      Hanzi: '聚会',
      Pinyin: 'jù huì',
      Definitions: 'party; gathering; to meet; to get together',
      hskLevel: 4,
    },
    {
      Hanzi: '距离',
      Pinyin: 'jù lí',
      Definitions: 'distance; to be apart; ',
      hskLevel: 4,
    },
    {
      Hanzi: '开玩笑',
      Pinyin: 'kāi wán xiào',
      Definitions: 'to play a joke; to make fun of; to joke',
      hskLevel: 4,
    },
    {
      Hanzi: '开心',
      Pinyin: 'kāi xīn',
      Definitions:
        'to feel happy; to rejoice; to have a great time; to make fun of sb',
      hskLevel: 4,
    },
    {
      Hanzi: '看法',
      Pinyin: 'kàn fǎ',
      Definitions: 'way of looking at a thing; view; opinion; ',
      hskLevel: 4,
    },
    {
      Hanzi: '烤鸭',
      Pinyin: 'kǎo yā',
      Definitions: 'roast duck',
      hskLevel: 4,
    },
    {
      Hanzi: '考虑',
      Pinyin: 'kǎo lv4',
      Definitions: 'to think over; to consider; consideration',
      hskLevel: 4,
    },
    {
      Hanzi: '棵',
      Pinyin: 'kē',
      Definitions: 'classifier for trees, cabbages, plants etc',
      hskLevel: 4,
    },
    {
      Hanzi: '科学',
      Pinyin: 'kē xué',
      Definitions: 'science; scientific knowledge; scientific; ',
      hskLevel: 4,
    },
    {
      Hanzi: '咳嗽',
      Pinyin: 'ké sou',
      Definitions: 'to cough; ',
      hskLevel: 4,
    },
    {
      Hanzi: '可怜',
      Pinyin: 'kě lián',
      Definitions: 'pitiful; pathetic',
      hskLevel: 4,
    },
    {
      Hanzi: '可是',
      Pinyin: 'kě shì',
      Definitions: 'but; however',
      hskLevel: 4,
    },
    {
      Hanzi: '可惜',
      Pinyin: 'kě xī',
      Definitions: "it is a pity; what a pity; (it's) too bad",
      hskLevel: 4,
    },
    {
      Hanzi: '客厅',
      Pinyin: 'kè tīng',
      Definitions: 'drawing room (room for arriving guests); living room; ',
      hskLevel: 4,
    },
    {
      Hanzi: '肯定',
      Pinyin: 'kěn dìng',
      Definitions:
        'to be sure; to be certain; sure; certain; definite; to confirm; to affirm; affirmative',
      hskLevel: 4,
    },
    {
      Hanzi: '空',
      Pinyin: 'kōng',
      Definitions: 'to empty; vacant; unoccupied; space; leisure; free time;',
      hskLevel: 4,
    },
    {
      Hanzi: '空气',
      Pinyin: 'kōng qì',
      Definitions: 'air; atmosphere',
      hskLevel: 4,
    },
    {
      Hanzi: '恐怕',
      Pinyin: 'kǒng pà',
      Definitions: "to fear; to dread; I'm afraid that...; perhaps; maybe",
      hskLevel: 4,
    },
    {
      Hanzi: '苦',
      Pinyin: 'kǔ',
      Definitions: 'bitter; hardship; pain; to suffer; painstaking',
      hskLevel: 4,
    },
    {
      Hanzi: '矿泉水',
      Pinyin: 'kuàng quán shuǐ',
      Definitions: 'mineral spring water; ',
      hskLevel: 4,
    },
    {
      Hanzi: '困',
      Pinyin: 'kùn',
      Definitions:
        'to be trapped; to surround; to be hard-pressed; stranded; destimtute',
      hskLevel: 4,
    },
    {
      Hanzi: '困难',
      Pinyin: 'kùn nan',
      Definitions: '(financial etc) difficulty; problem; issue; ',
      hskLevel: 4,
    },
    {
      Hanzi: '垃圾桶',
      Pinyin: 'lā jī tǒng',
      Definitions: 'rubbish bin',
      hskLevel: 4,
    },
    {
      Hanzi: '拉',
      Pinyin: 'lā',
      Definitions: 'to pull; to play (string instruments); to drag; to draw',
      hskLevel: 4,
    },
    {
      Hanzi: '辣',
      Pinyin: 'là',
      Definitions: 'hot (spicy); pungent',
      hskLevel: 4,
    },
    {
      Hanzi: '来不及',
      Pinyin: 'lái bu jí',
      Definitions:
        "there's not enough time (to do sth); it's too late (to do sth)",
      hskLevel: 4,
    },
    {
      Hanzi: '来得及',
      Pinyin: 'lái de jí',
      Definitions: "there's still time; able to do sth in time",
      hskLevel: 4,
    },
    {
      Hanzi: '来自',
      Pinyin: 'lái zì',
      Definitions: 'to come from (a place); From: (in email header)',
      hskLevel: 4,
    },
    {
      Hanzi: '懒',
      Pinyin: 'lǎn',
      Definitions: 'lazy',
      hskLevel: 4,
    },
    {
      Hanzi: '浪费',
      Pinyin: 'làng fèi',
      Definitions: 'to waste; to squander',
      hskLevel: 4,
    },
    {
      Hanzi: '浪漫',
      Pinyin: 'làng màn',
      Definitions: 'romantic',
      hskLevel: 4,
    },
    {
      Hanzi: '老虎',
      Pinyin: 'lǎo hǔ',
      Definitions: 'tiger; ',
      hskLevel: 4,
    },
    {
      Hanzi: '冷静',
      Pinyin: 'lěng jìng',
      Definitions: 'calm; cool-headed',
      hskLevel: 4,
    },
    {
      Hanzi: '理发',
      Pinyin: 'lǐ fà',
      Definitions: 'a barber; hairdressing',
      hskLevel: 4,
    },
    {
      Hanzi: '理解',
      Pinyin: 'lǐ jiě',
      Definitions: 'to comprehend; to understand; comprehension; understanding',
      hskLevel: 4,
    },
    {
      Hanzi: '理想',
      Pinyin: 'lǐ xiǎng',
      Definitions: 'a dream; an ideal; perfection; ideal; perfect; desirable; ',
      hskLevel: 4,
    },
    {
      Hanzi: '礼拜天',
      Pinyin: 'lǐ bài tiān',
      Definitions: 'Sunday',
      hskLevel: 4,
    },
    {
      Hanzi: '礼貌',
      Pinyin: 'lǐ mào',
      Definitions: 'courtesy; manners',
      hskLevel: 4,
    },
    {
      Hanzi: '例如',
      Pinyin: 'lì rú',
      Definitions: 'for example; for instance; such as',
      hskLevel: 4,
    },
    {
      Hanzi: '力气',
      Pinyin: 'lì qi',
      Definitions: 'strength; ',
      hskLevel: 4,
    },
    {
      Hanzi: '厉害',
      Pinyin: 'lì hai',
      Definitions:
        'difficult to deal with; difficult to endure; ferocious; radical; serious; terrible; violent; tremendous; awesome',
      hskLevel: 4,
    },
    {
      Hanzi: '俩',
      Pinyin: 'liǎ',
      Definitions: 'two (colloquial equivalent of 两个); both; some',
      hskLevel: 4,
    },
    {
      Hanzi: '联系',
      Pinyin: 'lián xì',
      Definitions:
        'connection; contact; relation; in touch with; to integrate; to link; to touch',
      hskLevel: 4,
    },
    {
      Hanzi: '连',
      Pinyin: 'lián',
      Definitions:
        'to link; to join; to connect; continuously; in succession; including; (used with 也, 都 etc) even; company (military)',
      hskLevel: 4,
    },
    {
      Hanzi: '凉快',
      Pinyin: 'liáng kuai',
      Definitions: 'nice and cold; pleasantly cool',
      hskLevel: 4,
    },
    {
      Hanzi: '零钱',
      Pinyin: 'líng qián',
      Definitions: 'change (of money); small change; pocket money',
      hskLevel: 4,
    },
    {
      Hanzi: '另外',
      Pinyin: 'lìng wài',
      Definitions:
        'additional; in addition; besides; separate; other; moreover; furthermore',
      hskLevel: 4,
    },
    {
      Hanzi: '流利',
      Pinyin: 'liú lì',
      Definitions: 'fluent',
      hskLevel: 4,
    },
    {
      Hanzi: '流行',
      Pinyin: 'liú xíng',
      Definitions:
        'to spread; to rage (of contagious disease); popular; fashionable; prevalent; (math.) manifold',
      hskLevel: 4,
    },
    {
      Hanzi: '留',
      Pinyin: 'liú',
      Definitions:
        'to retain; to stay; to remain; to keep; to preserve; to leave (eg a message)',
      hskLevel: 4,
    },
    {
      Hanzi: '旅行',
      Pinyin: 'lǚ xíng',
      Definitions: 'to travel; journey; trip; ',
      hskLevel: 4,
    },
    {
      Hanzi: '乱',
      Pinyin: 'luàn',
      Definitions:
        'in confusion or disorder; in a confused state of mind; disorder; upheaval; riot; illicit sexual relations; to throw into disorder; to mix up; indiscriminate; random; arbitrary',
      hskLevel: 4,
    },
    {
      Hanzi: '律师',
      Pinyin: 'lv4 shī',
      Definitions: 'lawyer',
      hskLevel: 4,
    },
    {
      Hanzi: '麻烦',
      Pinyin: 'má fan',
      Definitions:
        'inconvenient; troublesome; to trouble or bother sb; to put sb to trouble',
      hskLevel: 4,
    },
    {
      Hanzi: '马虎',
      Pinyin: 'mǎ hu',
      Definitions: 'careless; sloppy; negligent; skimpy',
      hskLevel: 4,
    },
    {
      Hanzi: '满',
      Pinyin: 'mǎn',
      Definitions:
        'full; filled; packed; fully; completely; to reach the limit; to satisfy; satisfied; contented; to fill;',
      hskLevel: 4,
    },
    {
      Hanzi: '毛',
      Pinyin: 'máo',
      Definitions: 'hair; feather; down',
      hskLevel: 4,
    },
    {
      Hanzi: '毛巾',
      Pinyin: 'máo jīn',
      Definitions: 'towel; ',
      hskLevel: 4,
    },
    {
      Hanzi: '美丽',
      Pinyin: 'měi lì',
      Definitions: 'beautiful',
      hskLevel: 4,
    },
    {
      Hanzi: '梦',
      Pinyin: 'mèng',
      Definitions: 'dream; ',
      hskLevel: 4,
    },
    {
      Hanzi: '迷路',
      Pinyin: 'mí lù',
      Definitions:
        'to lose the way; lost; labyrinth; labyrinthus vestibularis (of the inner ear)',
      hskLevel: 4,
    },
    {
      Hanzi: '密码',
      Pinyin: 'mì mǎ',
      Definitions: 'code; secret code; password; pin number',
      hskLevel: 4,
    },
    {
      Hanzi: '免费',
      Pinyin: 'miǎn fèi',
      Definitions: 'free (of charge)',
      hskLevel: 4,
    },
    {
      Hanzi: '秒',
      Pinyin: 'miǎo',
      Definitions:
        'second (of time); unit of angle or arc equivalent to one sixtieth of a degree',
      hskLevel: 4,
    },
    {
      Hanzi: '民族',
      Pinyin: 'mín zú',
      Definitions: 'nationality; ethnic group; ',
      hskLevel: 4,
    },
    {
      Hanzi: '母亲',
      Pinyin: 'mǔ qīn',
      Definitions: 'mother; also pr. with light tone [mu3 qin5]; ',
      hskLevel: 4,
    },
    {
      Hanzi: '目的',
      Pinyin: 'mù dì',
      Definitions: 'purpose; aim; goal; target; objective; ',
      hskLevel: 4,
    },
    {
      Hanzi: '耐心',
      Pinyin: 'nài xīn',
      Definitions: 'to be patient; patient (adjective)',
      hskLevel: 4,
    },
    {
      Hanzi: '难道',
      Pinyin: 'nán dào',
      Definitions: "don't tell me...; could it be that...?",
      hskLevel: 4,
    },
    {
      Hanzi: '难受',
      Pinyin: 'nán shòu',
      Definitions: 'to feel unwell; to suffer pain; to be difficult to bear',
      hskLevel: 4,
    },
    {
      Hanzi: '内',
      Pinyin: 'nèi',
      Definitions: 'inside; inner; internal; within; interior',
      hskLevel: 4,
    },
    {
      Hanzi: '内容',
      Pinyin: 'nèi róng',
      Definitions: 'content; substance; details; ',
      hskLevel: 4,
    },
    {
      Hanzi: '能力',
      Pinyin: 'néng lì',
      Definitions: 'capability; capable; able; ability; ',
      hskLevel: 4,
    },
    {
      Hanzi: '年龄',
      Pinyin: 'nián líng',
      Definitions: "(a person's) age; ",
      hskLevel: 4,
    },
    {
      Hanzi: '弄',
      Pinyin: 'nòng',
      Definitions:
        'to do (to manage); to manage; to handle; to play with; to fool with; to mess with; to fix; to toy with',
      hskLevel: 4,
    },
    {
      Hanzi: '暖和',
      Pinyin: 'nuǎn huo',
      Definitions: 'warm (weather); nice and warm',
      hskLevel: 4,
    },
    {
      Hanzi: '偶尔',
      Pinyin: 'ǒu ěr',
      Definitions: 'occasionally; once in a while; sometimes',
      hskLevel: 4,
    },
    {
      Hanzi: '排队',
      Pinyin: 'pái duì',
      Definitions: 'to line up; queue up; form a line',
      hskLevel: 4,
    },
    {
      Hanzi: '排列',
      Pinyin: 'pái liè',
      Definitions:
        'to arrange; arrangement; array; permutation (i.e. ordered choice of n elements out of m)',
      hskLevel: 4,
    },
    {
      Hanzi: '判断',
      Pinyin: 'pàn duàn',
      Definitions: 'to decide; to determine; ',
      hskLevel: 4,
    },
    {
      Hanzi: '陪',
      Pinyin: 'péi',
      Definitions: 'to accompany; to keep sb company',
      hskLevel: 4,
    },
    {
      Hanzi: '批评',
      Pinyin: 'pī píng',
      Definitions: 'to criticize; criticism; ',
      hskLevel: 4,
    },
    {
      Hanzi: '皮肤',
      Pinyin: 'pí fū',
      Definitions: 'skin; ',
      hskLevel: 4,
    },
    {
      Hanzi: '脾气',
      Pinyin: 'pí qi',
      Definitions: 'temperament; disposition; temper; ',
      hskLevel: 4,
    },
    {
      Hanzi: '篇',
      Pinyin: 'piān',
      Definitions:
        'piece of writing; sheet; bound set of bamboo slips used for record keeping (old); classifier for written items: chapter, article',
      hskLevel: 4,
    },
    {
      Hanzi: '骗',
      Pinyin: 'piàn',
      Definitions:
        'to cheat; to swindle; to deceive; to fool; to hoodwink; to trick',
      hskLevel: 4,
    },
    {
      Hanzi: '乒乓球',
      Pinyin: 'pīng pāng qiú',
      Definitions: 'table tennis; ping-pong; ping pong; table tennis ball; ',
      hskLevel: 4,
    },
    {
      Hanzi: '平时',
      Pinyin: 'píng shí',
      Definitions: 'in normal times; in peacetime',
      hskLevel: 4,
    },
    {
      Hanzi: '破',
      Pinyin: 'pò',
      Definitions:
        'broken; damaged; worn out; to break, split or cleave; to get rid of; to destroy; to break with; to defeat; to capture (a city etc); to expose the truth of',
      hskLevel: 4,
    },
    {
      Hanzi: '葡萄',
      Pinyin: 'pú tao',
      Definitions: 'grape',
      hskLevel: 4,
    },
    {
      Hanzi: '普遍',
      Pinyin: 'pǔ biàn',
      Definitions: 'universal; general; widespread; common',
      hskLevel: 4,
    },
    {
      Hanzi: '普通话',
      Pinyin: 'pǔ tōng huà',
      Definitions:
        'Mandarin (common language); Putonghua (common speech of the Chinese language); ordinary speech',
      hskLevel: 4,
    },
    {
      Hanzi: '其次',
      Pinyin: 'qí cì',
      Definitions: 'next; secondly',
      hskLevel: 4,
    },
    {
      Hanzi: '其中',
      Pinyin: 'qí zhōng',
      Definitions: 'among; in; included among these',
      hskLevel: 4,
    },
    {
      Hanzi: '气候',
      Pinyin: 'qì hòu',
      Definitions: 'climate; atmosphere; situation; ',
      hskLevel: 4,
    },
    {
      Hanzi: '千万',
      Pinyin: 'qiān wàn',
      Definitions: 'ten million; countless; many; one must by all means',
      hskLevel: 4,
    },
    {
      Hanzi: '签证',
      Pinyin: 'qiān zhèng',
      Definitions: 'visa; certificate; to certify; ',
      hskLevel: 4,
    },
    {
      Hanzi: '敲',
      Pinyin: 'qiāo',
      Definitions: 'extort; knock; to strike; to knock (at a door); to hit',
      hskLevel: 4,
    },
    {
      Hanzi: '桥',
      Pinyin: 'qiáo',
      Definitions: 'bridge; ',
      hskLevel: 4,
    },
    {
      Hanzi: '巧克力',
      Pinyin: 'qiǎo kè lì',
      Definitions: 'chocolate (loanword); ',
      hskLevel: 4,
    },
    {
      Hanzi: '亲戚',
      Pinyin: 'qīn qi',
      Definitions: 'a relative (i.e. family relation); ',
      hskLevel: 4,
    },
    {
      Hanzi: '轻',
      Pinyin: 'qīng',
      Definitions:
        'light (not heavy); light; easy; gentle; soft; reckless; unimportant; frivolous; small in number; unstressed; neutral',
      hskLevel: 4,
    },
    {
      Hanzi: '轻松',
      Pinyin: 'qīng sōng',
      Definitions: 'gentle; relaxed',
      hskLevel: 4,
    },
    {
      Hanzi: '情况',
      Pinyin: 'qíng kuàng',
      Definitions: 'circumstances; state of affairs; situation; ',
      hskLevel: 4,
    },
    {
      Hanzi: '穷',
      Pinyin: 'qióng',
      Definitions: 'exhausted; poor',
      hskLevel: 4,
    },
    {
      Hanzi: '区别',
      Pinyin: 'qū bié',
      Definitions:
        'difference; to distinguish; to discriminate; to make a distinction; ',
      hskLevel: 4,
    },
    {
      Hanzi: '取',
      Pinyin: 'qǔ',
      Definitions: 'to take; to get; to choose; to fetch',
      hskLevel: 4,
    },
    {
      Hanzi: '全部',
      Pinyin: 'quán bù',
      Definitions: 'whole; entire; complete',
      hskLevel: 4,
    },
    {
      Hanzi: '缺点',
      Pinyin: 'quē diǎn',
      Definitions: 'weak point; fault; shortcoming; ',
      hskLevel: 4,
    },
    {
      Hanzi: '缺少',
      Pinyin: 'quē shǎo',
      Definitions: 'lack; shortage of; shortfall; to be short (of); to lack',
      hskLevel: 4,
    },
    {
      Hanzi: '却',
      Pinyin: 'què',
      Definitions:
        'but; yet; however; while; to go back; to decline; to retreat; nevertheless; even though',
      hskLevel: 4,
    },
    {
      Hanzi: '确实',
      Pinyin: 'què shí',
      Definitions: 'indeed; really; reliable; real; true',
      hskLevel: 4,
    },
    {
      Hanzi: '然而',
      Pinyin: 'rán ér',
      Definitions: 'however; yet; but',
      hskLevel: 4,
    },
    {
      Hanzi: '热闹',
      Pinyin: 'rè nao',
      Definitions: 'bustling with noise and excitement; lively',
      hskLevel: 4,
    },
    {
      Hanzi: '任何',
      Pinyin: 'rèn hé',
      Definitions: 'any; whatever; whichever; whatsoever',
      hskLevel: 4,
    },
    {
      Hanzi: '任务',
      Pinyin: 'rèn wu',
      Definitions: 'mission; assignment; task; duty; role; ',
      hskLevel: 4,
    },
    {
      Hanzi: '扔',
      Pinyin: 'rēng',
      Definitions: 'to throw; to throw away',
      hskLevel: 4,
    },
    {
      Hanzi: '仍然',
      Pinyin: 'réng rán',
      Definitions: 'still; yet',
      hskLevel: 4,
    },
    {
      Hanzi: '日记',
      Pinyin: 'rì jì',
      Definitions: 'diary; ',
      hskLevel: 4,
    },
    {
      Hanzi: '入口',
      Pinyin: 'rù kǒu',
      Definitions: 'entrance',
      hskLevel: 4,
    },
    {
      Hanzi: '散步',
      Pinyin: 'sàn bù',
      Definitions: 'to take a walk; to go for a walk',
      hskLevel: 4,
    },
    {
      Hanzi: '森林',
      Pinyin: 'sēn lín',
      Definitions: 'forest; ',
      hskLevel: 4,
    },
    {
      Hanzi: '沙发',
      Pinyin: 'shā fā',
      Definitions: 'sofa; ',
      hskLevel: 4,
    },
    {
      Hanzi: '伤心',
      Pinyin: 'shāng xīn',
      Definitions: 'to grieve; to be broken-hearted; to feel deeply hurt; sad',
      hskLevel: 4,
    },
    {
      Hanzi: '商量',
      Pinyin: 'shāng liang',
      Definitions: 'to consult; to talk over; to discuss',
      hskLevel: 4,
    },
    {
      Hanzi: '稍微',
      Pinyin: 'shāo wēi',
      Definitions: 'a little bit',
      hskLevel: 4,
    },
    {
      Hanzi: '勺子',
      Pinyin: 'sháo zi',
      Definitions: 'scoop; ladle; ',
      hskLevel: 4,
    },
    {
      Hanzi: '社会',
      Pinyin: 'shè huì',
      Definitions: 'society; ',
      hskLevel: 4,
    },
    {
      Hanzi: '深',
      Pinyin: 'shēn',
      Definitions: 'deep; late; profound; dark (of color, water etc); deep',
      hskLevel: 4,
    },
    {
      Hanzi: '申请',
      Pinyin: 'shēn qǐng',
      Definitions: 'to apply for sth; application (form etc); ',
      hskLevel: 4,
    },
    {
      Hanzi: '甚至',
      Pinyin: 'shèn zhì',
      Definitions: 'so much so that; even',
      hskLevel: 4,
    },
    {
      Hanzi: '生活',
      Pinyin: 'shēng huó',
      Definitions: 'life; activity; to live; livelihood',
      hskLevel: 4,
    },
    {
      Hanzi: '生命',
      Pinyin: 'shēng mìng',
      Definitions: 'life; living; biological; ',
      hskLevel: 4,
    },
    {
      Hanzi: '生意',
      Pinyin: 'shēng yì',
      Definitions: 'life force; vitality;',
      hskLevel: 4,
    },
    {
      Hanzi: '省',
      Pinyin: 'shěng',
      Definitions:
        'to do without; to economize; to save; to omit; to leave out; province; ',
      hskLevel: 4,
    },
    {
      Hanzi: '剩',
      Pinyin: 'shèng',
      Definitions: 'to remain; to be left; to have as remainder',
      hskLevel: 4,
    },
    {
      Hanzi: '失败',
      Pinyin: 'shī bài',
      Definitions:
        'to be defeated; to lose; to fail (e.g. experiments); failure; defeat; ',
      hskLevel: 4,
    },
    {
      Hanzi: '失望',
      Pinyin: 'shī wàng',
      Definitions: 'disappointed; to lose hope; to despair',
      hskLevel: 4,
    },
    {
      Hanzi: '师傅',
      Pinyin: 'shī fu',
      Definitions:
        'master; qualified worker; respectful form of address for older men; ',
      hskLevel: 4,
    },
    {
      Hanzi: '十分',
      Pinyin: 'shí fēn',
      Definitions:
        'to divide into ten equal parts; very; hundred percent; completely; extremely; utterly; absolutely',
      hskLevel: 4,
    },
    {
      Hanzi: '实际',
      Pinyin: 'shí jì',
      Definitions: 'actual; reality; practice',
      hskLevel: 4,
    },
    {
      Hanzi: '实在',
      Pinyin: 'shí zài',
      Definitions: 'in reality; honestly; really; verily; concrete',
      hskLevel: 4,
    },
    {
      Hanzi: '使',
      Pinyin: 'shǐ',
      Definitions:
        'to cause; to enable; to use; to employ; to make; to send; to instruct sb to do sth; envoy; messenger',
      hskLevel: 4,
    },
    {
      Hanzi: '使用',
      Pinyin: 'shǐ yòng',
      Definitions: 'to use; to employ; to apply; to make use of',
      hskLevel: 4,
    },
    {
      Hanzi: '世纪',
      Pinyin: 'shì jì',
      Definitions: 'century; ',
      hskLevel: 4,
    },
    {
      Hanzi: '是否',
      Pinyin: 'shì fǒu',
      Definitions: "whether (or not); if; is or isn't",
      hskLevel: 4,
    },
    {
      Hanzi: '适合',
      Pinyin: 'shì hé',
      Definitions: 'to fit; to suit',
      hskLevel: 4,
    },
    {
      Hanzi: '适应',
      Pinyin: 'shì yìng',
      Definitions:
        'to suit; to fit; to be suitable; to adapt; to get used to sth',
      hskLevel: 4,
    },
    {
      Hanzi: '收',
      Pinyin: 'shōu',
      Definitions:
        'to receive; to accept; to collect; in care of (used on address line after name)',
      hskLevel: 4,
    },
    {
      Hanzi: '收入',
      Pinyin: 'shōu rù',
      Definitions: 'to take in; income; revenue; ',
      hskLevel: 4,
    },
    {
      Hanzi: '收拾',
      Pinyin: 'shōu shi',
      Definitions:
        'to put in order; to tidy up; to pack; to repair; to punish (colloquial); to manage',
      hskLevel: 4,
    },
    {
      Hanzi: '首都',
      Pinyin: 'shǒu dū',
      Definitions: 'capital (city); ',
      hskLevel: 4,
    },
    {
      Hanzi: '首先',
      Pinyin: 'shǒu xiān',
      Definitions: 'first (of all); in the first place',
      hskLevel: 4,
    },
    {
      Hanzi: '受不了',
      Pinyin: 'shòu bù liǎo',
      Definitions: "unbearable; unable to endure; can't stand",
      hskLevel: 4,
    },
    {
      Hanzi: '受到',
      Pinyin: 'shòu dào',
      Definitions: 'to suffer; obtained; given; to receive',
      hskLevel: 4,
    },
    {
      Hanzi: '售货员',
      Pinyin: 'shòu huò yuán',
      Definitions: 'salesperson; ',
      hskLevel: 4,
    },
    {
      Hanzi: '输',
      Pinyin: 'shū',
      Definitions: 'to transport; to lose; to donate; to enter (a password)',
      hskLevel: 4,
    },
    {
      Hanzi: '熟悉',
      Pinyin: 'shú xī',
      Definitions: 'to be familiar with; to know well',
      hskLevel: 4,
    },
    {
      Hanzi: '数量',
      Pinyin: 'shù liàng',
      Definitions: 'quantity; amount; ',
      hskLevel: 4,
    },
    {
      Hanzi: '数字',
      Pinyin: 'shù zì',
      Definitions:
        'numeral; digit; number; figure; amount; digital (electronics etc); ',
      hskLevel: 4,
    },
    {
      Hanzi: '帅',
      Pinyin: 'shuài',
      Definitions: 'handsome; graceful; smart; commander in chief',
      hskLevel: 4,
    },
    {
      Hanzi: '顺便',
      Pinyin: 'shùn biàn',
      Definitions: 'conveniently; in passing; without much extra effort',
      hskLevel: 4,
    },
    {
      Hanzi: '顺利',
      Pinyin: 'shùn lì',
      Definitions: 'smoothly; without a hitch',
      hskLevel: 4,
    },
    {
      Hanzi: '顺序',
      Pinyin: 'shùn xù',
      Definitions: 'sequence; order',
      hskLevel: 4,
    },
    {
      Hanzi: '说明',
      Pinyin: 'shuō míng',
      Definitions:
        'to explain; to illustrate; explanation; directions; caption; ',
      hskLevel: 4,
    },
    {
      Hanzi: '硕士',
      Pinyin: 'shuò shì',
      Definitions: "master's degree; learned person",
      hskLevel: 4,
    },
    {
      Hanzi: '死',
      Pinyin: 'sǐ',
      Definitions:
        'to die; impassable; uncrossable; inflexible; rigid; extremely',
      hskLevel: 4,
    },
    {
      Hanzi: '塑料袋',
      Pinyin: 'sù liào dài',
      Definitions: 'plastic bag',
      hskLevel: 4,
    },
    {
      Hanzi: '速度',
      Pinyin: 'sù dù',
      Definitions: 'speed; rate; velocity; ',
      hskLevel: 4,
    },
    {
      Hanzi: '酸',
      Pinyin: 'suān',
      Definitions: 'sour; tart; sick at heart; grieved; sore; aching; acid',
      hskLevel: 4,
    },
    {
      Hanzi: '随便',
      Pinyin: 'suí biàn',
      Definitions:
        'as one wishes; as one pleases; at random; negligent; casual; wanton',
      hskLevel: 4,
    },
    {
      Hanzi: '随着',
      Pinyin: 'suí zhe',
      Definitions: 'along with; in the wake of; following',
      hskLevel: 4,
    },
    {
      Hanzi: '孙子',
      Pinyin: 'sūn zi',
      Definitions: 'grandson (paternal)',
      hskLevel: 4,
    },
    {
      Hanzi: '所有',
      Pinyin: 'suǒ yǒu',
      Definitions: 'all; to possess; to have; to own',
      hskLevel: 4,
    },
    {
      Hanzi: '台',
      Pinyin: 'tái',
      Definitions:
        'desk; platform; stage; terrace; stand; support; station; broadcasting station; desk; classifier for vehicles or machines; Taiwan (abbr.)',
      hskLevel: 4,
    },
    {
      Hanzi: '抬',
      Pinyin: 'tái',
      Definitions: 'to lift; to raise; (of two or more persons) to carry',
      hskLevel: 4,
    },
    {
      Hanzi: '态度',
      Pinyin: 'tài du',
      Definitions: 'manner; bearing; attitude; approach; ',
      hskLevel: 4,
    },
    {
      Hanzi: '弹钢琴',
      Pinyin: 'tán gāng qín',
      Definitions: 'play the piano',
      hskLevel: 4,
    },
    {
      Hanzi: '谈',
      Pinyin: 'tán',
      Definitions: 'to discuss; to chat; to converse; to talk; to speak',
      hskLevel: 4,
    },
    {
      Hanzi: '汤',
      Pinyin: 'tāng',
      Definitions: 'soup; broth; hot water',
      hskLevel: 4,
    },
    {
      Hanzi: '糖',
      Pinyin: 'táng',
      Definitions: 'sugar; sweets; candy; ',
      hskLevel: 4,
    },
    {
      Hanzi: '躺',
      Pinyin: 'tǎng',
      Definitions: 'to recline; to lie down',
      hskLevel: 4,
    },
    {
      Hanzi: '趟',
      Pinyin: 'tàng',
      Definitions: 'quantifier for the number of trips or runs made',
      hskLevel: 4,
    },
    {
      Hanzi: '讨论',
      Pinyin: 'tǎo lùn',
      Definitions: 'to talk over; to discuss; ',
      hskLevel: 4,
    },
    {
      Hanzi: '讨厌',
      Pinyin: 'tǎo yàn',
      Definitions:
        'to dislike; to loathe; disagreeable; troublesome; annoying; nasty; to hate doing something',
      hskLevel: 4,
    },
    {
      Hanzi: '特点',
      Pinyin: 'tè diǎn',
      Definitions: 'characteristic (feature); trait; feature; ',
      hskLevel: 4,
    },
    {
      Hanzi: '提',
      Pinyin: 'tí',
      Definitions: 'to carry; to lift; to raise',
      hskLevel: 4,
    },
    {
      Hanzi: '提供',
      Pinyin: 'tí gōng',
      Definitions: 'to supply; to provide; to furnish; to offer',
      hskLevel: 4,
    },
    {
      Hanzi: '提前',
      Pinyin: 'tí qián',
      Definitions:
        'to shift to an earlier date; to bring forward; to advance; in advance',
      hskLevel: 4,
    },
    {
      Hanzi: '提醒',
      Pinyin: 'tí xǐng',
      Definitions: 'to call attention to; to warn of; to remind',
      hskLevel: 4,
    },
    {
      Hanzi: '填空',
      Pinyin: 'tián kòng',
      Definitions:
        'to fill a job vacancy; to fill in a blank (e.g. on questionnaire or exam paper)',
      hskLevel: 4,
    },
    {
      Hanzi: '条件',
      Pinyin: 'tiáo jiàn',
      Definitions:
        'circumstances; condition; term; factor; requirement; prerequisite; qualification; ',
      hskLevel: 4,
    },
    {
      Hanzi: '停',
      Pinyin: 'tíng',
      Definitions: 'to halt; to stop; to park (a car);',
      hskLevel: 4,
    },
    {
      Hanzi: '挺',
      Pinyin: 'tǐng',
      Definitions:
        'to (physically) straighten up; to stick out; to endure or hold out; straight; stiff; outstanding; extraordinary; rather; quite; very; classifier for machine guns',
      hskLevel: 4,
    },
    {
      Hanzi: '通过',
      Pinyin: 'tōng guò',
      Definitions:
        'to pass through; to get through; by means of; through; via; to adopt; to pass (a bill); to switch over',
      hskLevel: 4,
    },
    {
      Hanzi: '通知',
      Pinyin: 'tōng zhī',
      Definitions: 'to notify; to inform; notice; notification; ',
      hskLevel: 4,
    },
    {
      Hanzi: '同情',
      Pinyin: 'tóng qíng',
      Definitions: 'compassion; relent; sympathize; sympathy',
      hskLevel: 4,
    },
    {
      Hanzi: '同时',
      Pinyin: 'tóng shí',
      Definitions: 'at the same time; simultaneously',
      hskLevel: 4,
    },
    {
      Hanzi: '推',
      Pinyin: 'tuī',
      Definitions:
        'to push; to cut; to refuse; to reject; to decline; to shirk (responsibility); to put off; to delay; to push forward; to nominate; to elect',
      hskLevel: 4,
    },
    {
      Hanzi: '推迟',
      Pinyin: 'tuī chí',
      Definitions: 'to postpone; to put off; to defer',
      hskLevel: 4,
    },
    {
      Hanzi: '脱',
      Pinyin: 'tuō',
      Definitions: 'to shed; to take off; to escape; to get away from',
      hskLevel: 4,
    },
    {
      Hanzi: '袜子',
      Pinyin: 'wà zi',
      Definitions: 'socks; stockings; ',
      hskLevel: 4,
    },
    {
      Hanzi: '完全',
      Pinyin: 'wán quán',
      Definitions: 'complete; whole; totally; entirely',
      hskLevel: 4,
    },
    {
      Hanzi: '往往',
      Pinyin: 'wǎng wǎng',
      Definitions: 'often; frequently',
      hskLevel: 4,
    },
    {
      Hanzi: '网球',
      Pinyin: 'wǎng qiú',
      Definitions: 'tennis; ',
      hskLevel: 4,
    },
    {
      Hanzi: '网站',
      Pinyin: 'wǎng zhàn',
      Definitions: 'website; network station; node',
      hskLevel: 4,
    },
    {
      Hanzi: '危险',
      Pinyin: 'wēi xiǎn',
      Definitions: 'danger; dangerous',
      hskLevel: 4,
    },
    {
      Hanzi: '卫生间',
      Pinyin: 'wèi shēng jiān',
      Definitions: 'bathroom; toilet; WC; ',
      hskLevel: 4,
    },
    {
      Hanzi: '味道',
      Pinyin: 'wèi dào',
      Definitions: 'flavor; smell; hint of',
      hskLevel: 4,
    },
    {
      Hanzi: '温度',
      Pinyin: 'wēn dù',
      Definitions: 'temperature; ',
      hskLevel: 4,
    },
    {
      Hanzi: '文章',
      Pinyin: 'wén zhāng',
      Definitions: 'article; essay; literary works; writings; hidden meaning; ',
      hskLevel: 4,
    },
    {
      Hanzi: '污染',
      Pinyin: 'wū rǎn',
      Definitions: 'pollution; contamination; ',
      hskLevel: 4,
    },
    {
      Hanzi: '无',
      Pinyin: 'wú',
      Definitions: 'un-; to lack; -less; not to have; no; none; not; to lack',
      hskLevel: 4,
    },
    {
      Hanzi: '无聊',
      Pinyin: 'wú liáo',
      Definitions: 'nonsense; bored',
      hskLevel: 4,
    },
    {
      Hanzi: '无论',
      Pinyin: 'wú lùn',
      Definitions: 'no matter what or how; regardless of whether...',
      hskLevel: 4,
    },
    {
      Hanzi: '误会',
      Pinyin: 'wù huì',
      Definitions: 'to misunderstand; to mistake; misunderstanding; ',
      hskLevel: 4,
    },
    {
      Hanzi: '吸引',
      Pinyin: 'xī yǐn',
      Definitions: 'to attract (interest, investment etc); ',
      hskLevel: 4,
    },
    {
      Hanzi: '西红柿',
      Pinyin: 'xī hóng shì',
      Definitions: 'tomato; ',
      hskLevel: 4,
    },
    {
      Hanzi: '咸',
      Pinyin: 'xián',
      Definitions: 'salty',
      hskLevel: 4,
    },
    {
      Hanzi: '现金',
      Pinyin: 'xiàn jīn',
      Definitions: 'cash',
      hskLevel: 4,
    },
    {
      Hanzi: '羡慕',
      Pinyin: 'xiàn mù',
      Definitions: 'envious; envy; to admire',
      hskLevel: 4,
    },
    {
      Hanzi: '相反',
      Pinyin: 'xiāng fǎn',
      Definitions: 'opposite; contrary',
      hskLevel: 4,
    },
    {
      Hanzi: '相同',
      Pinyin: 'xiāng tóng',
      Definitions: 'identical; same',
      hskLevel: 4,
    },
    {
      Hanzi: '香',
      Pinyin: 'xiāng',
      Definitions:
        'fragrant; sweet smelling; aromatic; savory or appetizing; (to eat) with relish; (of sleep) sound; perfume or spice; joss or incense stick; ',
      hskLevel: 4,
    },
    {
      Hanzi: '详细',
      Pinyin: 'xiáng xì',
      Definitions: 'detailed; in detail; minute',
      hskLevel: 4,
    },
    {
      Hanzi: '响',
      Pinyin: 'xiǎng',
      Definitions:
        'to make a sound; to sound; to ring; loud; classifier for noises',
      hskLevel: 4,
    },
    {
      Hanzi: '橡皮',
      Pinyin: 'xiàng pí',
      Definitions: 'rubber; an eraser; ',
      hskLevel: 4,
    },
    {
      Hanzi: '消息',
      Pinyin: 'xiāo xi',
      Definitions: 'news; information; ',
      hskLevel: 4,
    },
    {
      Hanzi: '小吃',
      Pinyin: 'xiǎo chī',
      Definitions: 'snack; refreshments; ',
      hskLevel: 4,
    },
    {
      Hanzi: '小伙子',
      Pinyin: 'xiǎo huǒ zi',
      Definitions: 'lad; young fellow; young man; young guy; youngster; ',
      hskLevel: 4,
    },
    {
      Hanzi: '小说',
      Pinyin: 'xiǎo shuō',
      Definitions: 'novel; fiction; ',
      hskLevel: 4,
    },
    {
      Hanzi: '效果',
      Pinyin: 'xiào guǒ',
      Definitions: 'result; effect; quality; ',
      hskLevel: 4,
    },
    {
      Hanzi: '笑话',
      Pinyin: 'xiào huà',
      Definitions: 'joke; jest; ',
      hskLevel: 4,
    },
    {
      Hanzi: '心情',
      Pinyin: 'xīn qíng',
      Definitions: 'mood; frame of mind; ',
      hskLevel: 4,
    },
    {
      Hanzi: '辛苦',
      Pinyin: 'xīn kǔ',
      Definitions: 'hard; exhausting; with much toil; thanks for your trouble',
      hskLevel: 4,
    },
    {
      Hanzi: '信封',
      Pinyin: 'xìn fēng',
      Definitions: 'envelope; ',
      hskLevel: 4,
    },
    {
      Hanzi: '信息',
      Pinyin: 'xìn xī',
      Definitions: 'information; news; message',
      hskLevel: 4,
    },
    {
      Hanzi: '信心',
      Pinyin: 'xìn xīn',
      Definitions: 'confidence; faith (in sb or sth); ',
      hskLevel: 4,
    },
    {
      Hanzi: '兴奋',
      Pinyin: 'xīng fèn',
      Definitions: 'excited; excitement',
      hskLevel: 4,
    },
    {
      Hanzi: '行',
      Pinyin: 'xíng',
      Definitions:
        'to walk; to go; to travel; a visit; temporary; makeshift; current; in circulation; to do; to perform; capable; competent; effective; all right; OK!; will do',
      hskLevel: 4,
    },
    {
      Hanzi: '醒',
      Pinyin: 'xǐng',
      Definitions: 'to wake up; to awaken; to be awake',
      hskLevel: 4,
    },
    {
      Hanzi: '幸福',
      Pinyin: 'xìng fú',
      Definitions: 'happiness; happy; blessed; well-being',
      hskLevel: 4,
    },
    {
      Hanzi: '性别',
      Pinyin: 'xìng bié',
      Definitions: 'gender; sex; distinguishing between the sexes',
      hskLevel: 4,
    },
    {
      Hanzi: '性格',
      Pinyin: 'xìng gé',
      Definitions:
        'nature (temperament); nature; disposition; temperament; character; ',
      hskLevel: 4,
    },
    {
      Hanzi: '修理',
      Pinyin: 'xiū lǐ',
      Definitions:
        'to repair; to perform maintenance; to overhaul; to fix; to prune; to trim',
      hskLevel: 4,
    },
    {
      Hanzi: '许多',
      Pinyin: 'xǔ duō',
      Definitions: 'a great deal of; many; a lot of; much',
      hskLevel: 4,
    },
    {
      Hanzi: '学期',
      Pinyin: 'xué qī',
      Definitions: 'school term; semester; ',
      hskLevel: 4,
    },
    {
      Hanzi: '呀',
      Pinyin: 'ya',
      Definitions:
        '(particle equivalent to 啊 after a vowel, expressing surprise or doubt)',
      hskLevel: 4,
    },
    {
      Hanzi: '压力',
      Pinyin: 'yā lì',
      Definitions: 'pressure',
      hskLevel: 4,
    },
    {
      Hanzi: '牙膏',
      Pinyin: 'yá gāo',
      Definitions: 'toothpaste; ',
      hskLevel: 4,
    },
    {
      Hanzi: '亚洲',
      Pinyin: 'yà zhōu',
      Definitions: 'Asia; Asian',
      hskLevel: 4,
    },
    {
      Hanzi: '严格',
      Pinyin: 'yán gé',
      Definitions: 'strict; stringent; tight; rigorous',
      hskLevel: 4,
    },
    {
      Hanzi: '严重',
      Pinyin: 'yán zhòng',
      Definitions: 'serious; severe; critical; grave',
      hskLevel: 4,
    },
    {
      Hanzi: '盐',
      Pinyin: 'yán',
      Definitions: 'salt; ',
      hskLevel: 4,
    },
    {
      Hanzi: '研究',
      Pinyin: 'yán jiū',
      Definitions: 'research; a study; ',
      hskLevel: 4,
    },
    {
      Hanzi: '演出',
      Pinyin: 'yǎn chū',
      Definitions:
        'to act (in a play); to perform; to put on (a performance); performance; concert; show; ',
      hskLevel: 4,
    },
    {
      Hanzi: '演员',
      Pinyin: 'yǎn yuán',
      Definitions: 'actor or actress; performer; ',
      hskLevel: 4,
    },
    {
      Hanzi: '眼镜',
      Pinyin: 'yǎn jìng',
      Definitions: 'spectacles; eyeglasses; ',
      hskLevel: 4,
    },
    {
      Hanzi: '阳光',
      Pinyin: 'yáng guāng',
      Definitions:
        'sunlight; sunshine; transparent (open to public scrutinity); ',
      hskLevel: 4,
    },
    {
      Hanzi: '养成',
      Pinyin: 'yǎng chéng',
      Definitions: 'to cultivate; to raise; to form (a habit); to acquire',
      hskLevel: 4,
    },
    {
      Hanzi: '样子',
      Pinyin: 'yàng zi',
      Definitions: 'manner; air; looks; aspect',
      hskLevel: 4,
    },
    {
      Hanzi: '邀请',
      Pinyin: 'yāo qǐng',
      Definitions: 'to invite; invitation; ',
      hskLevel: 4,
    },
    {
      Hanzi: '要是',
      Pinyin: 'yào shi',
      Definitions: 'if',
      hskLevel: 4,
    },
    {
      Hanzi: '钥匙',
      Pinyin: 'yào shi',
      Definitions: 'key; ',
      hskLevel: 4,
    },
    {
      Hanzi: '也许',
      Pinyin: 'yě xǔ',
      Definitions: 'perhaps; maybe; probably',
      hskLevel: 4,
    },
    {
      Hanzi: '叶子',
      Pinyin: 'yè zi',
      Definitions: 'foliage; leaf; ',
      hskLevel: 4,
    },
    {
      Hanzi: '页',
      Pinyin: 'yè',
      Definitions: 'page; leaf',
      hskLevel: 4,
    },
    {
      Hanzi: '一切',
      Pinyin: 'yī qiè',
      Definitions: 'everything; every; all',
      hskLevel: 4,
    },
    {
      Hanzi: '以',
      Pinyin: 'yǐ',
      Definitions: 'to use as; according to; because of',
      hskLevel: 4,
    },
    {
      Hanzi: '以为',
      Pinyin: 'yǐ wéi',
      Definitions:
        'to believe; to think; to consider; to be under the impression',
      hskLevel: 4,
    },
    {
      Hanzi: '意见',
      Pinyin: 'yì jiàn',
      Definitions: 'opinion; suggestion; idea; objection; complaint; ',
      hskLevel: 4,
    },
    {
      Hanzi: '艺术',
      Pinyin: 'yì shù',
      Definitions: 'art; ',
      hskLevel: 4,
    },
    {
      Hanzi: '因此',
      Pinyin: 'yīn cǐ',
      Definitions: 'consequently; as a result; thus',
      hskLevel: 4,
    },
    {
      Hanzi: '引起',
      Pinyin: 'yǐn qǐ',
      Definitions: 'to give rise to; to lead to; to cause; to arouse',
      hskLevel: 4,
    },
    {
      Hanzi: '印象',
      Pinyin: 'yìn xiàng',
      Definitions: 'impression; reflection; perception; ',
      hskLevel: 4,
    },
    {
      Hanzi: '赢',
      Pinyin: 'yíng',
      Definitions: 'to win; to beat; to profit',
      hskLevel: 4,
    },
    {
      Hanzi: '应聘',
      Pinyin: 'yìng pìn',
      Definitions: 'to accept a job offer; to apply for an advertised position',
      hskLevel: 4,
    },
    {
      Hanzi: '勇敢',
      Pinyin: 'yǒng gǎn',
      Definitions: 'brave; courageous',
      hskLevel: 4,
    },
    {
      Hanzi: '永远',
      Pinyin: 'yǒng yuǎn',
      Definitions: 'forever; eternal',
      hskLevel: 4,
    },
    {
      Hanzi: '优点',
      Pinyin: 'yōu diǎn',
      Definitions: 'merit; benefit; strong point; advantage; ',
      hskLevel: 4,
    },
    {
      Hanzi: '优秀',
      Pinyin: 'yōu xiù',
      Definitions: 'outstanding; excellent',
      hskLevel: 4,
    },
    {
      Hanzi: '幽默',
      Pinyin: 'yōu mò',
      Definitions: 'humor; humorous',
      hskLevel: 4,
    },
    {
      Hanzi: '尤其',
      Pinyin: 'yóu qí',
      Definitions: 'especially; particularly',
      hskLevel: 4,
    },
    {
      Hanzi: '由',
      Pinyin: 'yóu',
      Definitions:
        'to follow; from; it is for...to; reason; cause; because of; due to; to; to leave it (to sb); by (introduces passive verb)',
      hskLevel: 4,
    },
    {
      Hanzi: '由于',
      Pinyin: 'yóu yú',
      Definitions:
        'due to; as a result of; thanks to; owing to; since; because',
      hskLevel: 4,
    },
    {
      Hanzi: '邮局',
      Pinyin: 'yóu jú',
      Definitions: 'post office; ',
      hskLevel: 4,
    },
    {
      Hanzi: '友好',
      Pinyin: 'yǒu hǎo',
      Definitions: 'friendly; amicable',
      hskLevel: 4,
    },
    {
      Hanzi: '友谊',
      Pinyin: 'yǒu yì',
      Definitions: 'companionship; fellowship; friendship',
      hskLevel: 4,
    },
    {
      Hanzi: '有趣',
      Pinyin: 'yǒu qù',
      Definitions: 'interesting; fascinating; amusing',
      hskLevel: 4,
    },
    {
      Hanzi: '于是',
      Pinyin: 'yú shì',
      Definitions: 'thereupon; as a result; consequently; thus; hence',
      hskLevel: 4,
    },
    {
      Hanzi: '愉快',
      Pinyin: 'yú kuài',
      Definitions:
        'cheerful; cheerily; delightful; pleasant; pleasantly; pleasing; happy; delighted',
      hskLevel: 4,
    },
    {
      Hanzi: '与',
      Pinyin: 'yǔ',
      Definitions: 'to give; to help; to get along with; and; with',
      hskLevel: 4,
    },
    {
      Hanzi: '羽毛球',
      Pinyin: 'yǔ máo qiú',
      Definitions: 'badminton; shuttlecock; ',
      hskLevel: 4,
    },
    {
      Hanzi: '语法',
      Pinyin: 'yǔ fǎ',
      Definitions: 'grammar',
      hskLevel: 4,
    },
    {
      Hanzi: '语言',
      Pinyin: 'yǔ yán',
      Definitions: 'language; ',
      hskLevel: 4,
    },
    {
      Hanzi: '预习',
      Pinyin: 'yù xí',
      Definitions: 'to prepare a lesson',
      hskLevel: 4,
    },
    {
      Hanzi: '原来',
      Pinyin: 'yuán lái',
      Definitions:
        'original; former; originally; formerly; at first; so... actually',
      hskLevel: 4,
    },
    {
      Hanzi: '原谅',
      Pinyin: 'yuán liàng',
      Definitions: 'to excuse; to forgive; to pardon',
      hskLevel: 4,
    },
    {
      Hanzi: '原因',
      Pinyin: 'yuán yīn',
      Definitions: 'cause; origin; root cause; reason; ',
      hskLevel: 4,
    },
    {
      Hanzi: '约会',
      Pinyin: 'yuē huì',
      Definitions: 'appointment; engagement; date; ',
      hskLevel: 4,
    },
    {
      Hanzi: '阅读',
      Pinyin: 'yuè dú',
      Definitions: 'to read; reading',
      hskLevel: 4,
    },
    {
      Hanzi: '云',
      Pinyin: 'yún',
      Definitions: 'cloud; ',
      hskLevel: 4,
    },
    {
      Hanzi: '允许',
      Pinyin: 'yǔn xǔ',
      Definitions: 'to permit; to allow',
      hskLevel: 4,
    },
    {
      Hanzi: '杂志',
      Pinyin: 'zá zhì',
      Definitions: 'magazine; ',
      hskLevel: 4,
    },
    {
      Hanzi: '咱们',
      Pinyin: 'zán men',
      Definitions:
        'we or us (including both the speaker and the person(s) spoken to); I or me; you',
      hskLevel: 4,
    },
    {
      Hanzi: '暂时',
      Pinyin: 'zàn shí',
      Definitions: 'temporary; provisional; for the time being',
      hskLevel: 4,
    },
    {
      Hanzi: '脏',
      Pinyin: 'zāng',
      Definitions: 'dirty',
      hskLevel: 4,
    },
    {
      Hanzi: '责任',
      Pinyin: 'zé rèn',
      Definitions: 'responsibility; blame; duty; ',
      hskLevel: 4,
    },
    {
      Hanzi: '增加',
      Pinyin: 'zēng jiā',
      Definitions: 'to raise; to increase',
      hskLevel: 4,
    },
    {
      Hanzi: '占线',
      Pinyin: 'zhàn xiàn',
      Definitions: 'busy (telephone)',
      hskLevel: 4,
    },
    {
      Hanzi: '招聘',
      Pinyin: 'zhāo pìn',
      Definitions: 'recruitment; to invite applications for a job',
      hskLevel: 4,
    },
    {
      Hanzi: '照',
      Pinyin: 'zhào',
      Definitions:
        "according to; in accordance with; to shine; to illuminate; to reflect; to look at (one's reflection); to take (a photo); photo; as requested; as before;",
      hskLevel: 4,
    },
    {
      Hanzi: '真正',
      Pinyin: 'zhēn zhèng',
      Definitions: 'genuine; real; true; genuinely',
      hskLevel: 4,
    },
    {
      Hanzi: '整理',
      Pinyin: 'zhěng lǐ',
      Definitions:
        'to arrange; to tidy up; to sort out; to straighten out; to list systematically; to collate (data, files); to pack (luggage)',
      hskLevel: 4,
    },
    {
      Hanzi: '正常',
      Pinyin: 'zhèng cháng',
      Definitions: 'regular; normal; ordinary',
      hskLevel: 4,
    },
    {
      Hanzi: '正好',
      Pinyin: 'zhèng hǎo',
      Definitions:
        'just (in time); just right; just enough; to happen to; to chance to; by chance; it just so happens that',
      hskLevel: 4,
    },
    {
      Hanzi: '正确',
      Pinyin: 'zhèng què',
      Definitions: 'correct; proper',
      hskLevel: 4,
    },
    {
      Hanzi: '正式',
      Pinyin: 'zhèng shì',
      Definitions: 'formal; official',
      hskLevel: 4,
    },
    {
      Hanzi: '证明',
      Pinyin: 'zhèng míng',
      Definitions:
        'proof; certificate; identification; testimonial; to prove; to testify; to confirm the truth of; ',
      hskLevel: 4,
    },
    {
      Hanzi: '之',
      Pinyin: 'zhī',
      Definitions:
        '(possessive particle, literary equivalent of 的); him; her; its',
      hskLevel: 4,
    },
    {
      Hanzi: '支持',
      Pinyin: 'zhī chí',
      Definitions:
        'to be in favor of; to support; to back; support; backing; to stand by; ',
      hskLevel: 4,
    },
    {
      Hanzi: '知识',
      Pinyin: 'zhī shi',
      Definitions: 'intellectual; knowledge-related; knowledge; ',
      hskLevel: 4,
    },
    {
      Hanzi: '值得',
      Pinyin: 'zhí de',
      Definitions: 'to be worth; to deserve',
      hskLevel: 4,
    },
    {
      Hanzi: '植物',
      Pinyin: 'zhí wù',
      Definitions: 'botanical; plant; vegetation; ',
      hskLevel: 4,
    },
    {
      Hanzi: '直接',
      Pinyin: 'zhí jiē',
      Definitions:
        'direct; opposite: indirect 间接; immediate; directly; straightforward',
      hskLevel: 4,
    },
    {
      Hanzi: '职业',
      Pinyin: 'zhí yè',
      Definitions: 'occupation; profession; vocation; professional',
      hskLevel: 4,
    },
    {
      Hanzi: '只好',
      Pinyin: 'zhǐ hǎo',
      Definitions: 'without any better option; to have to; to be forced to',
      hskLevel: 4,
    },
    {
      Hanzi: '只要',
      Pinyin: 'zhǐ yào',
      Definitions: 'if only; so long as',
      hskLevel: 4,
    },
    {
      Hanzi: '指',
      Pinyin: 'zhǐ',
      Definitions:
        'finger; to point at or to; to indicate or refer to; to depend on; to count on; (of hair) to stand on end',
      hskLevel: 4,
    },
    {
      Hanzi: '至少',
      Pinyin: 'zhì shǎo',
      Definitions: 'at least; (to say the) least',
      hskLevel: 4,
    },
    {
      Hanzi: '质量',
      Pinyin: 'zhì liàng',
      Definitions: 'quality; mass (in physics); ',
      hskLevel: 4,
    },
    {
      Hanzi: '重',
      Pinyin: 'zhòng',
      Definitions: 'heavy; serious',
      hskLevel: 4,
    },
    {
      Hanzi: '重点',
      Pinyin: 'zhòng diǎn',
      Definitions:
        'emphasis; focal point; priority; key; with the emphasis on; focusing on',
      hskLevel: 4,
    },
    {
      Hanzi: '重视',
      Pinyin: 'zhòng shì',
      Definitions: 'to attach importance to sth; to value',
      hskLevel: 4,
    },
    {
      Hanzi: '周围',
      Pinyin: 'zhōu wéi',
      Definitions: 'surroundings; environment; to encompass',
      hskLevel: 4,
    },
    {
      Hanzi: '主意',
      Pinyin: 'zhǔ yi',
      Definitions: 'plan; idea; decision; ',
      hskLevel: 4,
    },
    {
      Hanzi: '祝贺',
      Pinyin: 'zhù hè',
      Definitions: 'to congratulate; congratulations; ',
      hskLevel: 4,
    },
    {
      Hanzi: '著名',
      Pinyin: 'zhù míng',
      Definitions: 'famous; noted; well-known; celebrated',
      hskLevel: 4,
    },
    {
      Hanzi: '专门',
      Pinyin: 'zhuān mén',
      Definitions: 'specialist; specialized; customized',
      hskLevel: 4,
    },
    {
      Hanzi: '专业',
      Pinyin: 'zhuān yè',
      Definitions:
        'specialty; specialized field; main field of study (at university); major; professional; ',
      hskLevel: 4,
    },
    {
      Hanzi: '转',
      Pinyin: 'zhuǎn',
      Definitions:
        'to turn; to change direction; to transfer; to forward (mail);',
      hskLevel: 4,
    },
    {
      Hanzi: '赚',
      Pinyin: 'zhuàn',
      Definitions: 'earn; make a profit',
      hskLevel: 4,
    },
    {
      Hanzi: '准确',
      Pinyin: 'zhǔn què',
      Definitions: 'accurate; exact; precise',
      hskLevel: 4,
    },
    {
      Hanzi: '准时',
      Pinyin: 'zhǔn shí',
      Definitions: 'on time; punctual; on schedule',
      hskLevel: 4,
    },
    {
      Hanzi: '仔细',
      Pinyin: 'zǐ xì',
      Definitions: 'careful; attentive; cautious',
      hskLevel: 4,
    },
    {
      Hanzi: '自然',
      Pinyin: 'zì rán',
      Definitions: 'at ease; free from affectation; natural',
      hskLevel: 4,
    },
    {
      Hanzi: '自信',
      Pinyin: 'zì xìn',
      Definitions: 'confidence; self-confidence',
      hskLevel: 4,
    },
    {
      Hanzi: '总结',
      Pinyin: 'zǒng jié',
      Definitions: 'to sum up; to conclude; summary; resume; ',
      hskLevel: 4,
    },
    {
      Hanzi: '租',
      Pinyin: 'zū',
      Definitions:
        'to rent; to charter; to rent out; to lease out; to hire; rent; land tax',
      hskLevel: 4,
    },
    {
      Hanzi: '最好',
      Pinyin: 'zuì hǎo',
      Definitions: 'best; (you) had better (do what we suggest)',
      hskLevel: 4,
    },
    {
      Hanzi: '尊重',
      Pinyin: 'zūn zhòng',
      Definitions: 'esteem; respect; to honor; to value sth',
      hskLevel: 4,
    },
    {
      Hanzi: '左右',
      Pinyin: 'zuǒ yòu',
      Definitions:
        'approximately; about; left and right; around; (dial.) anyway',
      hskLevel: 4,
    },
    {
      Hanzi: '作家',
      Pinyin: 'zuò jiā',
      Definitions: 'author; ',
      hskLevel: 4,
    },
    {
      Hanzi: '作用',
      Pinyin: 'zuò yòng',
      Definitions:
        'to act on; to affect; action; function; activity; impact; result; effect; purpose; intent; to play a role; corresponds to English -ity, -ism, -ization; ',
      hskLevel: 4,
    },
    {
      Hanzi: '作者',
      Pinyin: 'zuò zhě',
      Definitions: 'writer; author; ',
      hskLevel: 4,
    },
    {
      Hanzi: '座',
      Pinyin: 'zuò',
      Definitions:
        'seat; base; stand; classifier for buildings, mountains and similar immovable objects; ',
      hskLevel: 4,
    },
    {
      Hanzi: '座位',
      Pinyin: 'zuò wèi',
      Definitions: 'a place to sit; ',
      hskLevel: 4,
    },
  ],
  5: [
    {
      Hanzi: '哎',
      Pinyin: 'āi',
      'Speech Part': 'int',
      Definitions: '(used to attract attention), hey, oh',
      hskLevel: 5,
    },
    {
      Hanzi: '唉',
      Pinyin: 'āi',
      'Speech Part': 'int',
      Definitions: '(a response to a call/an order)',
      hskLevel: 5,
    },
    {
      Hanzi: '爱护',
      Pinyin: 'ài hù',
      'Speech Part': 'v',
      Definitions: 'care for, take good care of',
      hskLevel: 5,
    },
    {
      Hanzi: '爱惜',
      Pinyin: 'ài xī',
      'Speech Part': 'v',
      Definitions: 'treasure, cherish',
      hskLevel: 5,
    },
    {
      Hanzi: '爱心',
      Pinyin: 'ài xīn',
      'Speech Part': 'n',
      Definitions: 'loving heart',
      hskLevel: 5,
    },
    {
      Hanzi: '安慰',
      Pinyin: 'ān wèi',
      'Speech Part': 'v',
      Definitions: 'comfort, console',
      hskLevel: 5,
    },
    {
      Hanzi: '安装',
      Pinyin: 'ān zhuāng',
      'Speech Part': 'v',
      Definitions: 'install, set up',
      hskLevel: 5,
    },
    {
      Hanzi: '岸',
      Pinyin: 'àn',
      'Speech Part': 'n',
      Definitions: 'bank, coast',
      hskLevel: 5,
    },
    {
      Hanzi: '暗',
      Pinyin: 'àn',
      'Speech Part': 'adj',
      Definitions: 'dark, dim',
      hskLevel: 5,
    },
    {
      Hanzi: '把握',
      Pinyin: 'bǎ wò',
      'Speech Part': 'n, v',
      Definitions: 'assurance; hold, grasp',
      hskLevel: 5,
    },
    {
      Hanzi: '熬夜',
      Pinyin: 'áo yè',
      'Speech Part': 'v',
      Definitions: 'stay up late',
      hskLevel: 5,
    },
    {
      Hanzi: '办理',
      Pinyin: 'bàn lǐ',
      'Speech Part': 'v',
      Definitions: 'handle, conduct',
      hskLevel: 5,
    },
    {
      Hanzi: '摆',
      Pinyin: 'bǎi',
      'Speech Part': 'v',
      Definitions: 'put, place, wave',
      hskLevel: 5,
    },
    {
      Hanzi: '傍晚',
      Pinyin: 'bàng wǎn',
      'Speech Part': 'n',
      Definitions: 'evening, dusk',
      hskLevel: 5,
    },
    {
      Hanzi: '包裹',
      Pinyin: 'bāo guǒ',
      'Speech Part': 'n',
      Definitions: 'parcel, package',
      hskLevel: 5,
    },
    {
      Hanzi: '包含',
      Pinyin: 'bāo hán',
      'Speech Part': 'v',
      Definitions: 'contain, include',
      hskLevel: 5,
    },
    {
      Hanzi: '包括',
      Pinyin: 'bāo kuò',
      'Speech Part': 'v',
      Definitions: 'include',
      hskLevel: 5,
    },
    {
      Hanzi: '薄',
      Pinyin: 'báo',
      'Speech Part': 'adj',
      Definitions: 'thin, flimsy',
      hskLevel: 5,
    },
    {
      Hanzi: '宝贝',
      Pinyin: 'bǎo bèi',
      'Speech Part': 'n',
      Definitions: 'treasure, baby',
      hskLevel: 5,
    },
    {
      Hanzi: '宝贵',
      Pinyin: 'bǎo guì',
      'Speech Part': 'adj',
      Definitions: 'valuable, precious',
      hskLevel: 5,
    },
    {
      Hanzi: '保持',
      Pinyin: 'bǎo chí',
      'Speech Part': 'v',
      Definitions: 'maintain, preserve',
      hskLevel: 5,
    },
    {
      Hanzi: '保存',
      Pinyin: 'bǎo cún',
      'Speech Part': 'v',
      Definitions: 'keep',
      hskLevel: 5,
    },
    {
      Hanzi: '保留',
      Pinyin: 'bǎo liú',
      'Speech Part': 'v',
      Definitions: 'retain, keep back',
      hskLevel: 5,
    },
    {
      Hanzi: '保险',
      Pinyin: 'bǎo xiǎn',
      'Speech Part': 'n',
      Definitions: 'insurance',
      hskLevel: 5,
    },
    {
      Hanzi: '报到',
      Pinyin: 'bào dào',
      'Speech Part': 'v',
      Definitions: 'check in, report for duty',
      hskLevel: 5,
    },
    {
      Hanzi: '报道',
      Pinyin: 'bào dào',
      'Speech Part': 'n, v',
      Definitions: 'news report; report on, cover',
      hskLevel: 5,
    },
    {
      Hanzi: '报告',
      Pinyin: 'bào gào',
      'Speech Part': 'n, v',
      Definitions: 'report',
      hskLevel: 5,
    },
    {
      Hanzi: '报社',
      Pinyin: 'bào shè',
      'Speech Part': 'n',
      Definitions: 'newspaper office',
      hskLevel: 5,
    },
    {
      Hanzi: '抱怨',
      Pinyin: 'bào yuàn',
      'Speech Part': 'v',
      Definitions: 'complain, grumble',
      hskLevel: 5,
    },
    {
      Hanzi: '背',
      Pinyin: 'bēi',
      'Speech Part': 'v',
      Definitions: 'carry on the back',
      hskLevel: 5,
    },
    {
      Hanzi: '悲观',
      Pinyin: 'bēi guān',
      'Speech Part': 'adj',
      Definitions: 'pessimistic',
      hskLevel: 5,
    },
    {
      Hanzi: '背景',
      Pinyin: 'bèi jǐng',
      'Speech Part': 'n',
      Definitions: 'background',
      hskLevel: 5,
    },
    {
      Hanzi: '被子',
      Pinyin: 'bèi zi',
      'Speech Part': 'n',
      Definitions: 'quilt',
      hskLevel: 5,
    },
    {
      Hanzi: '本科',
      Pinyin: 'běn kē',
      'Speech Part': 'n',
      Definitions: 'undergraduate course',
      hskLevel: 5,
    },
    {
      Hanzi: '本领',
      Pinyin: 'běn lǐng',
      'Speech Part': 'n',
      Definitions: 'ability, skill',
      hskLevel: 5,
    },
    {
      Hanzi: '本质',
      Pinyin: 'běn zhì',
      'Speech Part': 'n',
      Definitions: 'nature',
      hskLevel: 5,
    },
    {
      Hanzi: '比例',
      Pinyin: 'bǐ lì',
      'Speech Part': 'n',
      Definitions: 'proportion, ratio',
      hskLevel: 5,
    },
    {
      Hanzi: '彼此',
      Pinyin: 'bǐ cǐ',
      'Speech Part': 'pron',
      Definitions: 'each other',
      hskLevel: 5,
    },
    {
      Hanzi: '必然',
      Pinyin: 'bì rán',
      'Speech Part': 'adj',
      Definitions: 'inevitable',
      hskLevel: 5,
    },
    {
      Hanzi: '必要',
      Pinyin: 'bì yào',
      'Speech Part': 'adj',
      Definitions: 'necessary, essential',
      hskLevel: 5,
    },
    {
      Hanzi: '毕竟',
      Pinyin: 'bì jìng',
      'Speech Part': 'adv',
      Definitions: 'after all',
      hskLevel: 5,
    },
    {
      Hanzi: '避免',
      Pinyin: 'bì miǎn',
      'Speech Part': 'v',
      Definitions: 'avoid',
      hskLevel: 5,
    },
    {
      Hanzi: '编辑',
      Pinyin: 'biān jí',
      'Speech Part': 'n, v',
      Definitions: 'editor; edit',
      hskLevel: 5,
    },
    {
      Hanzi: '鞭炮',
      Pinyin: 'biān pào',
      'Speech Part': 'n',
      Definitions: 'firecrackers',
      hskLevel: 5,
    },
    {
      Hanzi: '便',
      Pinyin: 'biàn',
      'Speech Part': 'adv',
      Definitions: 'as soon as',
      hskLevel: 5,
    },
    {
      Hanzi: '辩论',
      Pinyin: 'biàn lùn',
      'Speech Part': 'v',
      Definitions: 'debate',
      hskLevel: 5,
    },
    {
      Hanzi: '标点',
      Pinyin: 'biāo diǎn',
      'Speech Part': 'n',
      Definitions: 'punctuation',
      hskLevel: 5,
    },
    {
      Hanzi: '标志',
      Pinyin: 'biāo zhì',
      'Speech Part': 'n, v',
      Definitions: 'symbol, sign; indicate, symbolise',
      hskLevel: 5,
    },
    {
      Hanzi: '表达',
      Pinyin: 'biǎo dá',
      'Speech Part': 'v',
      Definitions: 'express',
      hskLevel: 5,
    },
    {
      Hanzi: '表面',
      Pinyin: 'biǎo miàn',
      'Speech Part': 'n',
      Definitions: 'surface, appearance',
      hskLevel: 5,
    },
    {
      Hanzi: '表明',
      Pinyin: 'biǎo míng',
      'Speech Part': 'v',
      Definitions: 'indicate, make clear',
      hskLevel: 5,
    },
    {
      Hanzi: '表情',
      Pinyin: 'biǎo qíng',
      'Speech Part': 'n',
      Definitions: 'expression',
      hskLevel: 5,
    },
    {
      Hanzi: '表现',
      Pinyin: 'biǎo xiàn',
      'Speech Part': 'v',
      Definitions: 'show, behave',
      hskLevel: 5,
    },
    {
      Hanzi: '冰淇凌',
      Pinyin: 'bīng jī líng',
      'Speech Part': 'n',
      Definitions: 'ice cream',
      hskLevel: 5,
    },
    {
      Hanzi: '病毒',
      Pinyin: 'bìng dú',
      'Speech Part': 'n',
      Definitions: 'virus',
      hskLevel: 5,
    },
    {
      Hanzi: '玻璃',
      Pinyin: 'bō li',
      'Speech Part': 'n',
      Definitions: 'glass',
      hskLevel: 5,
    },
    {
      Hanzi: '播放',
      Pinyin: 'bō fàng',
      'Speech Part': 'v',
      Definitions: 'broadcast',
      hskLevel: 5,
    },
    {
      Hanzi: '脖子',
      Pinyin: 'bó zi',
      'Speech Part': 'n',
      Definitions: 'neck',
      hskLevel: 5,
    },
    {
      Hanzi: '博物馆',
      Pinyin: 'bó wù guǎn',
      'Speech Part': 'n',
      Definitions: 'museum',
      hskLevel: 5,
    },
    {
      Hanzi: '不断',
      Pinyin: 'bú duàn',
      'Speech Part': 'adv',
      Definitions: 'unceasingly, continuously',
      hskLevel: 5,
    },
    {
      Hanzi: '不见得',
      Pinyin: 'bú jiàn dé',
      'Speech Part': 'adv',
      Definitions: 'not likely, may not',
      hskLevel: 5,
    },
    {
      Hanzi: '不耐烦',
      Pinyin: 'bú nài fán',
      'Speech Part': '-',
      Definitions: 'impatient',
      hskLevel: 5,
    },
    {
      Hanzi: '不要紧',
      Pinyin: 'bú yào jǐn',
      'Speech Part': 'adj',
      Definitions: 'not serious, never mind',
      hskLevel: 5,
    },
    {
      Hanzi: '补充',
      Pinyin: 'bǔ chōng',
      'Speech Part': 'v',
      Definitions: 'replenish, supplement',
      hskLevel: 5,
    },
    {
      Hanzi: '不安',
      Pinyin: 'bù ān',
      'Speech Part': 'adj',
      Definitions: 'uneasy',
      hskLevel: 5,
    },
    {
      Hanzi: '不得了',
      Pinyin: 'bù dé liǎo',
      'Speech Part': '-',
      Definitions: 'terrible, extremely',
      hskLevel: 5,
    },
    {
      Hanzi: '不然',
      Pinyin: 'bù rán',
      'Speech Part': 'conj',
      Definitions: 'otherwise',
      hskLevel: 5,
    },
    {
      Hanzi: '不如',
      Pinyin: 'bù rú',
      'Speech Part': 'v',
      Definitions: 'be inferior to',
      hskLevel: 5,
    },
    {
      Hanzi: '不足',
      Pinyin: 'bù zú',
      'Speech Part': 'n, v',
      Definitions: 'insufficiency; be less than',
      hskLevel: 5,
    },
    {
      Hanzi: '布',
      Pinyin: 'bù',
      'Speech Part': 'n',
      Definitions: 'cloth',
      hskLevel: 5,
    },
    {
      Hanzi: '步骤',
      Pinyin: 'bù zhòu',
      'Speech Part': 'n',
      Definitions: 'step',
      hskLevel: 5,
    },
    {
      Hanzi: '部门',
      Pinyin: 'bù mén',
      'Speech Part': 'n',
      Definitions: 'department',
      hskLevel: 5,
    },
    {
      Hanzi: '财产',
      Pinyin: 'cái chǎn',
      'Speech Part': 'n',
      Definitions: 'property',
      hskLevel: 5,
    },
    {
      Hanzi: '采访',
      Pinyin: 'cǎi fǎng',
      'Speech Part': 'v',
      Definitions: 'interview',
      hskLevel: 5,
    },
    {
      Hanzi: '采取',
      Pinyin: 'cǎi qǔ',
      'Speech Part': 'v',
      Definitions: 'adopt',
      hskLevel: 5,
    },
    {
      Hanzi: '彩虹',
      Pinyin: 'cǎi hóng',
      'Speech Part': 'n',
      Definitions: 'rainbow',
      hskLevel: 5,
    },
    {
      Hanzi: '踩',
      Pinyin: 'cǎi',
      'Speech Part': 'v',
      Definitions: 'step on, tread on',
      hskLevel: 5,
    },
    {
      Hanzi: '参考',
      Pinyin: 'cān kǎo',
      'Speech Part': 'v',
      Definitions: 'consult, refer to',
      hskLevel: 5,
    },
    {
      Hanzi: '参与',
      Pinyin: 'cān yù',
      'Speech Part': 'v',
      Definitions: 'take part in',
      hskLevel: 5,
    },
    {
      Hanzi: '惭愧',
      Pinyin: 'cán kuì',
      'Speech Part': 'adj',
      Definitions: 'ashamed',
      hskLevel: 5,
    },
    {
      Hanzi: '操场',
      Pinyin: 'cāo chǎng',
      'Speech Part': 'n',
      Definitions: 'playground',
      hskLevel: 5,
    },
    {
      Hanzi: '操心',
      Pinyin: 'cāo xīn',
      'Speech Part': 'v',
      Definitions: 'worry about something, be concerned with',
      hskLevel: 5,
    },
    {
      Hanzi: '册',
      Pinyin: 'cè',
      'Speech Part': 'm',
      Definitions: 'volume',
      hskLevel: 5,
    },
    {
      Hanzi: '测验',
      Pinyin: 'cè yàn',
      'Speech Part': 'v',
      Definitions: 'test, quiz',
      hskLevel: 5,
    },
    {
      Hanzi: '曾经',
      Pinyin: 'céng jīng',
      'Speech Part': 'adv',
      Definitions: 'once',
      hskLevel: 5,
    },
    {
      Hanzi: '叉子',
      Pinyin: 'chā zi',
      'Speech Part': 'n',
      Definitions: 'fork',
      hskLevel: 5,
    },
    {
      Hanzi: '差别',
      Pinyin: 'chā bié',
      'Speech Part': 'n',
      Definitions: 'difference',
      hskLevel: 5,
    },
    {
      Hanzi: '差距',
      Pinyin: 'chā jù',
      'Speech Part': 'n',
      Definitions: 'disparity, gap',
      hskLevel: 5,
    },
    {
      Hanzi: '插',
      Pinyin: 'chā',
      'Speech Part': 'v',
      Definitions: 'insert',
      hskLevel: 5,
    },
    {
      Hanzi: '拆',
      Pinyin: 'chāi',
      'Speech Part': 'v',
      Definitions: 'tear open, take apart',
      hskLevel: 5,
    },
    {
      Hanzi: '产品',
      Pinyin: 'chǎn pǐn',
      'Speech Part': 'n',
      Definitions: 'product',
      hskLevel: 5,
    },
    {
      Hanzi: '产生',
      Pinyin: 'chǎn shēng',
      'Speech Part': 'v',
      Definitions: 'bring about, produce, cause',
      hskLevel: 5,
    },
    {
      Hanzi: '长途',
      Pinyin: 'cháng tú',
      'Speech Part': 'adj',
      Definitions: 'long-distance',
      hskLevel: 5,
    },
    {
      Hanzi: '常识',
      Pinyin: 'cháng shí',
      'Speech Part': 'n',
      Definitions: 'general knowledge, common sense',
      hskLevel: 5,
    },
    {
      Hanzi: '抄',
      Pinyin: 'chāo',
      'Speech Part': 'v',
      Definitions: 'copy, transcribe',
      hskLevel: 5,
    },
    {
      Hanzi: '超级',
      Pinyin: 'chāo jí',
      'Speech Part': 'adj',
      Definitions: 'super',
      hskLevel: 5,
    },
    {
      Hanzi: '朝',
      Pinyin: 'cháo',
      'Speech Part': 'prep, v',
      Definitions: 'towards; face (to)',
      hskLevel: 5,
    },
    {
      Hanzi: '潮湿',
      Pinyin: 'cháo shī',
      'Speech Part': 'adj',
      Definitions: 'moist, damp',
      hskLevel: 5,
    },
    {
      Hanzi: '吵',
      Pinyin: 'chǎo',
      'Speech Part': 'adj, v',
      Definitions: 'noisy; quarrel',
      hskLevel: 5,
    },
    {
      Hanzi: '吵架',
      Pinyin: 'chǎo jià',
      'Speech Part': 'v',
      Definitions: 'quarrel',
      hskLevel: 5,
    },
    {
      Hanzi: '炒',
      Pinyin: 'chǎo',
      'Speech Part': 'v',
      Definitions: 'fry',
      hskLevel: 5,
    },
    {
      Hanzi: '车库',
      Pinyin: 'chē kù',
      'Speech Part': 'n',
      Definitions: 'garage',
      hskLevel: 5,
    },
    {
      Hanzi: '车厢',
      Pinyin: 'chē xiāng',
      'Speech Part': 'n',
      Definitions: 'railway carriage',
      hskLevel: 5,
    },
    {
      Hanzi: '彻底',
      Pinyin: 'chè dǐ',
      'Speech Part': 'adj',
      Definitions: 'thorough',
      hskLevel: 5,
    },
    {
      Hanzi: '沉默',
      Pinyin: 'chén mò',
      'Speech Part': 'v',
      Definitions: 'be silent',
      hskLevel: 5,
    },
    {
      Hanzi: '趁',
      Pinyin: 'chèn',
      'Speech Part': 'prep',
      Definitions: 'taking advantage of',
      hskLevel: 5,
    },
    {
      Hanzi: '称',
      Pinyin: 'chēng',
      'Speech Part': 'v',
      Definitions: 'call, weigh',
      hskLevel: 5,
    },
    {
      Hanzi: '称呼',
      Pinyin: 'chēng hu',
      'Speech Part': 'n, v',
      Definitions: 'form of address; call, address',
      hskLevel: 5,
    },
    {
      Hanzi: '称赞',
      Pinyin: 'chēng zàn',
      'Speech Part': 'v',
      Definitions: 'praise',
      hskLevel: 5,
    },
    {
      Hanzi: '成分',
      Pinyin: 'chéng fèn',
      'Speech Part': 'n',
      Definitions: 'composition, element',
      hskLevel: 5,
    },
    {
      Hanzi: '成果',
      Pinyin: 'chéng guǒ',
      'Speech Part': 'n',
      Definitions: 'achievement, positive result',
      hskLevel: 5,
    },
    {
      Hanzi: '成就',
      Pinyin: 'chéng jiù',
      'Speech Part': 'n',
      Definitions: 'achievement, accomplishment',
      hskLevel: 5,
    },
    {
      Hanzi: '成立',
      Pinyin: 'chéng lì',
      'Speech Part': 'v',
      Definitions: 'found, set up',
      hskLevel: 5,
    },
    {
      Hanzi: '成人',
      Pinyin: 'chéng rén',
      'Speech Part': 'n',
      Definitions: 'adult, grown-up',
      hskLevel: 5,
    },
    {
      Hanzi: '成熟',
      Pinyin: 'chéng shú',
      'Speech Part': 'adj, v',
      Definitions: 'mature; ripen',
      hskLevel: 5,
    },
    {
      Hanzi: '成语',
      Pinyin: 'chéng yǔ',
      'Speech Part': 'n',
      Definitions: 'set phrase (in Chinese)',
      hskLevel: 5,
    },
    {
      Hanzi: '成长',
      Pinyin: 'chéng zhǎng',
      'Speech Part': 'v',
      Definitions: 'grow up, develop',
      hskLevel: 5,
    },
    {
      Hanzi: '承担',
      Pinyin: 'chéng dān',
      'Speech Part': 'v',
      Definitions: 'bear, undertake',
      hskLevel: 5,
    },
    {
      Hanzi: '承受',
      Pinyin: 'chéng shòu',
      'Speech Part': 'v',
      Definitions: 'bear, stand',
      hskLevel: 5,
    },
    {
      Hanzi: '承认',
      Pinyin: 'chéng rèn',
      'Speech Part': 'v',
      Definitions: 'admit, acknowledge',
      hskLevel: 5,
    },
    {
      Hanzi: '诚恳',
      Pinyin: 'chéng kěn',
      'Speech Part': 'adj',
      Definitions: 'sincere',
      hskLevel: 5,
    },
    {
      Hanzi: '程度',
      Pinyin: 'chéng dù',
      'Speech Part': 'n',
      Definitions: 'degree, level',
      hskLevel: 5,
    },
    {
      Hanzi: '程序',
      Pinyin: 'chéng xù',
      'Speech Part': 'n',
      Definitions: 'procedure, computer program',
      hskLevel: 5,
    },
    {
      Hanzi: '池塘',
      Pinyin: 'chí táng',
      'Speech Part': 'n',
      Definitions: 'pond, pool',
      hskLevel: 5,
    },
    {
      Hanzi: '吃亏',
      Pinyin: 'chī kuī',
      'Speech Part': 'v',
      Definitions: 'suffer losses, be at a disadvantage',
      hskLevel: 5,
    },
    {
      Hanzi: '迟早',
      Pinyin: 'chí zǎo',
      'Speech Part': 'adv',
      Definitions: 'sooner or later',
      hskLevel: 5,
    },
    {
      Hanzi: '充电器',
      Pinyin: 'chōng diàn qì',
      'Speech Part': 'n',
      Definitions: '(battery) charger',
      hskLevel: 5,
    },
    {
      Hanzi: '尺子',
      Pinyin: 'chǐ zi',
      'Speech Part': 'n',
      Definitions: 'ruler',
      hskLevel: 5,
    },
    {
      Hanzi: '持续',
      Pinyin: 'chí xù',
      'Speech Part': 'v',
      Definitions: 'continue, sustain',
      hskLevel: 5,
    },
    {
      Hanzi: '充分',
      Pinyin: 'chōng fèn',
      'Speech Part': 'adj',
      Definitions: 'sufficient, to the full, as full as possible',
      hskLevel: 5,
    },
    {
      Hanzi: '翅膀',
      Pinyin: 'chì bǎng',
      'Speech Part': 'n',
      Definitions: 'wing',
      hskLevel: 5,
    },
    {
      Hanzi: '冲',
      Pinyin: 'chōng',
      'Speech Part': 'v',
      Definitions: 'rush, flush',
      hskLevel: 5,
    },
    {
      Hanzi: '重复',
      Pinyin: 'chóng fù',
      'Speech Part': 'v',
      Definitions: 'repeat, duplicate',
      hskLevel: 5,
    },
    {
      Hanzi: '充满',
      Pinyin: 'chōng mǎn',
      'Speech Part': 'v',
      Definitions: 'be full of, be filled with',
      hskLevel: 5,
    },
    {
      Hanzi: '抽屉',
      Pinyin: 'chōu ti',
      'Speech Part': 'n',
      Definitions: 'drawer',
      hskLevel: 5,
    },
    {
      Hanzi: '宠物',
      Pinyin: 'chǒng wù',
      'Speech Part': 'n',
      Definitions: 'pet',
      hskLevel: 5,
    },
    {
      Hanzi: '抽象',
      Pinyin: 'chōu xiàng',
      'Speech Part': 'adj',
      Definitions: 'abstract',
      hskLevel: 5,
    },
    {
      Hanzi: '出口',
      Pinyin: 'chū kǒu',
      'Speech Part': 'n, v',
      Definitions: 'exit; export',
      hskLevel: 5,
    },
    {
      Hanzi: '出版',
      Pinyin: 'chū bǎn',
      'Speech Part': 'v',
      Definitions: 'publish',
      hskLevel: 5,
    },
    {
      Hanzi: '丑',
      Pinyin: 'chǒu',
      'Speech Part': 'adj',
      Definitions: 'ugly, shameful',
      hskLevel: 5,
    },
    {
      Hanzi: '出示',
      Pinyin: 'chū shì',
      'Speech Part': 'v',
      Definitions: 'show, produce',
      hskLevel: 5,
    },
    {
      Hanzi: '臭',
      Pinyin: 'chòu',
      'Speech Part': 'adj',
      Definitions: 'smelly, stinking, odious',
      hskLevel: 5,
    },
    {
      Hanzi: '出色',
      Pinyin: 'chū sè',
      'Speech Part': 'adj',
      Definitions: 'outstanding, excellent',
      hskLevel: 5,
    },
    {
      Hanzi: '初级',
      Pinyin: 'chū jí',
      'Speech Part': 'adj',
      Definitions: 'elementary, primary',
      hskLevel: 5,
    },
    {
      Hanzi: '出席',
      Pinyin: 'chū xí',
      'Speech Part': 'v',
      Definitions: 'attend, be present',
      hskLevel: 5,
    },
    {
      Hanzi: '除非',
      Pinyin: 'chú fēi',
      'Speech Part': 'conj',
      Definitions: 'only if, only when',
      hskLevel: 5,
    },
    {
      Hanzi: '除夕',
      Pinyin: 'chú xī',
      'Speech Part': 'n',
      Definitions: "New Year's Eve",
      hskLevel: 5,
    },
    {
      Hanzi: '处理',
      Pinyin: 'chǔ lǐ',
      'Speech Part': 'v',
      Definitions: 'deal with, handle',
      hskLevel: 5,
    },
    {
      Hanzi: '传播',
      Pinyin: 'chuán bō',
      'Speech Part': 'v',
      Definitions: 'spread, disseminate',
      hskLevel: 5,
    },
    {
      Hanzi: '传染',
      Pinyin: 'chuán rǎn',
      'Speech Part': 'v',
      Definitions: 'infect',
      hskLevel: 5,
    },
    {
      Hanzi: '传说',
      Pinyin: 'chuán shuō',
      'Speech Part': 'n',
      Definitions: 'legend',
      hskLevel: 5,
    },
    {
      Hanzi: '传统',
      Pinyin: 'chuán tǒng',
      'Speech Part': 'adj, n',
      Definitions: 'traditional; tradition',
      hskLevel: 5,
    },
    {
      Hanzi: '窗帘',
      Pinyin: 'chuāng lián',
      'Speech Part': 'n',
      Definitions: 'curtain',
      hskLevel: 5,
    },
    {
      Hanzi: '闯',
      Pinyin: 'chuǎng',
      'Speech Part': 'v',
      Definitions: 'rush, temper onself',
      hskLevel: 5,
    },
    {
      Hanzi: '创造',
      Pinyin: 'chuàng zào',
      'Speech Part': 'v',
      Definitions: 'create',
      hskLevel: 5,
    },
    {
      Hanzi: '吹',
      Pinyin: 'chuī',
      'Speech Part': 'v',
      Definitions: 'blow, play (wind instruments)',
      hskLevel: 5,
    },
    {
      Hanzi: '词汇',
      Pinyin: 'cí huì',
      'Speech Part': 'n',
      Definitions: 'vocabulary',
      hskLevel: 5,
    },
    {
      Hanzi: '辞职',
      Pinyin: 'cí zhí',
      'Speech Part': 'v',
      Definitions: 'resign, quit',
      hskLevel: 5,
    },
    {
      Hanzi: '此外',
      Pinyin: 'cǐ wài',
      'Speech Part': 'conj',
      Definitions: 'besides, in addition',
      hskLevel: 5,
    },
    {
      Hanzi: '次要',
      Pinyin: 'cì yào',
      'Speech Part': 'adj',
      Definitions: 'less important, secondary',
      hskLevel: 5,
    },
    {
      Hanzi: '刺激',
      Pinyin: 'cì jī',
      'Speech Part': 'v',
      Definitions: 'stimulate, upset',
      hskLevel: 5,
    },
    {
      Hanzi: '匆忙',
      Pinyin: 'cōng máng',
      'Speech Part': 'adj',
      Definitions: 'hurried, hasty',
      hskLevel: 5,
    },
    {
      Hanzi: '从此',
      Pinyin: 'cóng cǐ',
      'Speech Part': 'adv',
      Definitions: 'from this time on',
      hskLevel: 5,
    },
    {
      Hanzi: '从而',
      Pinyin: 'cóng ér',
      'Speech Part': 'conj',
      Definitions: 'thus, thereby',
      hskLevel: 5,
    },
    {
      Hanzi: '从前',
      Pinyin: 'cóng qián',
      'Speech Part': 'n',
      Definitions: 'former times, the past',
      hskLevel: 5,
    },
    {
      Hanzi: '从事',
      Pinyin: 'cóng shì',
      'Speech Part': 'v',
      Definitions: 'undertake, be engaged in',
      hskLevel: 5,
    },
    {
      Hanzi: '粗糙',
      Pinyin: 'cū cāo',
      'Speech Part': 'adj',
      Definitions: 'rough, ragged, unpolished',
      hskLevel: 5,
    },
    {
      Hanzi: '促进',
      Pinyin: 'cù jìn',
      'Speech Part': 'v',
      Definitions: 'promote',
      hskLevel: 5,
    },
    {
      Hanzi: '促使',
      Pinyin: 'cù shǐ',
      'Speech Part': 'v',
      Definitions: 'impel, urge',
      hskLevel: 5,
    },
    {
      Hanzi: '醋',
      Pinyin: 'cù',
      'Speech Part': 'n',
      Definitions: 'vinegar',
      hskLevel: 5,
    },
    {
      Hanzi: '催',
      Pinyin: 'cuī',
      'Speech Part': 'v',
      Definitions: 'hurry, urge',
      hskLevel: 5,
    },
    {
      Hanzi: '存在',
      Pinyin: 'cún zài',
      'Speech Part': 'v',
      Definitions: 'exist',
      hskLevel: 5,
    },
    {
      Hanzi: '措施',
      Pinyin: 'cuò shī',
      'Speech Part': 'n',
      Definitions: 'measure',
      hskLevel: 5,
    },
    {
      Hanzi: '答应',
      Pinyin: 'dā ying',
      'Speech Part': 'v',
      Definitions: 'answer, agree',
      hskLevel: 5,
    },
    {
      Hanzi: '达到',
      Pinyin: 'dá dào',
      'Speech Part': 'v',
      Definitions: 'achieve, reach',
      hskLevel: 5,
    },
    {
      Hanzi: '打工',
      Pinyin: 'dǎ gōng',
      'Speech Part': 'v',
      Definitions: 'do a temporary job',
      hskLevel: 5,
    },
    {
      Hanzi: '打交道',
      Pinyin: 'dǎ jiāo dao',
      'Speech Part': '-',
      Definitions: 'have dealings with',
      hskLevel: 5,
    },
    {
      Hanzi: '大方',
      Pinyin: 'dà fang',
      'Speech Part': 'adj',
      Definitions: 'natural and poised, generous',
      hskLevel: 5,
    },
    {
      Hanzi: '大厦',
      Pinyin: 'dà shà',
      'Speech Part': 'n',
      Definitions: 'mansion, large building',
      hskLevel: 5,
    },
    {
      Hanzi: '打喷嚏',
      Pinyin: 'dǎ pēn tì',
      'Speech Part': '-',
      Definitions: 'sneeze',
      hskLevel: 5,
    },
    {
      Hanzi: '打听',
      Pinyin: 'dǎ ting',
      'Speech Part': 'v',
      Definitions: 'enquire about, ask about',
      hskLevel: 5,
    },
    {
      Hanzi: '大型',
      Pinyin: 'dà xíng',
      'Speech Part': 'adj',
      Definitions: 'large-scale, large-sized',
      hskLevel: 5,
    },
    {
      Hanzi: '呆',
      Pinyin: 'dāi',
      'Speech Part': 'adj',
      Definitions: 'dull, blank',
      hskLevel: 5,
    },
    {
      Hanzi: '代表',
      Pinyin: 'dài biǎo',
      'Speech Part': 'n, v',
      Definitions: 'representative; represent',
      hskLevel: 5,
    },
    {
      Hanzi: '代替',
      Pinyin: 'dài tì',
      'Speech Part': 'v',
      Definitions: 'substitute for, replace',
      hskLevel: 5,
    },
    {
      Hanzi: '待遇',
      Pinyin: 'dài yù',
      'Speech Part': 'n',
      Definitions: 'treatment, remuneration',
      hskLevel: 5,
    },
    {
      Hanzi: '贷款',
      Pinyin: 'dài kuǎn',
      'Speech Part': 'n, v',
      Definitions: 'loan; get a loan',
      hskLevel: 5,
    },
    {
      Hanzi: '大象',
      Pinyin: 'dà xiàng',
      'Speech Part': 'n',
      Definitions: 'elephant',
      hskLevel: 5,
    },
    {
      Hanzi: '担任',
      Pinyin: 'dān rèn',
      'Speech Part': 'v',
      Definitions: 'hold (a post)',
      hskLevel: 5,
    },
    {
      Hanzi: '单纯',
      Pinyin: 'dān chún',
      'Speech Part': 'adj',
      Definitions: 'simple, pure',
      hskLevel: 5,
    },
    {
      Hanzi: '单调',
      Pinyin: 'dān diào',
      'Speech Part': 'adj',
      Definitions: 'monotonous, dull',
      hskLevel: 5,
    },
    {
      Hanzi: '单独',
      Pinyin: 'dān dú',
      'Speech Part': 'adv',
      Definitions: "alone, on one's own",
      hskLevel: 5,
    },
    {
      Hanzi: '单元',
      Pinyin: 'dān yuán',
      'Speech Part': 'n',
      Definitions: 'unit',
      hskLevel: 5,
    },
    {
      Hanzi: '单位',
      Pinyin: 'dān wèi',
      'Speech Part': 'n',
      Definitions:
        'unit (for standard of measurement or a group of people as a whole)',
      hskLevel: 5,
    },
    {
      Hanzi: '耽误',
      Pinyin: 'dān wu',
      'Speech Part': 'v',
      Definitions: 'delay, hold up',
      hskLevel: 5,
    },
    {
      Hanzi: '胆小鬼',
      Pinyin: 'dǎn xiǎo guǐ',
      'Speech Part': 'n',
      Definitions: 'coward',
      hskLevel: 5,
    },
    {
      Hanzi: '淡',
      Pinyin: 'dàn',
      'Speech Part': 'adj',
      Definitions: 'bland (for food/drinks), light (for colour)',
      hskLevel: 5,
    },
    {
      Hanzi: '当地',
      Pinyin: 'dāng dì',
      'Speech Part': 'n',
      Definitions: 'locality, place mentioned',
      hskLevel: 5,
    },
    {
      Hanzi: '当心',
      Pinyin: 'dāng xīn',
      'Speech Part': 'v',
      Definitions: 'take care, look out',
      hskLevel: 5,
    },
    {
      Hanzi: '挡',
      Pinyin: 'dǎng',
      'Speech Part': 'v',
      Definitions: 'block, keep off',
      hskLevel: 5,
    },
    {
      Hanzi: '导演',
      Pinyin: 'dǎo yǎn',
      'Speech Part': 'n, v',
      Definitions: 'director; direct',
      hskLevel: 5,
    },
    {
      Hanzi: '导致',
      Pinyin: 'dǎo zhì',
      'Speech Part': 'v',
      Definitions: 'lead to, bring about',
      hskLevel: 5,
    },
    {
      Hanzi: '岛屿',
      Pinyin: 'dǎo yǔ',
      'Speech Part': 'n',
      Definitions: 'islands',
      hskLevel: 5,
    },
    {
      Hanzi: '到达',
      Pinyin: 'dào dá',
      'Speech Part': 'v',
      Definitions: 'arrive, reach',
      hskLevel: 5,
    },
    {
      Hanzi: '倒霉',
      Pinyin: 'dǎo méi',
      'Speech Part': 'adj',
      Definitions: 'unlucky',
      hskLevel: 5,
    },
    {
      Hanzi: '道德',
      Pinyin: 'dào dé',
      'Speech Part': 'n',
      Definitions: 'morality, morals',
      hskLevel: 5,
    },
    {
      Hanzi: '登记',
      Pinyin: 'dēng jì',
      'Speech Part': 'v',
      Definitions: 'register, check in',
      hskLevel: 5,
    },
    {
      Hanzi: '等待',
      Pinyin: 'děng dài',
      'Speech Part': 'v',
      Definitions: 'wait for, await',
      hskLevel: 5,
    },
    {
      Hanzi: '滴',
      Pinyin: 'dī',
      'Speech Part': 'm, v',
      Definitions: 'drop; drip',
      hskLevel: 5,
    },
    {
      Hanzi: '地道',
      Pinyin: 'dì dao',
      'Speech Part': 'adj',
      Definitions: 'genuine, typical',
      hskLevel: 5,
    },
    {
      Hanzi: '的确',
      Pinyin: 'dí què',
      'Speech Part': 'adv',
      Definitions: 'indeed, really',
      hskLevel: 5,
    },
    {
      Hanzi: '道理',
      Pinyin: 'dào lǐ',
      'Speech Part': 'n',
      Definitions: 'reason, principle',
      hskLevel: 5,
    },
    {
      Hanzi: '等于',
      Pinyin: 'děng yú',
      'Speech Part': 'v',
      Definitions: 'be equal to, amount to',
      hskLevel: 5,
    },
    {
      Hanzi: '地区',
      Pinyin: 'dì qū',
      'Speech Part': 'n',
      Definitions: 'area, district',
      hskLevel: 5,
    },
    {
      Hanzi: '敌人',
      Pinyin: 'dí rén',
      'Speech Part': 'n',
      Definitions: 'enemy',
      hskLevel: 5,
    },
    {
      Hanzi: '地理',
      Pinyin: 'dì lǐ',
      'Speech Part': 'n',
      Definitions: 'geography',
      hskLevel: 5,
    },
    {
      Hanzi: '地毯',
      Pinyin: 'dì tǎn',
      'Speech Part': 'n',
      Definitions: 'carpet',
      hskLevel: 5,
    },
    {
      Hanzi: '地位',
      Pinyin: 'dì wèi',
      'Speech Part': 'n',
      Definitions: 'position, ranking',
      hskLevel: 5,
    },
    {
      Hanzi: '地震',
      Pinyin: 'dì zhèn',
      'Speech Part': 'v',
      Definitions: 'earthquake',
      hskLevel: 5,
    },
    {
      Hanzi: '递',
      Pinyin: 'dì',
      'Speech Part': 'v',
      Definitions: 'pass, hand over',
      hskLevel: 5,
    },
    {
      Hanzi: '点心',
      Pinyin: 'diǎn xin',
      'Speech Part': 'n',
      Definitions: 'dessert',
      hskLevel: 5,
    },
    {
      Hanzi: '电池',
      Pinyin: 'diàn chí',
      'Speech Part': 'n',
      Definitions: 'battery, cell',
      hskLevel: 5,
    },
    {
      Hanzi: '电台',
      Pinyin: 'diàn tái',
      'Speech Part': 'n',
      Definitions: 'radio station, transmitter-receiver',
      hskLevel: 5,
    },
    {
      Hanzi: '钓',
      Pinyin: 'diào',
      'Speech Part': 'v',
      Definitions: 'angle, fish',
      hskLevel: 5,
    },
    {
      Hanzi: '顶',
      Pinyin: 'dǐng',
      'Speech Part': 'n, m',
      Definitions: 'top, crown; (used for something that has a top)',
      hskLevel: 5,
    },
    {
      Hanzi: '动画片',
      Pinyin: 'dòng huà piàn',
      'Speech Part': 'n',
      Definitions: 'cartoon',
      hskLevel: 5,
    },
    {
      Hanzi: '豆腐',
      Pinyin: 'dòu fu',
      'Speech Part': 'n',
      Definitions: 'tofu, bean curd',
      hskLevel: 5,
    },
    {
      Hanzi: '洞',
      Pinyin: 'dòng',
      'Speech Part': 'n',
      Definitions: 'cavity, hole',
      hskLevel: 5,
    },
    {
      Hanzi: '逗',
      Pinyin: 'dòu',
      'Speech Part': 'adj, v',
      Definitions: 'funny; tease',
      hskLevel: 5,
    },
    {
      Hanzi: '冻',
      Pinyin: 'dòng',
      'Speech Part': 'v',
      Definitions: 'freeze',
      hskLevel: 5,
    },
    {
      Hanzi: '独立',
      Pinyin: 'dú lì',
      'Speech Part': 'adj, v',
      Definitions: 'independent; become independent',
      hskLevel: 5,
    },
    {
      Hanzi: '独特',
      Pinyin: 'dú tè',
      'Speech Part': 'adj',
      Definitions: 'unique, distinctive',
      hskLevel: 5,
    },
    {
      Hanzi: '度过',
      Pinyin: 'dù guò',
      'Speech Part': 'v',
      Definitions: 'spend',
      hskLevel: 5,
    },
    {
      Hanzi: '断',
      Pinyin: 'duàn',
      'Speech Part': 'v',
      Definitions: 'break, cut off',
      hskLevel: 5,
    },
    {
      Hanzi: '对待',
      Pinyin: 'duì dài',
      'Speech Part': 'v',
      Definitions: 'treat',
      hskLevel: 5,
    },
    {
      Hanzi: '堆',
      Pinyin: 'duī',
      'Speech Part': 'm, v',
      Definitions: 'stack; pile (up)',
      hskLevel: 5,
    },
    {
      Hanzi: '对比',
      Pinyin: 'duì bǐ',
      'Speech Part': 'v',
      Definitions: 'contrast, compare',
      hskLevel: 5,
    },
    {
      Hanzi: '对方',
      Pinyin: 'duì fāng',
      'Speech Part': 'n',
      Definitions: 'other side, other party',
      hskLevel: 5,
    },
    {
      Hanzi: '对象',
      Pinyin: 'duì xiàng',
      'Speech Part': 'n',
      Definitions: 'object, target',
      hskLevel: 5,
    },
    {
      Hanzi: '对手',
      Pinyin: 'duì shǒu',
      'Speech Part': 'n',
      Definitions: 'competitor, rival',
      hskLevel: 5,
    },
    {
      Hanzi: '兑换',
      Pinyin: 'duì huàn',
      'Speech Part': 'v',
      Definitions: 'convert, exchange',
      hskLevel: 5,
    },
    {
      Hanzi: '吨',
      Pinyin: 'dūn',
      'Speech Part': 'm',
      Definitions: 'ton',
      hskLevel: 5,
    },
    {
      Hanzi: '顿',
      Pinyin: 'dùn',
      'Speech Part': 'm',
      Definitions: 'time',
      hskLevel: 5,
    },
    {
      Hanzi: '蹲',
      Pinyin: 'dūn',
      'Speech Part': 'v',
      Definitions: 'squat, crounch',
      hskLevel: 5,
    },
    {
      Hanzi: '多余',
      Pinyin: 'duō yú',
      'Speech Part': 'adj',
      Definitions: 'surplus, redundant',
      hskLevel: 5,
    },
    {
      Hanzi: '朵',
      Pinyin: 'duǒ',
      'Speech Part': 'm',
      Definitions: '(of flowers/clouds)',
      hskLevel: 5,
    },
    {
      Hanzi: '多亏',
      Pinyin: 'duō kuī',
      'Speech Part': 'v',
      Definitions: 'owe something to somebody',
      hskLevel: 5,
    },
    {
      Hanzi: '躲藏',
      Pinyin: 'duǒ cáng',
      'Speech Part': 'v',
      Definitions: 'hide',
      hskLevel: 5,
    },
    {
      Hanzi: '恶劣',
      Pinyin: 'è liè',
      'Speech Part': 'adj',
      Definitions: 'very bad, disgusting',
      hskLevel: 5,
    },
    {
      Hanzi: '耳环',
      Pinyin: 'ěr huán',
      'Speech Part': 'n',
      Definitions: 'earring, earbob',
      hskLevel: 5,
    },
    {
      Hanzi: '发愁',
      Pinyin: 'fā chóu',
      'Speech Part': 'v',
      Definitions: 'worry (about)',
      hskLevel: 5,
    },
    {
      Hanzi: '发表',
      Pinyin: 'fā biǎo',
      'Speech Part': 'v',
      Definitions: 'issue, publish',
      hskLevel: 5,
    },
    {
      Hanzi: '发抖',
      Pinyin: 'fā dǒu',
      'Speech Part': 'v',
      Definitions: 'quake, shake',
      hskLevel: 5,
    },
    {
      Hanzi: '发达',
      Pinyin: 'fā dá',
      'Speech Part': 'adj',
      Definitions: 'developed',
      hskLevel: 5,
    },
    {
      Hanzi: '发挥',
      Pinyin: 'fā huī',
      'Speech Part': 'v',
      Definitions: 'bring into play, give (full) play to',
      hskLevel: 5,
    },
    {
      Hanzi: '发明',
      Pinyin: 'fā míng',
      'Speech Part': 'n, v',
      Definitions: 'invention; invent',
      hskLevel: 5,
    },
    {
      Hanzi: '发票',
      Pinyin: 'fā piào',
      'Speech Part': 'n',
      Definitions: 'invoice',
      hskLevel: 5,
    },
    {
      Hanzi: '发言',
      Pinyin: 'fā yán',
      'Speech Part': 'n, v',
      Definitions: 'speech; speak',
      hskLevel: 5,
    },
    {
      Hanzi: '罚款',
      Pinyin: 'fá kuǎn',
      'Speech Part': 'n, v',
      Definitions: 'fine, penalty; impose a fine',
      hskLevel: 5,
    },
    {
      Hanzi: '法院',
      Pinyin: 'fǎ yuàn',
      'Speech Part': 'n',
      Definitions: 'court',
      hskLevel: 5,
    },
    {
      Hanzi: '翻',
      Pinyin: 'fān',
      'Speech Part': 'v',
      Definitions: 'turn over, climb over',
      hskLevel: 5,
    },
    {
      Hanzi: '繁荣',
      Pinyin: 'fán róng',
      'Speech Part': 'adj',
      Definitions: 'flourishing, prosperous',
      hskLevel: 5,
    },
    {
      Hanzi: '反而',
      Pinyin: 'fǎn ér',
      'Speech Part': 'adv',
      Definitions: 'on the contrary, instead',
      hskLevel: 5,
    },
    {
      Hanzi: '反应',
      Pinyin: 'fǎn yìng',
      'Speech Part': 'n, v',
      Definitions: 'reaction; respond, react',
      hskLevel: 5,
    },
    {
      Hanzi: '反复',
      Pinyin: 'fǎn fù',
      'Speech Part': 'adv, v',
      Definitions: 'repeatedly; vacilate',
      hskLevel: 5,
    },
    {
      Hanzi: '反映',
      Pinyin: 'fǎn yìng',
      'Speech Part': 'v',
      Definitions: 'reflect, make known',
      hskLevel: 5,
    },
    {
      Hanzi: '反正',
      Pinyin: 'fǎn zhèng',
      'Speech Part': 'adv',
      Definitions: 'all the same, anyway',
      hskLevel: 5,
    },
    {
      Hanzi: '范围',
      Pinyin: 'fàn wéi',
      'Speech Part': 'n',
      Definitions: 'scope, range, domain',
      hskLevel: 5,
    },
    {
      Hanzi: '方',
      Pinyin: 'fāng',
      'Speech Part': 'adj',
      Definitions: 'direction, side',
      hskLevel: 5,
    },
    {
      Hanzi: '方案',
      Pinyin: 'fāng àn',
      'Speech Part': 'n',
      Definitions: 'plan, programme',
      hskLevel: 5,
    },
    {
      Hanzi: '非',
      Pinyin: 'fēi',
      'Speech Part': 'adj, v, adv',
      Definitions: 'wrong; be not; have got to, simply must',
      hskLevel: 5,
    },
    {
      Hanzi: '妨碍',
      Pinyin: 'fáng ài',
      'Speech Part': 'v',
      Definitions: 'hinder, obstruct',
      hskLevel: 5,
    },
    {
      Hanzi: '肥皂',
      Pinyin: 'féi zào',
      'Speech Part': 'n',
      Definitions: 'soap',
      hskLevel: 5,
    },
    {
      Hanzi: '仿佛',
      Pinyin: 'fǎng fú',
      'Speech Part': 'adv',
      Definitions: 'as if, as though',
      hskLevel: 5,
    },
    {
      Hanzi: '废话',
      Pinyin: 'fèi huà',
      'Speech Part': 'n, v',
      Definitions: 'nonsense; talk nonsense',
      hskLevel: 5,
    },
    {
      Hanzi: '分别',
      Pinyin: 'fēn bié',
      'Speech Part': 'adv, v',
      Definitions: 'separately, respectively; part',
      hskLevel: 5,
    },
    {
      Hanzi: '分布',
      Pinyin: 'fēn bù',
      'Speech Part': 'v',
      Definitions: 'be distributed',
      hskLevel: 5,
    },
    {
      Hanzi: '分配',
      Pinyin: 'fēn pèi',
      'Speech Part': 'v',
      Definitions: 'distribute, portion out',
      hskLevel: 5,
    },
    {
      Hanzi: '分手',
      Pinyin: 'fēn shǒu',
      'Speech Part': 'v',
      Definitions: 'split up, part',
      hskLevel: 5,
    },
    {
      Hanzi: '分析',
      Pinyin: 'fēn xī',
      'Speech Part': 'v',
      Definitions: 'analyse',
      hskLevel: 5,
    },
    {
      Hanzi: '纷纷',
      Pinyin: 'fēn fēn',
      'Speech Part': 'adv',
      Definitions: 'one after another, in succession',
      hskLevel: 5,
    },
    {
      Hanzi: '奋斗',
      Pinyin: 'fèn dòu',
      'Speech Part': 'v',
      Definitions: 'struggle, strive',
      hskLevel: 5,
    },
    {
      Hanzi: '风格',
      Pinyin: 'fēng gé',
      'Speech Part': 'n',
      Definitions: 'style',
      hskLevel: 5,
    },
    {
      Hanzi: '风景',
      Pinyin: 'fēng jǐng',
      'Speech Part': 'n',
      Definitions: 'view',
      hskLevel: 5,
    },
    {
      Hanzi: '风险',
      Pinyin: 'fēng xiǎn',
      'Speech Part': 'n',
      Definitions: 'risk',
      hskLevel: 5,
    },
    {
      Hanzi: '讽刺',
      Pinyin: 'fěng cì',
      'Speech Part': 'v',
      Definitions: 'satirise',
      hskLevel: 5,
    },
    {
      Hanzi: '风俗',
      Pinyin: 'fēng sú',
      'Speech Part': 'n',
      Definitions: 'custom',
      hskLevel: 5,
    },
    {
      Hanzi: '否定',
      Pinyin: 'fǒu dìng',
      'Speech Part': 'adj, v',
      Definitions: 'negative; deny',
      hskLevel: 5,
    },
    {
      Hanzi: '否认',
      Pinyin: 'fǒu rèn',
      'Speech Part': 'v',
      Definitions: 'deny',
      hskLevel: 5,
    },
    {
      Hanzi: '疯狂',
      Pinyin: 'fēng kuáng',
      'Speech Part': 'adj',
      Definitions: 'frenzied, crazy',
      hskLevel: 5,
    },
    {
      Hanzi: '扶',
      Pinyin: 'fú',
      'Speech Part': 'v',
      Definitions:
        'place a hand on something for support, support (with the hand)',
      hskLevel: 5,
    },
    {
      Hanzi: '幅',
      Pinyin: 'fú',
      'Speech Part': 'm',
      Definitions: '(for paintings/cloth)',
      hskLevel: 5,
    },
    {
      Hanzi: '服装',
      Pinyin: 'fú zhuāng',
      'Speech Part': 'n',
      Definitions: 'clothing, dress',
      hskLevel: 5,
    },
    {
      Hanzi: '辅导',
      Pinyin: 'fǔ dǎo',
      'Speech Part': 'v',
      Definitions: 'coach, tutor',
      hskLevel: 5,
    },
    {
      Hanzi: '妇女',
      Pinyin: 'fù nǚ',
      'Speech Part': 'n',
      Definitions: 'woman',
      hskLevel: 5,
    },
    {
      Hanzi: '复制',
      Pinyin: 'fù zhì',
      'Speech Part': 'v',
      Definitions: 'copy, duplicate',
      hskLevel: 5,
    },
    {
      Hanzi: '改革',
      Pinyin: 'gǎi gé',
      'Speech Part': 'n, v',
      Definitions: 'reform',
      hskLevel: 5,
    },
    {
      Hanzi: '改进',
      Pinyin: 'gǎi jìn',
      'Speech Part': 'v',
      Definitions: 'improve',
      hskLevel: 5,
    },
    {
      Hanzi: '改善',
      Pinyin: 'gǎi shàn',
      'Speech Part': 'v',
      Definitions: 'improve, upgrade',
      hskLevel: 5,
    },
    {
      Hanzi: '盖',
      Pinyin: 'gài',
      'Speech Part': 'n, v',
      Definitions: 'cover; cover (up)',
      hskLevel: 5,
    },
    {
      Hanzi: '概括',
      Pinyin: 'gài kuò',
      'Speech Part': 'v',
      Definitions: 'summarise',
      hskLevel: 5,
    },
    {
      Hanzi: '改正',
      Pinyin: 'gǎi zhèng',
      'Speech Part': 'v',
      Definitions: 'correct, amend',
      hskLevel: 5,
    },
    {
      Hanzi: '概念',
      Pinyin: 'gài niàn',
      'Speech Part': 'n',
      Definitions: 'concept',
      hskLevel: 5,
    },
    {
      Hanzi: '干脆',
      Pinyin: 'gān cuì',
      'Speech Part': 'adj, adv',
      Definitions: 'straightforward; directly',
      hskLevel: 5,
    },
    {
      Hanzi: '干燥',
      Pinyin: 'gān zào',
      'Speech Part': 'adj',
      Definitions: 'dry',
      hskLevel: 5,
    },
    {
      Hanzi: '赶紧',
      Pinyin: 'gǎn jǐn',
      'Speech Part': 'adv',
      Definitions: 'hastily, quickly',
      hskLevel: 5,
    },
    {
      Hanzi: '赶快',
      Pinyin: 'gǎn kuài',
      'Speech Part': 'adv',
      Definitions: 'quickly',
      hskLevel: 5,
    },
    {
      Hanzi: '感激',
      Pinyin: 'gǎn jī',
      'Speech Part': 'v',
      Definitions: 'be grateful (to), be thankful (to)',
      hskLevel: 5,
    },
    {
      Hanzi: '感受',
      Pinyin: 'gǎn shòu',
      'Speech Part': 'n, v',
      Definitions: 'feeling; feel',
      hskLevel: 5,
    },
    {
      Hanzi: '干活儿',
      Pinyin: 'gàn huó r',
      'Speech Part': 'v',
      Definitions: 'work',
      hskLevel: 5,
    },
    {
      Hanzi: '高级',
      Pinyin: 'gāo jí',
      'Speech Part': 'adj',
      Definitions: 'advanced, high-grade, senior',
      hskLevel: 5,
    },
    {
      Hanzi: '钢铁',
      Pinyin: 'gāng tiě',
      'Speech Part': 'n',
      Definitions: 'iron and steel',
      hskLevel: 5,
    },
    {
      Hanzi: '高档',
      Pinyin: 'gāo dàng',
      'Speech Part': 'adj',
      Definitions: 'top-grade',
      hskLevel: 5,
    },
    {
      Hanzi: '搞',
      Pinyin: 'gǎo',
      'Speech Part': 'v',
      Definitions: 'do, make',
      hskLevel: 5,
    },
    {
      Hanzi: '感想',
      Pinyin: 'gǎn xiǎng',
      'Speech Part': 'n',
      Definitions: 'reflections, thoughts',
      hskLevel: 5,
    },
    {
      Hanzi: '告别',
      Pinyin: 'gào bié',
      'Speech Part': 'v',
      Definitions: 'say goodbye to',
      hskLevel: 5,
    },
    {
      Hanzi: '格外',
      Pinyin: 'gé wài',
      'Speech Part': 'adv',
      Definitions: 'extraodinarily, especially',
      hskLevel: 5,
    },
    {
      Hanzi: '个人',
      Pinyin: 'gè rén',
      'Speech Part': 'n, pron',
      Definitions: 'individual; (used on formal occasions to refer to oneself)',
      hskLevel: 5,
    },
    {
      Hanzi: '隔壁',
      Pinyin: 'gé bì',
      'Speech Part': 'n',
      Definitions: 'next-door apartment',
      hskLevel: 5,
    },
    {
      Hanzi: '个别',
      Pinyin: 'gè bié',
      'Speech Part': 'adv',
      Definitions: 'particularly',
      hskLevel: 5,
    },
    {
      Hanzi: '各自',
      Pinyin: 'gè zì',
      'Speech Part': 'pron',
      Definitions: 'each (of a group), oneself',
      hskLevel: 5,
    },
    {
      Hanzi: '个性',
      Pinyin: 'gè xìng',
      'Speech Part': 'n',
      Definitions: 'individuality, personality',
      hskLevel: 5,
    },
    {
      Hanzi: '根',
      Pinyin: 'gēn',
      'Speech Part': 'm, n',
      Definitions: '(for long/thin objects); root',
      hskLevel: 5,
    },
    {
      Hanzi: '根本',
      Pinyin: 'gēn běn',
      'Speech Part': 'adj, adv',
      Definitions: 'basic; at all, totally',
      hskLevel: 5,
    },
    {
      Hanzi: '工厂',
      Pinyin: 'gōng chǎng',
      'Speech Part': 'n',
      Definitions: 'factory',
      hskLevel: 5,
    },
    {
      Hanzi: '工程师',
      Pinyin: 'gōng chéng shī',
      'Speech Part': 'n',
      Definitions: 'engineer',
      hskLevel: 5,
    },
    {
      Hanzi: '工具',
      Pinyin: 'gōng jù',
      'Speech Part': 'n',
      Definitions: 'tool',
      hskLevel: 5,
    },
    {
      Hanzi: '工业',
      Pinyin: 'gōng yè',
      'Speech Part': 'n',
      Definitions: 'industry',
      hskLevel: 5,
    },
    {
      Hanzi: '工人',
      Pinyin: 'gōng rén',
      'Speech Part': 'n',
      Definitions: 'worker',
      hskLevel: 5,
    },
    {
      Hanzi: '公布',
      Pinyin: 'gōng bù',
      'Speech Part': 'v',
      Definitions: 'announce, publish',
      hskLevel: 5,
    },
    {
      Hanzi: '公开',
      Pinyin: 'gōng kāi',
      'Speech Part': 'adj, v',
      Definitions: 'open; make public',
      hskLevel: 5,
    },
    {
      Hanzi: '公平',
      Pinyin: 'gōng píng',
      'Speech Part': 'adj',
      Definitions: 'fair, impartial',
      hskLevel: 5,
    },
    {
      Hanzi: '公寓',
      Pinyin: 'gōng yù',
      'Speech Part': 'n',
      Definitions: 'flat, apartment',
      hskLevel: 5,
    },
    {
      Hanzi: '公元',
      Pinyin: 'gōng yuán',
      'Speech Part': 'n',
      Definitions: 'Christian era',
      hskLevel: 5,
    },
    {
      Hanzi: '公主',
      Pinyin: 'gōng zhǔ',
      'Speech Part': 'n',
      Definitions: 'princess',
      hskLevel: 5,
    },
    {
      Hanzi: '功能',
      Pinyin: 'gōng néng',
      'Speech Part': 'n',
      Definitions: 'function',
      hskLevel: 5,
    },
    {
      Hanzi: '恭喜',
      Pinyin: 'gōng xǐ',
      'Speech Part': 'v',
      Definitions: 'congratulate',
      hskLevel: 5,
    },
    {
      Hanzi: '贡献',
      Pinyin: 'gòng xiàn',
      'Speech Part': 'n, v',
      Definitions: 'contribution; contribute',
      hskLevel: 5,
    },
    {
      Hanzi: '姑姑',
      Pinyin: 'gū gu',
      'Speech Part': 'n',
      Definitions: "father's sister, aunt",
      hskLevel: 5,
    },
    {
      Hanzi: '姑娘',
      Pinyin: 'gū niang',
      'Speech Part': 'n',
      Definitions: 'girl, (informal) daughter',
      hskLevel: 5,
    },
    {
      Hanzi: '古代',
      Pinyin: 'gǔ dài',
      'Speech Part': 'n',
      Definitions: 'ancient times',
      hskLevel: 5,
    },
    {
      Hanzi: '构成',
      Pinyin: 'gòu chéng',
      'Speech Part': 'v',
      Definitions: 'constitute, form',
      hskLevel: 5,
    },
    {
      Hanzi: '沟通',
      Pinyin: 'gōu tōng',
      'Speech Part': 'v',
      Definitions: 'communicate',
      hskLevel: 5,
    },
    {
      Hanzi: '古典',
      Pinyin: 'gǔ diǎn',
      'Speech Part': 'adj',
      Definitions: 'classical',
      hskLevel: 5,
    },
    {
      Hanzi: '股票',
      Pinyin: 'gǔ piào',
      'Speech Part': 'n',
      Definitions: 'share, stock',
      hskLevel: 5,
    },
    {
      Hanzi: '骨头',
      Pinyin: 'gǔ tou',
      'Speech Part': 'n',
      Definitions: 'bone',
      hskLevel: 5,
    },
    {
      Hanzi: '鼓舞',
      Pinyin: 'gǔ wǔ',
      'Speech Part': 'v',
      Definitions: 'encourage, inspire',
      hskLevel: 5,
    },
    {
      Hanzi: '鼓掌',
      Pinyin: 'gǔ zhǎng',
      'Speech Part': 'v',
      Definitions: 'applaud',
      hskLevel: 5,
    },
    {
      Hanzi: '固定',
      Pinyin: 'gù dìng',
      'Speech Part': 'adj, v',
      Definitions: 'fixed; fix',
      hskLevel: 5,
    },
    {
      Hanzi: '挂号',
      Pinyin: 'guà hào',
      'Speech Part': 'v',
      Definitions: 'register',
      hskLevel: 5,
    },
    {
      Hanzi: '乖',
      Pinyin: 'guāi',
      'Speech Part': 'adj',
      Definitions: 'clever, (of child) well-behaved',
      hskLevel: 5,
    },
    {
      Hanzi: '拐弯',
      Pinyin: 'guǎi wān',
      'Speech Part': 'v',
      Definitions: 'turn, turn round',
      hskLevel: 5,
    },
    {
      Hanzi: '怪不得',
      Pinyin: 'guài bu de',
      'Speech Part': 'adv',
      Definitions: 'no wonder',
      hskLevel: 5,
    },
    {
      Hanzi: '关闭',
      Pinyin: 'guān bì',
      'Speech Part': 'v',
      Definitions: 'close, shut',
      hskLevel: 5,
    },
    {
      Hanzi: '观察',
      Pinyin: 'guān chá',
      'Speech Part': 'v',
      Definitions: 'observe',
      hskLevel: 5,
    },
    {
      Hanzi: '观点',
      Pinyin: 'guān diǎn',
      'Speech Part': 'n',
      Definitions: 'view, viewpoint',
      hskLevel: 5,
    },
    {
      Hanzi: '观念',
      Pinyin: 'guān niàn',
      'Speech Part': 'n',
      Definitions: 'idea, concept',
      hskLevel: 5,
    },
    {
      Hanzi: '官',
      Pinyin: 'guān',
      'Speech Part': 'n',
      Definitions: 'government official, official',
      hskLevel: 5,
    },
    {
      Hanzi: '管子',
      Pinyin: 'guǎn zi',
      'Speech Part': 'n',
      Definitions: 'tube, pipe',
      hskLevel: 5,
    },
    {
      Hanzi: '冠军',
      Pinyin: 'guàn jūn',
      'Speech Part': 'n',
      Definitions: 'champion',
      hskLevel: 5,
    },
    {
      Hanzi: '光滑',
      Pinyin: 'guāng huá',
      'Speech Part': 'adj',
      Definitions: 'smooth, glossy',
      hskLevel: 5,
    },
    {
      Hanzi: '光临',
      Pinyin: 'guāng lín',
      'Speech Part': 'v',
      Definitions: '(polite) be present',
      hskLevel: 5,
    },
    {
      Hanzi: '广泛',
      Pinyin: 'guǎng fàn',
      'Speech Part': 'adj',
      Definitions: 'extensive, widespread',
      hskLevel: 5,
    },
    {
      Hanzi: '光盘',
      Pinyin: 'guāng pán',
      'Speech Part': 'n',
      Definitions: 'CD',
      hskLevel: 5,
    },
    {
      Hanzi: '广大',
      Pinyin: 'guǎng dà',
      'Speech Part': 'adj',
      Definitions: 'wide, vast',
      hskLevel: 5,
    },
    {
      Hanzi: '光明',
      Pinyin: 'guāng míng',
      'Speech Part': 'adj, n',
      Definitions: 'bright; light',
      hskLevel: 5,
    },
    {
      Hanzi: '广场',
      Pinyin: 'guǎng chǎng',
      'Speech Part': 'n',
      Definitions: 'square, plaza',
      hskLevel: 5,
    },
    {
      Hanzi: '归纳',
      Pinyin: 'guī nà',
      'Speech Part': 'n, v',
      Definitions: 'induction; sum up',
      hskLevel: 5,
    },
    {
      Hanzi: '规矩',
      Pinyin: 'guī ju',
      'Speech Part': 'adj, n',
      Definitions: 'well-behaved; rule, manners',
      hskLevel: 5,
    },
    {
      Hanzi: '规则',
      Pinyin: 'guī zé',
      'Speech Part': 'adj, n',
      Definitions: 'regular; rule',
      hskLevel: 5,
    },
    {
      Hanzi: '规律',
      Pinyin: 'guī lǜ',
      'Speech Part': 'n',
      Definitions: 'law, regular pattern',
      hskLevel: 5,
    },
    {
      Hanzi: '规模',
      Pinyin: 'guī mó',
      'Speech Part': 'n',
      Definitions: 'scale, scope',
      hskLevel: 5,
    },
    {
      Hanzi: '柜台',
      Pinyin: 'guì tái',
      'Speech Part': 'n',
      Definitions: 'counter',
      hskLevel: 5,
    },
    {
      Hanzi: '滚',
      Pinyin: 'gǔn',
      'Speech Part': 'v',
      Definitions: 'roll, get away',
      hskLevel: 5,
    },
    {
      Hanzi: '国王',
      Pinyin: 'guó wáng',
      'Speech Part': 'n',
      Definitions: 'king',
      hskLevel: 5,
    },
    {
      Hanzi: '国庆节',
      Pinyin: 'guó qìng jié',
      'Speech Part': 'n',
      Definitions: 'National Day',
      hskLevel: 5,
    },
    {
      Hanzi: '锅',
      Pinyin: 'guō',
      'Speech Part': 'n',
      Definitions: 'pot, pan',
      hskLevel: 5,
    },
    {
      Hanzi: '果然',
      Pinyin: 'guǒ rán',
      'Speech Part': 'adv',
      Definitions: 'really, as expected',
      hskLevel: 5,
    },
    {
      Hanzi: '过期',
      Pinyin: 'guò qī',
      'Speech Part': 'v',
      Definitions: 'be overdue, expired',
      hskLevel: 5,
    },
    {
      Hanzi: '过分',
      Pinyin: 'guò fèn',
      'Speech Part': 'adj',
      Definitions: 'excessive, going too far',
      hskLevel: 5,
    },
    {
      Hanzi: '果实',
      Pinyin: 'guǒ shí',
      'Speech Part': 'n',
      Definitions: 'fruit, fruits',
      hskLevel: 5,
    },
    {
      Hanzi: '哈',
      Pinyin: 'hā',
      'Speech Part': 'int, v',
      Definitions: '(expressing triumph or satisfaction); breathe out',
      hskLevel: 5,
    },
    {
      Hanzi: '海关',
      Pinyin: 'hǎi guān',
      'Speech Part': 'n',
      Definitions: 'customs',
      hskLevel: 5,
    },
    {
      Hanzi: '过敏',
      Pinyin: 'guò mǐn',
      'Speech Part': 'v',
      Definitions: 'be sensitive to, allergy',
      hskLevel: 5,
    },
    {
      Hanzi: '行业',
      Pinyin: 'háng yè',
      'Speech Part': 'n',
      Definitions: 'profession, industry',
      hskLevel: 5,
    },
    {
      Hanzi: '喊',
      Pinyin: 'hǎn',
      'Speech Part': 'v',
      Definitions: 'shout, call',
      hskLevel: 5,
    },
    {
      Hanzi: '好客',
      Pinyin: 'hào kè',
      'Speech Part': 'adj',
      Definitions: 'hospitable',
      hskLevel: 5,
    },
    {
      Hanzi: '豪华',
      Pinyin: 'háo huá',
      'Speech Part': 'adj',
      Definitions: 'luxurious',
      hskLevel: 5,
    },
    {
      Hanzi: '海鲜',
      Pinyin: 'hǎi xiān',
      'Speech Part': 'n',
      Definitions: 'seafood',
      hskLevel: 5,
    },
    {
      Hanzi: '合法',
      Pinyin: 'hé fǎ',
      'Speech Part': 'adj',
      Definitions: 'legal, lawful',
      hskLevel: 5,
    },
    {
      Hanzi: '合理',
      Pinyin: 'hé lǐ',
      'Speech Part': 'adj',
      Definitions: 'resonable, rational',
      hskLevel: 5,
    },
    {
      Hanzi: '好奇',
      Pinyin: 'hào qí',
      'Speech Part': 'adj',
      Definitions: 'curious',
      hskLevel: 5,
    },
    {
      Hanzi: '合影',
      Pinyin: 'hé yǐng',
      'Speech Part': 'n, v',
      Definitions: 'group photo; take a group photo',
      hskLevel: 5,
    },
    {
      Hanzi: '合同',
      Pinyin: 'hé tóng',
      'Speech Part': 'n',
      Definitions: 'contract',
      hskLevel: 5,
    },
    {
      Hanzi: '何必',
      Pinyin: 'hé bì',
      'Speech Part': 'adv',
      Definitions: 'why, there is no need',
      hskLevel: 5,
    },
    {
      Hanzi: '何况',
      Pinyin: 'hé kuàng',
      'Speech Part': 'conj',
      Definitions: 'moreover, let alone',
      hskLevel: 5,
    },
    {
      Hanzi: '合作',
      Pinyin: 'hé zuò',
      'Speech Part': 'v',
      Definitions: 'cooperate, work together',
      hskLevel: 5,
    },
    {
      Hanzi: '和平',
      Pinyin: 'hé píng',
      'Speech Part': 'n',
      Definitions: 'peace',
      hskLevel: 5,
    },
    {
      Hanzi: '核心',
      Pinyin: 'hé xīn',
      'Speech Part': 'n',
      Definitions: 'nucleus, core',
      hskLevel: 5,
    },
    {
      Hanzi: '恨',
      Pinyin: 'hèn',
      'Speech Part': 'v',
      Definitions: 'hate, resent',
      hskLevel: 5,
    },
    {
      Hanzi: '猴子',
      Pinyin: 'hóu zi',
      'Speech Part': 'n',
      Definitions: 'monkey',
      hskLevel: 5,
    },
    {
      Hanzi: '后背',
      Pinyin: 'hòu bèi',
      'Speech Part': 'n',
      Definitions: 'back (of the human body)',
      hskLevel: 5,
    },
    {
      Hanzi: '后果',
      Pinyin: 'hòu guǒ',
      'Speech Part': 'n',
      Definitions: 'consequence, aftermath',
      hskLevel: 5,
    },
    {
      Hanzi: '呼吸',
      Pinyin: 'hū xī',
      'Speech Part': 'v',
      Definitions: 'breathe',
      hskLevel: 5,
    },
    {
      Hanzi: '忽然',
      Pinyin: 'hū rán',
      'Speech Part': 'adv',
      Definitions: 'suddenly',
      hskLevel: 5,
    },
    {
      Hanzi: '忽视',
      Pinyin: 'hū shì',
      'Speech Part': 'v',
      Definitions: 'neglect, ignore',
      hskLevel: 5,
    },
    {
      Hanzi: '胡说',
      Pinyin: 'hú shuō',
      'Speech Part': 'v',
      Definitions: 'talk nonsense',
      hskLevel: 5,
    },
    {
      Hanzi: '胡同',
      Pinyin: 'hú tòng',
      'Speech Part': 'n',
      Definitions: 'lane, alley',
      hskLevel: 5,
    },
  ],
  6: [
    {
      Hanzi: '哎',
      Pinyin: 'āi',
      'Speech Part': 'int',
      Definitions: '(used to attract attention), hey, oh',
      hskLevel: 6,
    },
    {
      Hanzi: '唉',
      Pinyin: 'āi',
      'Speech Part': 'int',
      Definitions: '(a response to a call/an order)',
      hskLevel: 6,
    },
    {
      Hanzi: '爱护',
      Pinyin: 'ài hù',
      'Speech Part': 'v',
      Definitions: 'care for, take good care of',
      hskLevel: 6,
    },
    {
      Hanzi: '爱惜',
      Pinyin: 'ài xī',
      'Speech Part': 'v',
      Definitions: 'treasure, cherish',
      hskLevel: 6,
    },
    {
      Hanzi: '爱心',
      Pinyin: 'ài xīn',
      'Speech Part': 'n',
      Definitions: 'loving heart',
      hskLevel: 6,
    },
    {
      Hanzi: '安慰',
      Pinyin: 'ān wèi',
      'Speech Part': 'v',
      Definitions: 'comfort, console',
      hskLevel: 6,
    },
    {
      Hanzi: '安装',
      Pinyin: 'ān zhuāng',
      'Speech Part': 'v',
      Definitions: 'install, set up',
      hskLevel: 6,
    },
    {
      Hanzi: '岸',
      Pinyin: 'àn',
      'Speech Part': 'n',
      Definitions: 'bank, coast',
      hskLevel: 6,
    },
    {
      Hanzi: '暗',
      Pinyin: 'àn',
      'Speech Part': 'adj',
      Definitions: 'dark, dim',
      hskLevel: 6,
    },
    {
      Hanzi: '把握',
      Pinyin: 'bǎ wò',
      'Speech Part': 'n, v',
      Definitions: 'assurance; hold, grasp',
      hskLevel: 6,
    },
    {
      Hanzi: '熬夜',
      Pinyin: 'áo yè',
      'Speech Part': 'v',
      Definitions: 'stay up late',
      hskLevel: 6,
    },
    {
      Hanzi: '办理',
      Pinyin: 'bàn lǐ',
      'Speech Part': 'v',
      Definitions: 'handle, conduct',
      hskLevel: 6,
    },
    {
      Hanzi: '摆',
      Pinyin: 'bǎi',
      'Speech Part': 'v',
      Definitions: 'put, place, wave',
      hskLevel: 6,
    },
    {
      Hanzi: '傍晚',
      Pinyin: 'bàng wǎn',
      'Speech Part': 'n',
      Definitions: 'evening, dusk',
      hskLevel: 6,
    },
    {
      Hanzi: '包裹',
      Pinyin: 'bāo guǒ',
      'Speech Part': 'n',
      Definitions: 'parcel, package',
      hskLevel: 6,
    },
    {
      Hanzi: '包含',
      Pinyin: 'bāo hán',
      'Speech Part': 'v',
      Definitions: 'contain, include',
      hskLevel: 6,
    },
    {
      Hanzi: '包括',
      Pinyin: 'bāo kuò',
      'Speech Part': 'v',
      Definitions: 'include',
      hskLevel: 6,
    },
    {
      Hanzi: '薄',
      Pinyin: 'báo',
      'Speech Part': 'adj',
      Definitions: 'thin, flimsy',
      hskLevel: 6,
    },
    {
      Hanzi: '宝贝',
      Pinyin: 'bǎo bèi',
      'Speech Part': 'n',
      Definitions: 'treasure, baby',
      hskLevel: 6,
    },
    {
      Hanzi: '宝贵',
      Pinyin: 'bǎo guì',
      'Speech Part': 'adj',
      Definitions: 'valuable, precious',
      hskLevel: 6,
    },
    {
      Hanzi: '保持',
      Pinyin: 'bǎo chí',
      'Speech Part': 'v',
      Definitions: 'maintain, preserve',
      hskLevel: 6,
    },
    {
      Hanzi: '保存',
      Pinyin: 'bǎo cún',
      'Speech Part': 'v',
      Definitions: 'keep',
      hskLevel: 6,
    },
    {
      Hanzi: '保留',
      Pinyin: 'bǎo liú',
      'Speech Part': 'v',
      Definitions: 'retain, keep back',
      hskLevel: 6,
    },
    {
      Hanzi: '保险',
      Pinyin: 'bǎo xiǎn',
      'Speech Part': 'n',
      Definitions: 'insurance',
      hskLevel: 6,
    },
    {
      Hanzi: '报到',
      Pinyin: 'bào dào',
      'Speech Part': 'v',
      Definitions: 'check in, report for duty',
      hskLevel: 6,
    },
    {
      Hanzi: '报道',
      Pinyin: 'bào dào',
      'Speech Part': 'n, v',
      Definitions: 'news report; report on, cover',
      hskLevel: 6,
    },
    {
      Hanzi: '报告',
      Pinyin: 'bào gào',
      'Speech Part': 'n, v',
      Definitions: 'report',
      hskLevel: 6,
    },
    {
      Hanzi: '报社',
      Pinyin: 'bào shè',
      'Speech Part': 'n',
      Definitions: 'newspaper office',
      hskLevel: 6,
    },
    {
      Hanzi: '抱怨',
      Pinyin: 'bào yuàn',
      'Speech Part': 'v',
      Definitions: 'complain, grumble',
      hskLevel: 6,
    },
    {
      Hanzi: '背',
      Pinyin: 'bēi',
      'Speech Part': 'v',
      Definitions: 'carry on the back',
      hskLevel: 6,
    },
    {
      Hanzi: '悲观',
      Pinyin: 'bēi guān',
      'Speech Part': 'adj',
      Definitions: 'pessimistic',
      hskLevel: 6,
    },
    {
      Hanzi: '背景',
      Pinyin: 'bèi jǐng',
      'Speech Part': 'n',
      Definitions: 'background',
      hskLevel: 6,
    },
    {
      Hanzi: '被子',
      Pinyin: 'bèi zi',
      'Speech Part': 'n',
      Definitions: 'quilt',
      hskLevel: 6,
    },
    {
      Hanzi: '本科',
      Pinyin: 'běn kē',
      'Speech Part': 'n',
      Definitions: 'undergraduate course',
      hskLevel: 6,
    },
    {
      Hanzi: '本领',
      Pinyin: 'běn lǐng',
      'Speech Part': 'n',
      Definitions: 'ability, skill',
      hskLevel: 6,
    },
    {
      Hanzi: '本质',
      Pinyin: 'běn zhì',
      'Speech Part': 'n',
      Definitions: 'nature',
      hskLevel: 6,
    },
    {
      Hanzi: '比例',
      Pinyin: 'bǐ lì',
      'Speech Part': 'n',
      Definitions: 'proportion, ratio',
      hskLevel: 6,
    },
    {
      Hanzi: '彼此',
      Pinyin: 'bǐ cǐ',
      'Speech Part': 'pron',
      Definitions: 'each other',
      hskLevel: 6,
    },
    {
      Hanzi: '必然',
      Pinyin: 'bì rán',
      'Speech Part': 'adj',
      Definitions: 'inevitable',
      hskLevel: 6,
    },
    {
      Hanzi: '必要',
      Pinyin: 'bì yào',
      'Speech Part': 'adj',
      Definitions: 'necessary, essential',
      hskLevel: 6,
    },
    {
      Hanzi: '毕竟',
      Pinyin: 'bì jìng',
      'Speech Part': 'adv',
      Definitions: 'after all',
      hskLevel: 6,
    },
    {
      Hanzi: '避免',
      Pinyin: 'bì miǎn',
      'Speech Part': 'v',
      Definitions: 'avoid',
      hskLevel: 6,
    },
    {
      Hanzi: '编辑',
      Pinyin: 'biān jí',
      'Speech Part': 'n, v',
      Definitions: 'editor; edit',
      hskLevel: 6,
    },
    {
      Hanzi: '鞭炮',
      Pinyin: 'biān pào',
      'Speech Part': 'n',
      Definitions: 'firecrackers',
      hskLevel: 6,
    },
    {
      Hanzi: '便',
      Pinyin: 'biàn',
      'Speech Part': 'adv',
      Definitions: 'as soon as',
      hskLevel: 6,
    },
    {
      Hanzi: '辩论',
      Pinyin: 'biàn lùn',
      'Speech Part': 'v',
      Definitions: 'debate',
      hskLevel: 6,
    },
    {
      Hanzi: '标点',
      Pinyin: 'biāo diǎn',
      'Speech Part': 'n',
      Definitions: 'punctuation',
      hskLevel: 6,
    },
    {
      Hanzi: '标志',
      Pinyin: 'biāo zhì',
      'Speech Part': 'n, v',
      Definitions: 'symbol, sign; indicate, symbolise',
      hskLevel: 6,
    },
    {
      Hanzi: '表达',
      Pinyin: 'biǎo dá',
      'Speech Part': 'v',
      Definitions: 'express',
      hskLevel: 6,
    },
    {
      Hanzi: '表面',
      Pinyin: 'biǎo miàn',
      'Speech Part': 'n',
      Definitions: 'surface, appearance',
      hskLevel: 6,
    },
    {
      Hanzi: '表明',
      Pinyin: 'biǎo míng',
      'Speech Part': 'v',
      Definitions: 'indicate, make clear',
      hskLevel: 6,
    },
    {
      Hanzi: '表情',
      Pinyin: 'biǎo qíng',
      'Speech Part': 'n',
      Definitions: 'expression',
      hskLevel: 6,
    },
    {
      Hanzi: '表现',
      Pinyin: 'biǎo xiàn',
      'Speech Part': 'v',
      Definitions: 'show, behave',
      hskLevel: 6,
    },
    {
      Hanzi: '冰淇凌',
      Pinyin: 'bīng jī líng',
      'Speech Part': 'n',
      Definitions: 'ice cream',
      hskLevel: 6,
    },
    {
      Hanzi: '病毒',
      Pinyin: 'bìng dú',
      'Speech Part': 'n',
      Definitions: 'virus',
      hskLevel: 6,
    },
    {
      Hanzi: '玻璃',
      Pinyin: 'bō li',
      'Speech Part': 'n',
      Definitions: 'glass',
      hskLevel: 6,
    },
    {
      Hanzi: '播放',
      Pinyin: 'bō fàng',
      'Speech Part': 'v',
      Definitions: 'broadcast',
      hskLevel: 6,
    },
    {
      Hanzi: '脖子',
      Pinyin: 'bó zi',
      'Speech Part': 'n',
      Definitions: 'neck',
      hskLevel: 6,
    },
    {
      Hanzi: '博物馆',
      Pinyin: 'bó wù guǎn',
      'Speech Part': 'n',
      Definitions: 'museum',
      hskLevel: 6,
    },
    {
      Hanzi: '不断',
      Pinyin: 'bú duàn',
      'Speech Part': 'adv',
      Definitions: 'unceasingly, continuously',
      hskLevel: 6,
    },
    {
      Hanzi: '不见得',
      Pinyin: 'bú jiàn dé',
      'Speech Part': 'adv',
      Definitions: 'not likely, may not',
      hskLevel: 6,
    },
    {
      Hanzi: '不耐烦',
      Pinyin: 'bú nài fán',
      'Speech Part': '-',
      Definitions: 'impatient',
      hskLevel: 6,
    },
    {
      Hanzi: '不要紧',
      Pinyin: 'bú yào jǐn',
      'Speech Part': 'adj',
      Definitions: 'not serious, never mind',
      hskLevel: 6,
    },
    {
      Hanzi: '补充',
      Pinyin: 'bǔ chōng',
      'Speech Part': 'v',
      Definitions: 'replenish, supplement',
      hskLevel: 6,
    },
    {
      Hanzi: '不安',
      Pinyin: 'bù ān',
      'Speech Part': 'adj',
      Definitions: 'uneasy',
      hskLevel: 6,
    },
    {
      Hanzi: '不得了',
      Pinyin: 'bù dé liǎo',
      'Speech Part': '-',
      Definitions: 'terrible, extremely',
      hskLevel: 6,
    },
    {
      Hanzi: '不然',
      Pinyin: 'bù rán',
      'Speech Part': 'conj',
      Definitions: 'otherwise',
      hskLevel: 6,
    },
    {
      Hanzi: '不如',
      Pinyin: 'bù rú',
      'Speech Part': 'v',
      Definitions: 'be inferior to',
      hskLevel: 6,
    },
    {
      Hanzi: '不足',
      Pinyin: 'bù zú',
      'Speech Part': 'n, v',
      Definitions: 'insufficiency; be less than',
      hskLevel: 6,
    },
    {
      Hanzi: '布',
      Pinyin: 'bù',
      'Speech Part': 'n',
      Definitions: 'cloth',
      hskLevel: 6,
    },
    {
      Hanzi: '步骤',
      Pinyin: 'bù zhòu',
      'Speech Part': 'n',
      Definitions: 'step',
      hskLevel: 6,
    },
    {
      Hanzi: '部门',
      Pinyin: 'bù mén',
      'Speech Part': 'n',
      Definitions: 'department',
      hskLevel: 6,
    },
    {
      Hanzi: '财产',
      Pinyin: 'cái chǎn',
      'Speech Part': 'n',
      Definitions: 'property',
      hskLevel: 6,
    },
    {
      Hanzi: '采访',
      Pinyin: 'cǎi fǎng',
      'Speech Part': 'v',
      Definitions: 'interview',
      hskLevel: 6,
    },
    {
      Hanzi: '采取',
      Pinyin: 'cǎi qǔ',
      'Speech Part': 'v',
      Definitions: 'adopt',
      hskLevel: 6,
    },
    {
      Hanzi: '彩虹',
      Pinyin: 'cǎi hóng',
      'Speech Part': 'n',
      Definitions: 'rainbow',
      hskLevel: 6,
    },
    {
      Hanzi: '踩',
      Pinyin: 'cǎi',
      'Speech Part': 'v',
      Definitions: 'step on, tread on',
      hskLevel: 6,
    },
    {
      Hanzi: '参考',
      Pinyin: 'cān kǎo',
      'Speech Part': 'v',
      Definitions: 'consult, refer to',
      hskLevel: 6,
    },
    {
      Hanzi: '参与',
      Pinyin: 'cān yù',
      'Speech Part': 'v',
      Definitions: 'take part in',
      hskLevel: 6,
    },
    {
      Hanzi: '惭愧',
      Pinyin: 'cán kuì',
      'Speech Part': 'adj',
      Definitions: 'ashamed',
      hskLevel: 6,
    },
    {
      Hanzi: '操场',
      Pinyin: 'cāo chǎng',
      'Speech Part': 'n',
      Definitions: 'playground',
      hskLevel: 6,
    },
    {
      Hanzi: '操心',
      Pinyin: 'cāo xīn',
      'Speech Part': 'v',
      Definitions: 'worry about something, be concerned with',
      hskLevel: 6,
    },
    {
      Hanzi: '册',
      Pinyin: 'cè',
      'Speech Part': 'm',
      Definitions: 'volume',
      hskLevel: 6,
    },
    {
      Hanzi: '测验',
      Pinyin: 'cè yàn',
      'Speech Part': 'v',
      Definitions: 'test, quiz',
      hskLevel: 6,
    },
    {
      Hanzi: '曾经',
      Pinyin: 'céng jīng',
      'Speech Part': 'adv',
      Definitions: 'once',
      hskLevel: 6,
    },
    {
      Hanzi: '叉子',
      Pinyin: 'chā zi',
      'Speech Part': 'n',
      Definitions: 'fork',
      hskLevel: 6,
    },
    {
      Hanzi: '差别',
      Pinyin: 'chā bié',
      'Speech Part': 'n',
      Definitions: 'difference',
      hskLevel: 6,
    },
    {
      Hanzi: '差距',
      Pinyin: 'chā jù',
      'Speech Part': 'n',
      Definitions: 'disparity, gap',
      hskLevel: 6,
    },
    {
      Hanzi: '插',
      Pinyin: 'chā',
      'Speech Part': 'v',
      Definitions: 'insert',
      hskLevel: 6,
    },
    {
      Hanzi: '拆',
      Pinyin: 'chāi',
      'Speech Part': 'v',
      Definitions: 'tear open, take apart',
      hskLevel: 6,
    },
    {
      Hanzi: '产品',
      Pinyin: 'chǎn pǐn',
      'Speech Part': 'n',
      Definitions: 'product',
      hskLevel: 6,
    },
    {
      Hanzi: '产生',
      Pinyin: 'chǎn shēng',
      'Speech Part': 'v',
      Definitions: 'bring about, produce, cause',
      hskLevel: 6,
    },
    {
      Hanzi: '长途',
      Pinyin: 'cháng tú',
      'Speech Part': 'adj',
      Definitions: 'long-distance',
      hskLevel: 6,
    },
    {
      Hanzi: '常识',
      Pinyin: 'cháng shí',
      'Speech Part': 'n',
      Definitions: 'general knowledge, common sense',
      hskLevel: 6,
    },
    {
      Hanzi: '抄',
      Pinyin: 'chāo',
      'Speech Part': 'v',
      Definitions: 'copy, transcribe',
      hskLevel: 6,
    },
    {
      Hanzi: '超级',
      Pinyin: 'chāo jí',
      'Speech Part': 'adj',
      Definitions: 'super',
      hskLevel: 6,
    },
    {
      Hanzi: '朝',
      Pinyin: 'cháo',
      'Speech Part': 'prep, v',
      Definitions: 'towards; face (to)',
      hskLevel: 6,
    },
    {
      Hanzi: '潮湿',
      Pinyin: 'cháo shī',
      'Speech Part': 'adj',
      Definitions: 'moist, damp',
      hskLevel: 6,
    },
    {
      Hanzi: '吵',
      Pinyin: 'chǎo',
      'Speech Part': 'adj, v',
      Definitions: 'noisy; quarrel',
      hskLevel: 6,
    },
    {
      Hanzi: '吵架',
      Pinyin: 'chǎo jià',
      'Speech Part': 'v',
      Definitions: 'quarrel',
      hskLevel: 6,
    },
    {
      Hanzi: '炒',
      Pinyin: 'chǎo',
      'Speech Part': 'v',
      Definitions: 'fry',
      hskLevel: 6,
    },
    {
      Hanzi: '车库',
      Pinyin: 'chē kù',
      'Speech Part': 'n',
      Definitions: 'garage',
      hskLevel: 6,
    },
    {
      Hanzi: '车厢',
      Pinyin: 'chē xiāng',
      'Speech Part': 'n',
      Definitions: 'railway carriage',
      hskLevel: 6,
    },
    {
      Hanzi: '彻底',
      Pinyin: 'chè dǐ',
      'Speech Part': 'adj',
      Definitions: 'thorough',
      hskLevel: 6,
    },
    {
      Hanzi: '沉默',
      Pinyin: 'chén mò',
      'Speech Part': 'v',
      Definitions: 'be silent',
      hskLevel: 6,
    },
    {
      Hanzi: '趁',
      Pinyin: 'chèn',
      'Speech Part': 'prep',
      Definitions: 'taking advantage of',
      hskLevel: 6,
    },
    {
      Hanzi: '称',
      Pinyin: 'chēng',
      'Speech Part': 'v',
      Definitions: 'call, weigh',
      hskLevel: 6,
    },
    {
      Hanzi: '称呼',
      Pinyin: 'chēng hu',
      'Speech Part': 'n, v',
      Definitions: 'form of address; call, address',
      hskLevel: 6,
    },
    {
      Hanzi: '称赞',
      Pinyin: 'chēng zàn',
      'Speech Part': 'v',
      Definitions: 'praise',
      hskLevel: 6,
    },
    {
      Hanzi: '成分',
      Pinyin: 'chéng fèn',
      'Speech Part': 'n',
      Definitions: 'composition, element',
      hskLevel: 6,
    },
    {
      Hanzi: '成果',
      Pinyin: 'chéng guǒ',
      'Speech Part': 'n',
      Definitions: 'achievement, positive result',
      hskLevel: 6,
    },
    {
      Hanzi: '成就',
      Pinyin: 'chéng jiù',
      'Speech Part': 'n',
      Definitions: 'achievement, accomplishment',
      hskLevel: 6,
    },
    {
      Hanzi: '成立',
      Pinyin: 'chéng lì',
      'Speech Part': 'v',
      Definitions: 'found, set up',
      hskLevel: 6,
    },
    {
      Hanzi: '成人',
      Pinyin: 'chéng rén',
      'Speech Part': 'n',
      Definitions: 'adult, grown-up',
      hskLevel: 6,
    },
    {
      Hanzi: '成熟',
      Pinyin: 'chéng shú',
      'Speech Part': 'adj, v',
      Definitions: 'mature; ripen',
      hskLevel: 6,
    },
    {
      Hanzi: '成语',
      Pinyin: 'chéng yǔ',
      'Speech Part': 'n',
      Definitions: 'set phrase (in Chinese)',
      hskLevel: 6,
    },
    {
      Hanzi: '成长',
      Pinyin: 'chéng zhǎng',
      'Speech Part': 'v',
      Definitions: 'grow up, develop',
      hskLevel: 6,
    },
    {
      Hanzi: '承担',
      Pinyin: 'chéng dān',
      'Speech Part': 'v',
      Definitions: 'bear, undertake',
      hskLevel: 6,
    },
    {
      Hanzi: '承受',
      Pinyin: 'chéng shòu',
      'Speech Part': 'v',
      Definitions: 'bear, stand',
      hskLevel: 6,
    },
    {
      Hanzi: '承认',
      Pinyin: 'chéng rèn',
      'Speech Part': 'v',
      Definitions: 'admit, acknowledge',
      hskLevel: 6,
    },
    {
      Hanzi: '诚恳',
      Pinyin: 'chéng kěn',
      'Speech Part': 'adj',
      Definitions: 'sincere',
      hskLevel: 6,
    },
    {
      Hanzi: '程度',
      Pinyin: 'chéng dù',
      'Speech Part': 'n',
      Definitions: 'degree, level',
      hskLevel: 6,
    },
    {
      Hanzi: '程序',
      Pinyin: 'chéng xù',
      'Speech Part': 'n',
      Definitions: 'procedure, computer program',
      hskLevel: 6,
    },
    {
      Hanzi: '池塘',
      Pinyin: 'chí táng',
      'Speech Part': 'n',
      Definitions: 'pond, pool',
      hskLevel: 6,
    },
    {
      Hanzi: '吃亏',
      Pinyin: 'chī kuī',
      'Speech Part': 'v',
      Definitions: 'suffer losses, be at a disadvantage',
      hskLevel: 6,
    },
    {
      Hanzi: '迟早',
      Pinyin: 'chí zǎo',
      'Speech Part': 'adv',
      Definitions: 'sooner or later',
      hskLevel: 6,
    },
    {
      Hanzi: '充电器',
      Pinyin: 'chōng diàn qì',
      'Speech Part': 'n',
      Definitions: '(battery) charger',
      hskLevel: 6,
    },
    {
      Hanzi: '尺子',
      Pinyin: 'chǐ zi',
      'Speech Part': 'n',
      Definitions: 'ruler',
      hskLevel: 6,
    },
    {
      Hanzi: '持续',
      Pinyin: 'chí xù',
      'Speech Part': 'v',
      Definitions: 'continue, sustain',
      hskLevel: 6,
    },
    {
      Hanzi: '充分',
      Pinyin: 'chōng fèn',
      'Speech Part': 'adj',
      Definitions: 'sufficient, to the full, as full as possible',
      hskLevel: 6,
    },
    {
      Hanzi: '翅膀',
      Pinyin: 'chì bǎng',
      'Speech Part': 'n',
      Definitions: 'wing',
      hskLevel: 6,
    },
    {
      Hanzi: '冲',
      Pinyin: 'chōng',
      'Speech Part': 'v',
      Definitions: 'rush, flush',
      hskLevel: 6,
    },
    {
      Hanzi: '重复',
      Pinyin: 'chóng fù',
      'Speech Part': 'v',
      Definitions: 'repeat, duplicate',
      hskLevel: 6,
    },
    {
      Hanzi: '充满',
      Pinyin: 'chōng mǎn',
      'Speech Part': 'v',
      Definitions: 'be full of, be filled with',
      hskLevel: 6,
    },
    {
      Hanzi: '抽屉',
      Pinyin: 'chōu ti',
      'Speech Part': 'n',
      Definitions: 'drawer',
      hskLevel: 6,
    },
    {
      Hanzi: '宠物',
      Pinyin: 'chǒng wù',
      'Speech Part': 'n',
      Definitions: 'pet',
      hskLevel: 6,
    },
    {
      Hanzi: '抽象',
      Pinyin: 'chōu xiàng',
      'Speech Part': 'adj',
      Definitions: 'abstract',
      hskLevel: 6,
    },
    {
      Hanzi: '出口',
      Pinyin: 'chū kǒu',
      'Speech Part': 'n, v',
      Definitions: 'exit; export',
      hskLevel: 6,
    },
    {
      Hanzi: '出版',
      Pinyin: 'chū bǎn',
      'Speech Part': 'v',
      Definitions: 'publish',
      hskLevel: 6,
    },
    {
      Hanzi: '丑',
      Pinyin: 'chǒu',
      'Speech Part': 'adj',
      Definitions: 'ugly, shameful',
      hskLevel: 6,
    },
    {
      Hanzi: '出示',
      Pinyin: 'chū shì',
      'Speech Part': 'v',
      Definitions: 'show, produce',
      hskLevel: 6,
    },
    {
      Hanzi: '臭',
      Pinyin: 'chòu',
      'Speech Part': 'adj',
      Definitions: 'smelly, stinking, odious',
      hskLevel: 6,
    },
    {
      Hanzi: '出色',
      Pinyin: 'chū sè',
      'Speech Part': 'adj',
      Definitions: 'outstanding, excellent',
      hskLevel: 6,
    },
    {
      Hanzi: '初级',
      Pinyin: 'chū jí',
      'Speech Part': 'adj',
      Definitions: 'elementary, primary',
      hskLevel: 6,
    },
    {
      Hanzi: '出席',
      Pinyin: 'chū xí',
      'Speech Part': 'v',
      Definitions: 'attend, be present',
      hskLevel: 6,
    },
    {
      Hanzi: '除非',
      Pinyin: 'chú fēi',
      'Speech Part': 'conj',
      Definitions: 'only if, only when',
      hskLevel: 6,
    },
    {
      Hanzi: '除夕',
      Pinyin: 'chú xī',
      'Speech Part': 'n',
      Definitions: "New Year's Eve",
      hskLevel: 6,
    },
    {
      Hanzi: '处理',
      Pinyin: 'chǔ lǐ',
      'Speech Part': 'v',
      Definitions: 'deal with, handle',
      hskLevel: 6,
    },
    {
      Hanzi: '传播',
      Pinyin: 'chuán bō',
      'Speech Part': 'v',
      Definitions: 'spread, disseminate',
      hskLevel: 6,
    },
    {
      Hanzi: '传染',
      Pinyin: 'chuán rǎn',
      'Speech Part': 'v',
      Definitions: 'infect',
      hskLevel: 6,
    },
    {
      Hanzi: '传说',
      Pinyin: 'chuán shuō',
      'Speech Part': 'n',
      Definitions: 'legend',
      hskLevel: 6,
    },
    {
      Hanzi: '传统',
      Pinyin: 'chuán tǒng',
      'Speech Part': 'adj, n',
      Definitions: 'traditional; tradition',
      hskLevel: 6,
    },
    {
      Hanzi: '窗帘',
      Pinyin: 'chuāng lián',
      'Speech Part': 'n',
      Definitions: 'curtain',
      hskLevel: 6,
    },
    {
      Hanzi: '闯',
      Pinyin: 'chuǎng',
      'Speech Part': 'v',
      Definitions: 'rush, temper onself',
      hskLevel: 6,
    },
    {
      Hanzi: '创造',
      Pinyin: 'chuàng zào',
      'Speech Part': 'v',
      Definitions: 'create',
      hskLevel: 6,
    },
    {
      Hanzi: '吹',
      Pinyin: 'chuī',
      'Speech Part': 'v',
      Definitions: 'blow, play (wind instruments)',
      hskLevel: 6,
    },
    {
      Hanzi: '词汇',
      Pinyin: 'cí huì',
      'Speech Part': 'n',
      Definitions: 'vocabulary',
      hskLevel: 6,
    },
    {
      Hanzi: '辞职',
      Pinyin: 'cí zhí',
      'Speech Part': 'v',
      Definitions: 'resign, quit',
      hskLevel: 6,
    },
    {
      Hanzi: '此外',
      Pinyin: 'cǐ wài',
      'Speech Part': 'conj',
      Definitions: 'besides, in addition',
      hskLevel: 6,
    },
    {
      Hanzi: '次要',
      Pinyin: 'cì yào',
      'Speech Part': 'adj',
      Definitions: 'less important, secondary',
      hskLevel: 6,
    },
    {
      Hanzi: '刺激',
      Pinyin: 'cì jī',
      'Speech Part': 'v',
      Definitions: 'stimulate, upset',
      hskLevel: 6,
    },
    {
      Hanzi: '匆忙',
      Pinyin: 'cōng máng',
      'Speech Part': 'adj',
      Definitions: 'hurried, hasty',
      hskLevel: 6,
    },
    {
      Hanzi: '从此',
      Pinyin: 'cóng cǐ',
      'Speech Part': 'adv',
      Definitions: 'from this time on',
      hskLevel: 6,
    },
    {
      Hanzi: '从而',
      Pinyin: 'cóng ér',
      'Speech Part': 'conj',
      Definitions: 'thus, thereby',
      hskLevel: 6,
    },
    {
      Hanzi: '从前',
      Pinyin: 'cóng qián',
      'Speech Part': 'n',
      Definitions: 'former times, the past',
      hskLevel: 6,
    },
    {
      Hanzi: '从事',
      Pinyin: 'cóng shì',
      'Speech Part': 'v',
      Definitions: 'undertake, be engaged in',
      hskLevel: 6,
    },
    {
      Hanzi: '粗糙',
      Pinyin: 'cū cāo',
      'Speech Part': 'adj',
      Definitions: 'rough, ragged, unpolished',
      hskLevel: 6,
    },
    {
      Hanzi: '促进',
      Pinyin: 'cù jìn',
      'Speech Part': 'v',
      Definitions: 'promote',
      hskLevel: 6,
    },
    {
      Hanzi: '促使',
      Pinyin: 'cù shǐ',
      'Speech Part': 'v',
      Definitions: 'impel, urge',
      hskLevel: 6,
    },
    {
      Hanzi: '醋',
      Pinyin: 'cù',
      'Speech Part': 'n',
      Definitions: 'vinegar',
      hskLevel: 6,
    },
    {
      Hanzi: '催',
      Pinyin: 'cuī',
      'Speech Part': 'v',
      Definitions: 'hurry, urge',
      hskLevel: 6,
    },
    {
      Hanzi: '存在',
      Pinyin: 'cún zài',
      'Speech Part': 'v',
      Definitions: 'exist',
      hskLevel: 6,
    },
    {
      Hanzi: '措施',
      Pinyin: 'cuò shī',
      'Speech Part': 'n',
      Definitions: 'measure',
      hskLevel: 6,
    },
    {
      Hanzi: '答应',
      Pinyin: 'dā ying',
      'Speech Part': 'v',
      Definitions: 'answer, agree',
      hskLevel: 6,
    },
    {
      Hanzi: '达到',
      Pinyin: 'dá dào',
      'Speech Part': 'v',
      Definitions: 'achieve, reach',
      hskLevel: 6,
    },
    {
      Hanzi: '打工',
      Pinyin: 'dǎ gōng',
      'Speech Part': 'v',
      Definitions: 'do a temporary job',
      hskLevel: 6,
    },
    {
      Hanzi: '打交道',
      Pinyin: 'dǎ jiāo dao',
      'Speech Part': '-',
      Definitions: 'have dealings with',
      hskLevel: 6,
    },
    {
      Hanzi: '大方',
      Pinyin: 'dà fang',
      'Speech Part': 'adj',
      Definitions: 'natural and poised, generous',
      hskLevel: 6,
    },
    {
      Hanzi: '大厦',
      Pinyin: 'dà shà',
      'Speech Part': 'n',
      Definitions: 'mansion, large building',
      hskLevel: 6,
    },
    {
      Hanzi: '打喷嚏',
      Pinyin: 'dǎ pēn tì',
      'Speech Part': '-',
      Definitions: 'sneeze',
      hskLevel: 6,
    },
    {
      Hanzi: '打听',
      Pinyin: 'dǎ ting',
      'Speech Part': 'v',
      Definitions: 'enquire about, ask about',
      hskLevel: 6,
    },
    {
      Hanzi: '大型',
      Pinyin: 'dà xíng',
      'Speech Part': 'adj',
      Definitions: 'large-scale, large-sized',
      hskLevel: 6,
    },
    {
      Hanzi: '呆',
      Pinyin: 'dāi',
      'Speech Part': 'adj',
      Definitions: 'dull, blank',
      hskLevel: 6,
    },
    {
      Hanzi: '代表',
      Pinyin: 'dài biǎo',
      'Speech Part': 'n, v',
      Definitions: 'representative; represent',
      hskLevel: 6,
    },
    {
      Hanzi: '代替',
      Pinyin: 'dài tì',
      'Speech Part': 'v',
      Definitions: 'substitute for, replace',
      hskLevel: 6,
    },
    {
      Hanzi: '待遇',
      Pinyin: 'dài yù',
      'Speech Part': 'n',
      Definitions: 'treatment, remuneration',
      hskLevel: 6,
    },
    {
      Hanzi: '贷款',
      Pinyin: 'dài kuǎn',
      'Speech Part': 'n, v',
      Definitions: 'loan; get a loan',
      hskLevel: 6,
    },
    {
      Hanzi: '大象',
      Pinyin: 'dà xiàng',
      'Speech Part': 'n',
      Definitions: 'elephant',
      hskLevel: 6,
    },
    {
      Hanzi: '担任',
      Pinyin: 'dān rèn',
      'Speech Part': 'v',
      Definitions: 'hold (a post)',
      hskLevel: 6,
    },
    {
      Hanzi: '单纯',
      Pinyin: 'dān chún',
      'Speech Part': 'adj',
      Definitions: 'simple, pure',
      hskLevel: 6,
    },
    {
      Hanzi: '单调',
      Pinyin: 'dān diào',
      'Speech Part': 'adj',
      Definitions: 'monotonous, dull',
      hskLevel: 6,
    },
    {
      Hanzi: '单独',
      Pinyin: 'dān dú',
      'Speech Part': 'adv',
      Definitions: "alone, on one's own",
      hskLevel: 6,
    },
    {
      Hanzi: '单元',
      Pinyin: 'dān yuán',
      'Speech Part': 'n',
      Definitions: 'unit',
      hskLevel: 6,
    },
    {
      Hanzi: '单位',
      Pinyin: 'dān wèi',
      'Speech Part': 'n',
      Definitions:
        'unit (for standard of measurement or a group of people as a whole)',
      hskLevel: 6,
    },
    {
      Hanzi: '耽误',
      Pinyin: 'dān wu',
      'Speech Part': 'v',
      Definitions: 'delay, hold up',
      hskLevel: 6,
    },
    {
      Hanzi: '胆小鬼',
      Pinyin: 'dǎn xiǎo guǐ',
      'Speech Part': 'n',
      Definitions: 'coward',
      hskLevel: 6,
    },
    {
      Hanzi: '淡',
      Pinyin: 'dàn',
      'Speech Part': 'adj',
      Definitions: 'bland (for food/drinks), light (for colour)',
      hskLevel: 6,
    },
    {
      Hanzi: '当地',
      Pinyin: 'dāng dì',
      'Speech Part': 'n',
      Definitions: 'locality, place mentioned',
      hskLevel: 6,
    },
    {
      Hanzi: '当心',
      Pinyin: 'dāng xīn',
      'Speech Part': 'v',
      Definitions: 'take care, look out',
      hskLevel: 6,
    },
    {
      Hanzi: '挡',
      Pinyin: 'dǎng',
      'Speech Part': 'v',
      Definitions: 'block, keep off',
      hskLevel: 6,
    },
    {
      Hanzi: '导演',
      Pinyin: 'dǎo yǎn',
      'Speech Part': 'n, v',
      Definitions: 'director; direct',
      hskLevel: 6,
    },
    {
      Hanzi: '导致',
      Pinyin: 'dǎo zhì',
      'Speech Part': 'v',
      Definitions: 'lead to, bring about',
      hskLevel: 6,
    },
    {
      Hanzi: '岛屿',
      Pinyin: 'dǎo yǔ',
      'Speech Part': 'n',
      Definitions: 'islands',
      hskLevel: 6,
    },
    {
      Hanzi: '到达',
      Pinyin: 'dào dá',
      'Speech Part': 'v',
      Definitions: 'arrive, reach',
      hskLevel: 6,
    },
    {
      Hanzi: '倒霉',
      Pinyin: 'dǎo méi',
      'Speech Part': 'adj',
      Definitions: 'unlucky',
      hskLevel: 6,
    },
    {
      Hanzi: '道德',
      Pinyin: 'dào dé',
      'Speech Part': 'n',
      Definitions: 'morality, morals',
      hskLevel: 6,
    },
    {
      Hanzi: '登记',
      Pinyin: 'dēng jì',
      'Speech Part': 'v',
      Definitions: 'register, check in',
      hskLevel: 6,
    },
    {
      Hanzi: '等待',
      Pinyin: 'děng dài',
      'Speech Part': 'v',
      Definitions: 'wait for, await',
      hskLevel: 6,
    },
    {
      Hanzi: '滴',
      Pinyin: 'dī',
      'Speech Part': 'm, v',
      Definitions: 'drop; drip',
      hskLevel: 6,
    },
    {
      Hanzi: '地道',
      Pinyin: 'dì dao',
      'Speech Part': 'adj',
      Definitions: 'genuine, typical',
      hskLevel: 6,
    },
    {
      Hanzi: '的确',
      Pinyin: 'dí què',
      'Speech Part': 'adv',
      Definitions: 'indeed, really',
      hskLevel: 6,
    },
    {
      Hanzi: '道理',
      Pinyin: 'dào lǐ',
      'Speech Part': 'n',
      Definitions: 'reason, principle',
      hskLevel: 6,
    },
    {
      Hanzi: '等于',
      Pinyin: 'děng yú',
      'Speech Part': 'v',
      Definitions: 'be equal to, amount to',
      hskLevel: 6,
    },
    {
      Hanzi: '地区',
      Pinyin: 'dì qū',
      'Speech Part': 'n',
      Definitions: 'area, district',
      hskLevel: 6,
    },
    {
      Hanzi: '敌人',
      Pinyin: 'dí rén',
      'Speech Part': 'n',
      Definitions: 'enemy',
      hskLevel: 6,
    },
    {
      Hanzi: '地理',
      Pinyin: 'dì lǐ',
      'Speech Part': 'n',
      Definitions: 'geography',
      hskLevel: 6,
    },
    {
      Hanzi: '地毯',
      Pinyin: 'dì tǎn',
      'Speech Part': 'n',
      Definitions: 'carpet',
      hskLevel: 6,
    },
    {
      Hanzi: '地位',
      Pinyin: 'dì wèi',
      'Speech Part': 'n',
      Definitions: 'position, ranking',
      hskLevel: 6,
    },
    {
      Hanzi: '地震',
      Pinyin: 'dì zhèn',
      'Speech Part': 'v',
      Definitions: 'earthquake',
      hskLevel: 6,
    },
    {
      Hanzi: '递',
      Pinyin: 'dì',
      'Speech Part': 'v',
      Definitions: 'pass, hand over',
      hskLevel: 6,
    },
    {
      Hanzi: '点心',
      Pinyin: 'diǎn xin',
      'Speech Part': 'n',
      Definitions: 'dessert',
      hskLevel: 6,
    },
    {
      Hanzi: '电池',
      Pinyin: 'diàn chí',
      'Speech Part': 'n',
      Definitions: 'battery, cell',
      hskLevel: 6,
    },
    {
      Hanzi: '电台',
      Pinyin: 'diàn tái',
      'Speech Part': 'n',
      Definitions: 'radio station, transmitter-receiver',
      hskLevel: 6,
    },
    {
      Hanzi: '钓',
      Pinyin: 'diào',
      'Speech Part': 'v',
      Definitions: 'angle, fish',
      hskLevel: 6,
    },
    {
      Hanzi: '顶',
      Pinyin: 'dǐng',
      'Speech Part': 'n, m',
      Definitions: 'top, crown; (used for something that has a top)',
      hskLevel: 6,
    },
    {
      Hanzi: '动画片',
      Pinyin: 'dòng huà piàn',
      'Speech Part': 'n',
      Definitions: 'cartoon',
      hskLevel: 6,
    },
    {
      Hanzi: '豆腐',
      Pinyin: 'dòu fu',
      'Speech Part': 'n',
      Definitions: 'tofu, bean curd',
      hskLevel: 6,
    },
    {
      Hanzi: '洞',
      Pinyin: 'dòng',
      'Speech Part': 'n',
      Definitions: 'cavity, hole',
      hskLevel: 6,
    },
    {
      Hanzi: '逗',
      Pinyin: 'dòu',
      'Speech Part': 'adj, v',
      Definitions: 'funny; tease',
      hskLevel: 6,
    },
    {
      Hanzi: '冻',
      Pinyin: 'dòng',
      'Speech Part': 'v',
      Definitions: 'freeze',
      hskLevel: 6,
    },
    {
      Hanzi: '独立',
      Pinyin: 'dú lì',
      'Speech Part': 'adj, v',
      Definitions: 'independent; become independent',
      hskLevel: 6,
    },
    {
      Hanzi: '独特',
      Pinyin: 'dú tè',
      'Speech Part': 'adj',
      Definitions: 'unique, distinctive',
      hskLevel: 6,
    },
    {
      Hanzi: '度过',
      Pinyin: 'dù guò',
      'Speech Part': 'v',
      Definitions: 'spend',
      hskLevel: 6,
    },
    {
      Hanzi: '断',
      Pinyin: 'duàn',
      'Speech Part': 'v',
      Definitions: 'break, cut off',
      hskLevel: 6,
    },
    {
      Hanzi: '对待',
      Pinyin: 'duì dài',
      'Speech Part': 'v',
      Definitions: 'treat',
      hskLevel: 6,
    },
    {
      Hanzi: '堆',
      Pinyin: 'duī',
      'Speech Part': 'm, v',
      Definitions: 'stack; pile (up)',
      hskLevel: 6,
    },
    {
      Hanzi: '对比',
      Pinyin: 'duì bǐ',
      'Speech Part': 'v',
      Definitions: 'contrast, compare',
      hskLevel: 6,
    },
    {
      Hanzi: '对方',
      Pinyin: 'duì fāng',
      'Speech Part': 'n',
      Definitions: 'other side, other party',
      hskLevel: 6,
    },
    {
      Hanzi: '对象',
      Pinyin: 'duì xiàng',
      'Speech Part': 'n',
      Definitions: 'object, target',
      hskLevel: 6,
    },
    {
      Hanzi: '对手',
      Pinyin: 'duì shǒu',
      'Speech Part': 'n',
      Definitions: 'competitor, rival',
      hskLevel: 6,
    },
    {
      Hanzi: '兑换',
      Pinyin: 'duì huàn',
      'Speech Part': 'v',
      Definitions: 'convert, exchange',
      hskLevel: 6,
    },
    {
      Hanzi: '吨',
      Pinyin: 'dūn',
      'Speech Part': 'm',
      Definitions: 'ton',
      hskLevel: 6,
    },
    {
      Hanzi: '顿',
      Pinyin: 'dùn',
      'Speech Part': 'm',
      Definitions: 'time',
      hskLevel: 6,
    },
    {
      Hanzi: '蹲',
      Pinyin: 'dūn',
      'Speech Part': 'v',
      Definitions: 'squat, crounch',
      hskLevel: 6,
    },
    {
      Hanzi: '多余',
      Pinyin: 'duō yú',
      'Speech Part': 'adj',
      Definitions: 'surplus, redundant',
      hskLevel: 6,
    },
    {
      Hanzi: '朵',
      Pinyin: 'duǒ',
      'Speech Part': 'm',
      Definitions: '(of flowers/clouds)',
      hskLevel: 6,
    },
    {
      Hanzi: '多亏',
      Pinyin: 'duō kuī',
      'Speech Part': 'v',
      Definitions: 'owe something to somebody',
      hskLevel: 6,
    },
    {
      Hanzi: '躲藏',
      Pinyin: 'duǒ cáng',
      'Speech Part': 'v',
      Definitions: 'hide',
      hskLevel: 6,
    },
    {
      Hanzi: '恶劣',
      Pinyin: 'è liè',
      'Speech Part': 'adj',
      Definitions: 'very bad, disgusting',
      hskLevel: 6,
    },
    {
      Hanzi: '耳环',
      Pinyin: 'ěr huán',
      'Speech Part': 'n',
      Definitions: 'earring, earbob',
      hskLevel: 6,
    },
    {
      Hanzi: '发愁',
      Pinyin: 'fā chóu',
      'Speech Part': 'v',
      Definitions: 'worry (about)',
      hskLevel: 6,
    },
    {
      Hanzi: '发表',
      Pinyin: 'fā biǎo',
      'Speech Part': 'v',
      Definitions: 'issue, publish',
      hskLevel: 6,
    },
    {
      Hanzi: '发抖',
      Pinyin: 'fā dǒu',
      'Speech Part': 'v',
      Definitions: 'quake, shake',
      hskLevel: 6,
    },
    {
      Hanzi: '发达',
      Pinyin: 'fā dá',
      'Speech Part': 'adj',
      Definitions: 'developed',
      hskLevel: 6,
    },
    {
      Hanzi: '发挥',
      Pinyin: 'fā huī',
      'Speech Part': 'v',
      Definitions: 'bring into play, give (full) play to',
      hskLevel: 6,
    },
    {
      Hanzi: '发明',
      Pinyin: 'fā míng',
      'Speech Part': 'n, v',
      Definitions: 'invention; invent',
      hskLevel: 6,
    },
    {
      Hanzi: '发票',
      Pinyin: 'fā piào',
      'Speech Part': 'n',
      Definitions: 'invoice',
      hskLevel: 6,
    },
    {
      Hanzi: '发言',
      Pinyin: 'fā yán',
      'Speech Part': 'n, v',
      Definitions: 'speech; speak',
      hskLevel: 6,
    },
    {
      Hanzi: '罚款',
      Pinyin: 'fá kuǎn',
      'Speech Part': 'n, v',
      Definitions: 'fine, penalty; impose a fine',
      hskLevel: 6,
    },
    {
      Hanzi: '法院',
      Pinyin: 'fǎ yuàn',
      'Speech Part': 'n',
      Definitions: 'court',
      hskLevel: 6,
    },
    {
      Hanzi: '翻',
      Pinyin: 'fān',
      'Speech Part': 'v',
      Definitions: 'turn over, climb over',
      hskLevel: 6,
    },
    {
      Hanzi: '繁荣',
      Pinyin: 'fán róng',
      'Speech Part': 'adj',
      Definitions: 'flourishing, prosperous',
      hskLevel: 6,
    },
    {
      Hanzi: '反而',
      Pinyin: 'fǎn ér',
      'Speech Part': 'adv',
      Definitions: 'on the contrary, instead',
      hskLevel: 6,
    },
    {
      Hanzi: '反应',
      Pinyin: 'fǎn yìng',
      'Speech Part': 'n, v',
      Definitions: 'reaction; respond, react',
      hskLevel: 6,
    },
    {
      Hanzi: '反复',
      Pinyin: 'fǎn fù',
      'Speech Part': 'adv, v',
      Definitions: 'repeatedly; vacilate',
      hskLevel: 6,
    },
    {
      Hanzi: '反映',
      Pinyin: 'fǎn yìng',
      'Speech Part': 'v',
      Definitions: 'reflect, make known',
      hskLevel: 6,
    },
    {
      Hanzi: '反正',
      Pinyin: 'fǎn zhèng',
      'Speech Part': 'adv',
      Definitions: 'all the same, anyway',
      hskLevel: 6,
    },
    {
      Hanzi: '范围',
      Pinyin: 'fàn wéi',
      'Speech Part': 'n',
      Definitions: 'scope, range, domain',
      hskLevel: 6,
    },
    {
      Hanzi: '方',
      Pinyin: 'fāng',
      'Speech Part': 'adj',
      Definitions: 'direction, side',
      hskLevel: 6,
    },
    {
      Hanzi: '方案',
      Pinyin: 'fāng àn',
      'Speech Part': 'n',
      Definitions: 'plan, programme',
      hskLevel: 6,
    },
    {
      Hanzi: '非',
      Pinyin: 'fēi',
      'Speech Part': 'adj, v, adv',
      Definitions: 'wrong; be not; have got to, simply must',
      hskLevel: 6,
    },
    {
      Hanzi: '妨碍',
      Pinyin: 'fáng ài',
      'Speech Part': 'v',
      Definitions: 'hinder, obstruct',
      hskLevel: 6,
    },
    {
      Hanzi: '肥皂',
      Pinyin: 'féi zào',
      'Speech Part': 'n',
      Definitions: 'soap',
      hskLevel: 6,
    },
    {
      Hanzi: '仿佛',
      Pinyin: 'fǎng fú',
      'Speech Part': 'adv',
      Definitions: 'as if, as though',
      hskLevel: 6,
    },
    {
      Hanzi: '废话',
      Pinyin: 'fèi huà',
      'Speech Part': 'n, v',
      Definitions: 'nonsense; talk nonsense',
      hskLevel: 6,
    },
    {
      Hanzi: '分别',
      Pinyin: 'fēn bié',
      'Speech Part': 'adv, v',
      Definitions: 'separately, respectively; part',
      hskLevel: 6,
    },
    {
      Hanzi: '分布',
      Pinyin: 'fēn bù',
      'Speech Part': 'v',
      Definitions: 'be distributed',
      hskLevel: 6,
    },
    {
      Hanzi: '分配',
      Pinyin: 'fēn pèi',
      'Speech Part': 'v',
      Definitions: 'distribute, portion out',
      hskLevel: 6,
    },
    {
      Hanzi: '分手',
      Pinyin: 'fēn shǒu',
      'Speech Part': 'v',
      Definitions: 'split up, part',
      hskLevel: 6,
    },
    {
      Hanzi: '分析',
      Pinyin: 'fēn xī',
      'Speech Part': 'v',
      Definitions: 'analyse',
      hskLevel: 6,
    },
    {
      Hanzi: '纷纷',
      Pinyin: 'fēn fēn',
      'Speech Part': 'adv',
      Definitions: 'one after another, in succession',
      hskLevel: 6,
    },
    {
      Hanzi: '奋斗',
      Pinyin: 'fèn dòu',
      'Speech Part': 'v',
      Definitions: 'struggle, strive',
      hskLevel: 6,
    },
    {
      Hanzi: '风格',
      Pinyin: 'fēng gé',
      'Speech Part': 'n',
      Definitions: 'style',
      hskLevel: 6,
    },
    {
      Hanzi: '风景',
      Pinyin: 'fēng jǐng',
      'Speech Part': 'n',
      Definitions: 'view',
      hskLevel: 6,
    },
    {
      Hanzi: '风险',
      Pinyin: 'fēng xiǎn',
      'Speech Part': 'n',
      Definitions: 'risk',
      hskLevel: 6,
    },
    {
      Hanzi: '讽刺',
      Pinyin: 'fěng cì',
      'Speech Part': 'v',
      Definitions: 'satirise',
      hskLevel: 6,
    },
    {
      Hanzi: '风俗',
      Pinyin: 'fēng sú',
      'Speech Part': 'n',
      Definitions: 'custom',
      hskLevel: 6,
    },
    {
      Hanzi: '否定',
      Pinyin: 'fǒu dìng',
      'Speech Part': 'adj, v',
      Definitions: 'negative; deny',
      hskLevel: 6,
    },
    {
      Hanzi: '否认',
      Pinyin: 'fǒu rèn',
      'Speech Part': 'v',
      Definitions: 'deny',
      hskLevel: 6,
    },
    {
      Hanzi: '疯狂',
      Pinyin: 'fēng kuáng',
      'Speech Part': 'adj',
      Definitions: 'frenzied, crazy',
      hskLevel: 6,
    },
    {
      Hanzi: '扶',
      Pinyin: 'fú',
      'Speech Part': 'v',
      Definitions:
        'place a hand on something for support, support (with the hand)',
      hskLevel: 6,
    },
    {
      Hanzi: '幅',
      Pinyin: 'fú',
      'Speech Part': 'm',
      Definitions: '(for paintings/cloth)',
      hskLevel: 6,
    },
    {
      Hanzi: '服装',
      Pinyin: 'fú zhuāng',
      'Speech Part': 'n',
      Definitions: 'clothing, dress',
      hskLevel: 6,
    },
    {
      Hanzi: '辅导',
      Pinyin: 'fǔ dǎo',
      'Speech Part': 'v',
      Definitions: 'coach, tutor',
      hskLevel: 6,
    },
    {
      Hanzi: '妇女',
      Pinyin: 'fù nǚ',
      'Speech Part': 'n',
      Definitions: 'woman',
      hskLevel: 6,
    },
    {
      Hanzi: '复制',
      Pinyin: 'fù zhì',
      'Speech Part': 'v',
      Definitions: 'copy, duplicate',
      hskLevel: 6,
    },
    {
      Hanzi: '改革',
      Pinyin: 'gǎi gé',
      'Speech Part': 'n, v',
      Definitions: 'reform',
      hskLevel: 6,
    },
    {
      Hanzi: '改进',
      Pinyin: 'gǎi jìn',
      'Speech Part': 'v',
      Definitions: 'improve',
      hskLevel: 6,
    },
    {
      Hanzi: '改善',
      Pinyin: 'gǎi shàn',
      'Speech Part': 'v',
      Definitions: 'improve, upgrade',
      hskLevel: 6,
    },
    {
      Hanzi: '盖',
      Pinyin: 'gài',
      'Speech Part': 'n, v',
      Definitions: 'cover; cover (up)',
      hskLevel: 6,
    },
    {
      Hanzi: '概括',
      Pinyin: 'gài kuò',
      'Speech Part': 'v',
      Definitions: 'summarise',
      hskLevel: 6,
    },
    {
      Hanzi: '改正',
      Pinyin: 'gǎi zhèng',
      'Speech Part': 'v',
      Definitions: 'correct, amend',
      hskLevel: 6,
    },
    {
      Hanzi: '概念',
      Pinyin: 'gài niàn',
      'Speech Part': 'n',
      Definitions: 'concept',
      hskLevel: 6,
    },
    {
      Hanzi: '干脆',
      Pinyin: 'gān cuì',
      'Speech Part': 'adj, adv',
      Definitions: 'straightforward; directly',
      hskLevel: 6,
    },
    {
      Hanzi: '干燥',
      Pinyin: 'gān zào',
      'Speech Part': 'adj',
      Definitions: 'dry',
      hskLevel: 6,
    },
    {
      Hanzi: '赶紧',
      Pinyin: 'gǎn jǐn',
      'Speech Part': 'adv',
      Definitions: 'hastily, quickly',
      hskLevel: 6,
    },
    {
      Hanzi: '赶快',
      Pinyin: 'gǎn kuài',
      'Speech Part': 'adv',
      Definitions: 'quickly',
      hskLevel: 6,
    },
    {
      Hanzi: '感激',
      Pinyin: 'gǎn jī',
      'Speech Part': 'v',
      Definitions: 'be grateful (to), be thankful (to)',
      hskLevel: 6,
    },
    {
      Hanzi: '感受',
      Pinyin: 'gǎn shòu',
      'Speech Part': 'n, v',
      Definitions: 'feeling; feel',
      hskLevel: 6,
    },
    {
      Hanzi: '干活儿',
      Pinyin: 'gàn huó r',
      'Speech Part': 'v',
      Definitions: 'work',
      hskLevel: 6,
    },
    {
      Hanzi: '高级',
      Pinyin: 'gāo jí',
      'Speech Part': 'adj',
      Definitions: 'advanced, high-grade, senior',
      hskLevel: 6,
    },
    {
      Hanzi: '钢铁',
      Pinyin: 'gāng tiě',
      'Speech Part': 'n',
      Definitions: 'iron and steel',
      hskLevel: 6,
    },
    {
      Hanzi: '高档',
      Pinyin: 'gāo dàng',
      'Speech Part': 'adj',
      Definitions: 'top-grade',
      hskLevel: 6,
    },
    {
      Hanzi: '搞',
      Pinyin: 'gǎo',
      'Speech Part': 'v',
      Definitions: 'do, make',
      hskLevel: 6,
    },
    {
      Hanzi: '感想',
      Pinyin: 'gǎn xiǎng',
      'Speech Part': 'n',
      Definitions: 'reflections, thoughts',
      hskLevel: 6,
    },
    {
      Hanzi: '告别',
      Pinyin: 'gào bié',
      'Speech Part': 'v',
      Definitions: 'say goodbye to',
      hskLevel: 6,
    },
    {
      Hanzi: '格外',
      Pinyin: 'gé wài',
      'Speech Part': 'adv',
      Definitions: 'extraodinarily, especially',
      hskLevel: 6,
    },
    {
      Hanzi: '个人',
      Pinyin: 'gè rén',
      'Speech Part': 'n, pron',
      Definitions: 'individual; (used on formal occasions to refer to oneself)',
      hskLevel: 6,
    },
    {
      Hanzi: '隔壁',
      Pinyin: 'gé bì',
      'Speech Part': 'n',
      Definitions: 'next-door apartment',
      hskLevel: 6,
    },
    {
      Hanzi: '个别',
      Pinyin: 'gè bié',
      'Speech Part': 'adv',
      Definitions: 'particularly',
      hskLevel: 6,
    },
    {
      Hanzi: '各自',
      Pinyin: 'gè zì',
      'Speech Part': 'pron',
      Definitions: 'each (of a group), oneself',
      hskLevel: 6,
    },
    {
      Hanzi: '个性',
      Pinyin: 'gè xìng',
      'Speech Part': 'n',
      Definitions: 'individuality, personality',
      hskLevel: 6,
    },
    {
      Hanzi: '根',
      Pinyin: 'gēn',
      'Speech Part': 'm, n',
      Definitions: '(for long/thin objects); root',
      hskLevel: 6,
    },
    {
      Hanzi: '根本',
      Pinyin: 'gēn běn',
      'Speech Part': 'adj, adv',
      Definitions: 'basic; at all, totally',
      hskLevel: 6,
    },
    {
      Hanzi: '工厂',
      Pinyin: 'gōng chǎng',
      'Speech Part': 'n',
      Definitions: 'factory',
      hskLevel: 6,
    },
    {
      Hanzi: '工程师',
      Pinyin: 'gōng chéng shī',
      'Speech Part': 'n',
      Definitions: 'engineer',
      hskLevel: 6,
    },
    {
      Hanzi: '工具',
      Pinyin: 'gōng jù',
      'Speech Part': 'n',
      Definitions: 'tool',
      hskLevel: 6,
    },
    {
      Hanzi: '工业',
      Pinyin: 'gōng yè',
      'Speech Part': 'n',
      Definitions: 'industry',
      hskLevel: 6,
    },
    {
      Hanzi: '工人',
      Pinyin: 'gōng rén',
      'Speech Part': 'n',
      Definitions: 'worker',
      hskLevel: 6,
    },
    {
      Hanzi: '公布',
      Pinyin: 'gōng bù',
      'Speech Part': 'v',
      Definitions: 'announce, publish',
      hskLevel: 6,
    },
    {
      Hanzi: '公开',
      Pinyin: 'gōng kāi',
      'Speech Part': 'adj, v',
      Definitions: 'open; make public',
      hskLevel: 6,
    },
    {
      Hanzi: '公平',
      Pinyin: 'gōng píng',
      'Speech Part': 'adj',
      Definitions: 'fair, impartial',
      hskLevel: 6,
    },
    {
      Hanzi: '公寓',
      Pinyin: 'gōng yù',
      'Speech Part': 'n',
      Definitions: 'flat, apartment',
      hskLevel: 6,
    },
    {
      Hanzi: '公元',
      Pinyin: 'gōng yuán',
      'Speech Part': 'n',
      Definitions: 'Christian era',
      hskLevel: 6,
    },
    {
      Hanzi: '公主',
      Pinyin: 'gōng zhǔ',
      'Speech Part': 'n',
      Definitions: 'princess',
      hskLevel: 6,
    },
    {
      Hanzi: '功能',
      Pinyin: 'gōng néng',
      'Speech Part': 'n',
      Definitions: 'function',
      hskLevel: 6,
    },
    {
      Hanzi: '恭喜',
      Pinyin: 'gōng xǐ',
      'Speech Part': 'v',
      Definitions: 'congratulate',
      hskLevel: 6,
    },
    {
      Hanzi: '贡献',
      Pinyin: 'gòng xiàn',
      'Speech Part': 'n, v',
      Definitions: 'contribution; contribute',
      hskLevel: 6,
    },
    {
      Hanzi: '姑姑',
      Pinyin: 'gū gu',
      'Speech Part': 'n',
      Definitions: "father's sister, aunt",
      hskLevel: 6,
    },
    {
      Hanzi: '姑娘',
      Pinyin: 'gū niang',
      'Speech Part': 'n',
      Definitions: 'girl, (informal) daughter',
      hskLevel: 6,
    },
    {
      Hanzi: '古代',
      Pinyin: 'gǔ dài',
      'Speech Part': 'n',
      Definitions: 'ancient times',
      hskLevel: 6,
    },
    {
      Hanzi: '构成',
      Pinyin: 'gòu chéng',
      'Speech Part': 'v',
      Definitions: 'constitute, form',
      hskLevel: 6,
    },
    {
      Hanzi: '沟通',
      Pinyin: 'gōu tōng',
      'Speech Part': 'v',
      Definitions: 'communicate',
      hskLevel: 6,
    },
    {
      Hanzi: '古典',
      Pinyin: 'gǔ diǎn',
      'Speech Part': 'adj',
      Definitions: 'classical',
      hskLevel: 6,
    },
    {
      Hanzi: '股票',
      Pinyin: 'gǔ piào',
      'Speech Part': 'n',
      Definitions: 'share, stock',
      hskLevel: 6,
    },
    {
      Hanzi: '骨头',
      Pinyin: 'gǔ tou',
      'Speech Part': 'n',
      Definitions: 'bone',
      hskLevel: 6,
    },
    {
      Hanzi: '鼓舞',
      Pinyin: 'gǔ wǔ',
      'Speech Part': 'v',
      Definitions: 'encourage, inspire',
      hskLevel: 6,
    },
    {
      Hanzi: '鼓掌',
      Pinyin: 'gǔ zhǎng',
      'Speech Part': 'v',
      Definitions: 'applaud',
      hskLevel: 6,
    },
    {
      Hanzi: '固定',
      Pinyin: 'gù dìng',
      'Speech Part': 'adj, v',
      Definitions: 'fixed; fix',
      hskLevel: 6,
    },
    {
      Hanzi: '挂号',
      Pinyin: 'guà hào',
      'Speech Part': 'v',
      Definitions: 'register',
      hskLevel: 6,
    },
    {
      Hanzi: '乖',
      Pinyin: 'guāi',
      'Speech Part': 'adj',
      Definitions: 'clever, (of child) well-behaved',
      hskLevel: 6,
    },
    {
      Hanzi: '拐弯',
      Pinyin: 'guǎi wān',
      'Speech Part': 'v',
      Definitions: 'turn, turn round',
      hskLevel: 6,
    },
    {
      Hanzi: '怪不得',
      Pinyin: 'guài bu de',
      'Speech Part': 'adv',
      Definitions: 'no wonder',
      hskLevel: 6,
    },
    {
      Hanzi: '关闭',
      Pinyin: 'guān bì',
      'Speech Part': 'v',
      Definitions: 'close, shut',
      hskLevel: 6,
    },
    {
      Hanzi: '观察',
      Pinyin: 'guān chá',
      'Speech Part': 'v',
      Definitions: 'observe',
      hskLevel: 6,
    },
    {
      Hanzi: '观点',
      Pinyin: 'guān diǎn',
      'Speech Part': 'n',
      Definitions: 'view, viewpoint',
      hskLevel: 6,
    },
    {
      Hanzi: '观念',
      Pinyin: 'guān niàn',
      'Speech Part': 'n',
      Definitions: 'idea, concept',
      hskLevel: 6,
    },
    {
      Hanzi: '官',
      Pinyin: 'guān',
      'Speech Part': 'n',
      Definitions: 'government official, official',
      hskLevel: 6,
    },
    {
      Hanzi: '管子',
      Pinyin: 'guǎn zi',
      'Speech Part': 'n',
      Definitions: 'tube, pipe',
      hskLevel: 6,
    },
    {
      Hanzi: '冠军',
      Pinyin: 'guàn jūn',
      'Speech Part': 'n',
      Definitions: 'champion',
      hskLevel: 6,
    },
    {
      Hanzi: '光滑',
      Pinyin: 'guāng huá',
      'Speech Part': 'adj',
      Definitions: 'smooth, glossy',
      hskLevel: 6,
    },
    {
      Hanzi: '光临',
      Pinyin: 'guāng lín',
      'Speech Part': 'v',
      Definitions: '(polite) be present',
      hskLevel: 6,
    },
    {
      Hanzi: '广泛',
      Pinyin: 'guǎng fàn',
      'Speech Part': 'adj',
      Definitions: 'extensive, widespread',
      hskLevel: 6,
    },
    {
      Hanzi: '光盘',
      Pinyin: 'guāng pán',
      'Speech Part': 'n',
      Definitions: 'CD',
      hskLevel: 6,
    },
    {
      Hanzi: '广大',
      Pinyin: 'guǎng dà',
      'Speech Part': 'adj',
      Definitions: 'wide, vast',
      hskLevel: 6,
    },
    {
      Hanzi: '光明',
      Pinyin: 'guāng míng',
      'Speech Part': 'adj, n',
      Definitions: 'bright; light',
      hskLevel: 6,
    },
    {
      Hanzi: '广场',
      Pinyin: 'guǎng chǎng',
      'Speech Part': 'n',
      Definitions: 'square, plaza',
      hskLevel: 6,
    },
    {
      Hanzi: '归纳',
      Pinyin: 'guī nà',
      'Speech Part': 'n, v',
      Definitions: 'induction; sum up',
      hskLevel: 6,
    },
    {
      Hanzi: '规矩',
      Pinyin: 'guī ju',
      'Speech Part': 'adj, n',
      Definitions: 'well-behaved; rule, manners',
      hskLevel: 6,
    },
    {
      Hanzi: '规则',
      Pinyin: 'guī zé',
      'Speech Part': 'adj, n',
      Definitions: 'regular; rule',
      hskLevel: 6,
    },
    {
      Hanzi: '规律',
      Pinyin: 'guī lǜ',
      'Speech Part': 'n',
      Definitions: 'law, regular pattern',
      hskLevel: 6,
    },
    {
      Hanzi: '规模',
      Pinyin: 'guī mó',
      'Speech Part': 'n',
      Definitions: 'scale, scope',
      hskLevel: 6,
    },
    {
      Hanzi: '柜台',
      Pinyin: 'guì tái',
      'Speech Part': 'n',
      Definitions: 'counter',
      hskLevel: 6,
    },
    {
      Hanzi: '滚',
      Pinyin: 'gǔn',
      'Speech Part': 'v',
      Definitions: 'roll, get away',
      hskLevel: 6,
    },
    {
      Hanzi: '国王',
      Pinyin: 'guó wáng',
      'Speech Part': 'n',
      Definitions: 'king',
      hskLevel: 6,
    },
    {
      Hanzi: '国庆节',
      Pinyin: 'guó qìng jié',
      'Speech Part': 'n',
      Definitions: 'National Day',
      hskLevel: 6,
    },
    {
      Hanzi: '锅',
      Pinyin: 'guō',
      'Speech Part': 'n',
      Definitions: 'pot, pan',
      hskLevel: 6,
    },
    {
      Hanzi: '果然',
      Pinyin: 'guǒ rán',
      'Speech Part': 'adv',
      Definitions: 'really, as expected',
      hskLevel: 6,
    },
    {
      Hanzi: '过期',
      Pinyin: 'guò qī',
      'Speech Part': 'v',
      Definitions: 'be overdue, expired',
      hskLevel: 6,
    },
    {
      Hanzi: '过分',
      Pinyin: 'guò fèn',
      'Speech Part': 'adj',
      Definitions: 'excessive, going too far',
      hskLevel: 6,
    },
    {
      Hanzi: '果实',
      Pinyin: 'guǒ shí',
      'Speech Part': 'n',
      Definitions: 'fruit, fruits',
      hskLevel: 6,
    },
    {
      Hanzi: '哈',
      Pinyin: 'hā',
      'Speech Part': 'int, v',
      Definitions: '(expressing triumph or satisfaction); breathe out',
      hskLevel: 6,
    },
    {
      Hanzi: '海关',
      Pinyin: 'hǎi guān',
      'Speech Part': 'n',
      Definitions: 'customs',
      hskLevel: 6,
    },
    {
      Hanzi: '过敏',
      Pinyin: 'guò mǐn',
      'Speech Part': 'v',
      Definitions: 'be sensitive to, allergy',
      hskLevel: 6,
    },
    {
      Hanzi: '行业',
      Pinyin: 'háng yè',
      'Speech Part': 'n',
      Definitions: 'profession, industry',
      hskLevel: 6,
    },
    {
      Hanzi: '喊',
      Pinyin: 'hǎn',
      'Speech Part': 'v',
      Definitions: 'shout, call',
      hskLevel: 6,
    },
    {
      Hanzi: '好客',
      Pinyin: 'hào kè',
      'Speech Part': 'adj',
      Definitions: 'hospitable',
      hskLevel: 6,
    },
    {
      Hanzi: '豪华',
      Pinyin: 'háo huá',
      'Speech Part': 'adj',
      Definitions: 'luxurious',
      hskLevel: 6,
    },
    {
      Hanzi: '海鲜',
      Pinyin: 'hǎi xiān',
      'Speech Part': 'n',
      Definitions: 'seafood',
      hskLevel: 6,
    },
    {
      Hanzi: '合法',
      Pinyin: 'hé fǎ',
      'Speech Part': 'adj',
      Definitions: 'legal, lawful',
      hskLevel: 6,
    },
    {
      Hanzi: '合理',
      Pinyin: 'hé lǐ',
      'Speech Part': 'adj',
      Definitions: 'resonable, rational',
      hskLevel: 6,
    },
    {
      Hanzi: '好奇',
      Pinyin: 'hào qí',
      'Speech Part': 'adj',
      Definitions: 'curious',
      hskLevel: 6,
    },
    {
      Hanzi: '合影',
      Pinyin: 'hé yǐng',
      'Speech Part': 'n, v',
      Definitions: 'group photo; take a group photo',
      hskLevel: 6,
    },
    {
      Hanzi: '合同',
      Pinyin: 'hé tóng',
      'Speech Part': 'n',
      Definitions: 'contract',
      hskLevel: 6,
    },
    {
      Hanzi: '何必',
      Pinyin: 'hé bì',
      'Speech Part': 'adv',
      Definitions: 'why, there is no need',
      hskLevel: 6,
    },
    {
      Hanzi: '何况',
      Pinyin: 'hé kuàng',
      'Speech Part': 'conj',
      Definitions: 'moreover, let alone',
      hskLevel: 6,
    },
    {
      Hanzi: '合作',
      Pinyin: 'hé zuò',
      'Speech Part': 'v',
      Definitions: 'cooperate, work together',
      hskLevel: 6,
    },
    {
      Hanzi: '和平',
      Pinyin: 'hé píng',
      'Speech Part': 'n',
      Definitions: 'peace',
      hskLevel: 6,
    },
    {
      Hanzi: '核心',
      Pinyin: 'hé xīn',
      'Speech Part': 'n',
      Definitions: 'nucleus, core',
      hskLevel: 6,
    },
    {
      Hanzi: '恨',
      Pinyin: 'hèn',
      'Speech Part': 'v',
      Definitions: 'hate, resent',
      hskLevel: 6,
    },
    {
      Hanzi: '猴子',
      Pinyin: 'hóu zi',
      'Speech Part': 'n',
      Definitions: 'monkey',
      hskLevel: 6,
    },
    {
      Hanzi: '后背',
      Pinyin: 'hòu bèi',
      'Speech Part': 'n',
      Definitions: 'back (of the human body)',
      hskLevel: 6,
    },
    {
      Hanzi: '后果',
      Pinyin: 'hòu guǒ',
      'Speech Part': 'n',
      Definitions: 'consequence, aftermath',
      hskLevel: 6,
    },
    {
      Hanzi: '呼吸',
      Pinyin: 'hū xī',
      'Speech Part': 'v',
      Definitions: 'breathe',
      hskLevel: 6,
    },
    {
      Hanzi: '忽然',
      Pinyin: 'hū rán',
      'Speech Part': 'adv',
      Definitions: 'suddenly',
      hskLevel: 6,
    },
    {
      Hanzi: '忽视',
      Pinyin: 'hū shì',
      'Speech Part': 'v',
      Definitions: 'neglect, ignore',
      hskLevel: 6,
    },
    {
      Hanzi: '胡说',
      Pinyin: 'hú shuō',
      'Speech Part': 'v',
      Definitions: 'talk nonsense',
      hskLevel: 6,
    },
    {
      Hanzi: '胡同',
      Pinyin: 'hú tòng',
      'Speech Part': 'n',
      Definitions: 'lane, alley',
      hskLevel: 6,
    },
  ],
};

// function addHSKLevel(arr, hskLevel) {
//   for (let wordIndex = 0; wordIndex < arr.length; wordIndex++) {
//     const word = arr[wordIndex];

//     word['hskLevel'] = hskLevel;
//   }

//   return arr;
// }

export default data;
