import React from 'react';
import './buttonselect.css';
const ButtonSelect = (props) => {
  function handleFalseClick() {
    props.addSeedsList();
    props.toggleDetailAndSelect();
  }

  function handleTrueClick() {
    props.addPlantsList();
    props.toggleDetailAndSelect();
  }

  return (
    <>
      <button onClick={handleFalseClick} className="button button--false">
        <img
          src={require('../../../../../../../../images/false.png')}
          alt=""
          className="button--false--image"
        />
      </button>
      <button onClick={handleTrueClick} className="button button--true">
        <img
          src={require('../../../../../../../../images/true.png')}
          alt=""
          className="button--true--image"
        />
      </button>
    </>
  );
};

export default ButtonSelect;
