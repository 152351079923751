import React from 'react';
import './wordtranslated.css';
const WordTranslated = ({ translatedWord, showDetailAndSelect }) => {
  return (
    <div className="word--translated">
      {showDetailAndSelect && translatedWord}
    </div>
  );
};

export default WordTranslated;
