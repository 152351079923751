import React from 'react';
import './buttons.css';
import ButtonSelect from './components/buttonselect/ButtonSelect';
import ButtonShow from './components/buttonshow/ButtonShow';
const Buttons = (props) => {
  return (
    <div className="button--container">
      {props.showDetailAndSelect ? (
        <ButtonSelect
          addPlantsList={props.addPlantsList}
          addSeedsList={props.addSeedsList}
          toggleDetailAndSelect={props.toggleDetailAndSelect}
        />
      ) : (
        <ButtonShow toggleDetailAndSelect={props.toggleDetailAndSelect} />
      )}
    </div>
  );
};

export default Buttons;
