import { useState } from 'react';
import './wordsortcard.css';

const WordSortCard = ({ strength, wordSort }) => {
  const [indexHover, setIndexHover] = useState(-1);

  return (
    <div className={`wordlevel-card wordlevel-card--${strength}`}>
      <div className="wordlevel-card__title">
        {strength.toUpperCase()} ({wordSort[strength]?.length || 0})
      </div>
      <div className="wordlevel-card__word-container">
        {wordSort[strength]
          ?.sort((a, b) => b[1] - a[1])
          .map((e, i) => (
            <div
              className="wordlevel-card__word"
              onMouseEnter={() => setIndexHover(i)}
              onMouseLeave={() => setIndexHover(-1)}
              onClick={() => {
                navigator.clipboard.writeText(e[0]);
                alert(`Copied "${e[0]}" to clipboard!`); // Example of feedback; replace with more sophisticated feedback in production
              }}
              style={{
                cursor: 'pointer',
                transform: indexHover === i ? 'scale(1.1)' : 'none',
                transition: 'background-color 0.3s, transform 0.3s',
                backgroundColor: indexHover === i ? '#f0f0f0' : '',
              }}
            >
              {indexHover === i ? <strong>{e[1]}</strong> : e[0]}
            </div>
          ))}
      </div>
    </div>
  );
};

export default WordSortCard;
