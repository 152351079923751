import { useEffect, useState } from 'react';
import WordSortCard from './components/WordSortCard';
import './wordsort.css';

const WordLevel = ({ allUserActivityData }) => {
  //State
  const [wordSort, setWordSort] = useState({});

  //Tools
  const threshold = {
    strong: 76,
    medium: 60,
    weak: 0,
  };

  //Word frequency calculator
  const wordFrequencyCalc = function () {
    let allWordStatsObj = {};

    if (allUserActivityData === undefined) return;

    allUserActivityData.forEach((currentActivity) => {
      if (currentActivity.plants) {
        //Turn into own function
        currentActivity.plants.forEach((plant) => {
          if (allWordStatsObj[plant] === undefined) {
            //If there's no plant entry
            allWordStatsObj[plant] = {};
            allWordStatsObj[plant]['appearance'] = 1;
            allWordStatsObj[plant]['plant'] = 1;
          } else if (allWordStatsObj[plant]['plant'] === undefined) {
            //If there has been an entry, but for seed only
            allWordStatsObj[plant]['plant'] = 1;
            allWordStatsObj[plant]['appearance']++;
          } else if (allWordStatsObj[plant]['plant']) {
            //If there is already an entry
            allWordStatsObj[plant]['plant']++;
            allWordStatsObj[plant]['appearance']++;
          }
        });
      }

      if (currentActivity.seeds) {
        //Turn into own function
        currentActivity.seeds.forEach((seed) => {
          if (allWordStatsObj[seed] === undefined) {
            //If there's no seed entry
            allWordStatsObj[seed] = {};
            allWordStatsObj[seed]['appearance'] = 1;
            allWordStatsObj[seed]['seed'] = 1;
          } else if (allWordStatsObj[seed]['seed'] === undefined) {
            //If there has been an entry, but for plant only
            allWordStatsObj[seed]['seed'] = 1;
            allWordStatsObj[seed]['appearance']++;
          } else if (allWordStatsObj[seed]['seed']) {
            //If there is already an entry
            allWordStatsObj[seed]['seed']++;
            allWordStatsObj[seed]['appearance']++;
          }
        });
      }
    });

    //Turn into own function
    //Calculate the number of plants vs. the number of appearances
    for (const key in allWordStatsObj) {
      if (Object.hasOwnProperty.call(allWordStatsObj, key)) {
        const word = allWordStatsObj[key];

        if (word.plant) {
          const num = word.plant / word.appearance;
          allWordStatsObj[key]['percentage'] = Math.round(
            (num + Number.EPSILON) * 100
          );
        } else if (word.plant === undefined) {
          allWordStatsObj[key]['percentage'] = 0;
        }
      }
    }

    function wordSort() {
      const sortedWordObj = {
        weak: [],
        medium: [],
        strong: [],
      };

      for (const key in allWordStatsObj) {
        if (Object.hasOwnProperty.call(allWordStatsObj, key)) {
          const element = allWordStatsObj[key];
          if (element.percentage >= threshold.strong) {
            sortedWordObj.strong.push([key, element.percentage]);
          } else if (
            element.percentage < threshold.strong &&
            element.percentage >= threshold.medium
          ) {
            sortedWordObj.medium.push([key, element.percentage]);
          } else {
            sortedWordObj.weak.push([key, element.percentage]);
          }
        }
      }

      setWordSort(sortedWordObj);
    }
    wordSort();
  };

  useEffect(() => {
    wordFrequencyCalc();
  }, [allUserActivityData]);

  return (
    <div className="wordlevel--container">
      <WordSortCard strength={'weak'} wordSort={wordSort} />
      <WordSortCard strength={'medium'} wordSort={wordSort} />
      <WordSortCard strength={'strong'} wordSort={wordSort} />
    </div>
  );
};

export default WordLevel;
