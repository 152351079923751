//Dependencies
import './App.css';

//Routing
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

//Components
import { useState, useEffect } from 'react';
import Body from './components/body/Body';
import Login from './pages/login/Login';
import Dashboard from './pages/dashboard/Dashboard';
import AdminDashboard from './pages/admin-dashboard/AdminDashboard';

//Firebase
import { auth, provider, db } from './firebase-config';
import { signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import { collection, addDoc } from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import Nav from './components/nav/Nav';

function App() {
  //State
  const [plantsList, setPlantsList] = useState([]);
  const [seedsList, setSeedsList] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [adminLoggedIn, setAdminLoggedIn] = useState(false);
  const [showNavLinks, setShowNavLinks] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoggedIn(true);
      }
    });
  }, []);

  //Variables
  const difficulty = seedsList.length / (seedsList.length + plantsList.length);
  // const auth = getAuth();
  const user = auth.currentUser;

  function handleLogin() {
    signInWithPopup(auth, provider).then((result) => {
      setLoggedIn(true);
    });
  }

  function handleLogout() {
    signOut(auth).then(() => {
      setLoggedIn(false);
    });
  }

  async function handleSave() {
    function getToday() {
      let today = new Date();
      const d = String(today.getDate()).padStart(2, '0');
      const m = String(today.getMonth() + 1).padStart(2, '0');
      const y = today.getFullYear();

      return y + m + d;
    }

    if (user) {
      const email = user.email;

      await addDoc(collection(db, email), {
        date: getToday(),
        plants: plantsList,
        seeds: seedsList.map((e) => e[0]),
      });
    }

    alert('Saved Success');

    //set plants and seeds list to []
    setPlantsList([]);
    setSeedsList([]);
  }

  return (
    <div className="App">
      <Router>
        <Nav
          handleLogin={handleLogin}
          handleLogout={handleLogout}
          loggedIn={loggedIn}
          showNavLinks={showNavLinks}
          setShowNavLinks={setShowNavLinks}
          handleSave={handleSave}
          difficulty={difficulty}
        />

        <Routes>
          <Route
            path="/"
            element={
              <Body
                plantsList={plantsList}
                seedsList={seedsList}
                setPlantsList={setPlantsList}
                setSeedsList={setSeedsList}
              />
            }
          />
          <Route
            path="/dashboard"
            element={<Dashboard loggedIn={loggedIn} user={user} />}
          />
          <Route
            path="/admin-login"
            element={<Login setAdminLoggedIn={setAdminLoggedIn} />}
          />
          <Route
            path="/admin-dashboard"
            element={<AdminDashboard adminLoggedIn={adminLoggedIn} />}
          />
          <Route path="*" element={<h1>404</h1>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
