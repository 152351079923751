//React
import { useState } from 'react';
import './login.css';

//Router
import { useNavigate } from 'react-router-dom';

//Firebase
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase-config';

const Login = ({ setAdminLoggedIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Set the user object in the parent component to the authenticated user
        setAdminLoggedIn(true);
        navigate('/admin-dashboard');
      })
      .catch((error) => {
        // Handle any login errors here
        console.error(error);
      });
  };

  return (
    <div className="login--container">
      <form onSubmit={handleSubmit}>
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <button type="submit" className="login--button">
          Log In
        </button>
      </form>
    </div>
  );
};

export default Login;
