import React from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Link } from 'react-router-dom';

import './nav.css';
import { useHSKLevelContext } from '../../context/hskLevelContext';

const Nav = ({
  setShowNavLinks,
  difficulty,
  loggedIn,
  handleLogin,
  handleLogout,
  showNavLinks,
  handleSave,
}) => {
  const { HSKLevel, toggleHSKLevel } = useHSKLevelContext();

  console.log(HSKLevel, 'hsk level');

  return (
    <nav id={showNavLinks ? 'showNav' : ''}>
      <div className="nav--left--side">
        <Link to="/word-garden">
          <div className="nav--title">Word Garden 词园</div>
        </Link>
      </div>
      <div className="nav--right--side">
        <button
          className="burger--button"
          onClick={() => setShowNavLinks(!showNavLinks)}
        >
          <GiHamburgerMenu style={{ fontSize: '2em' }} />
        </button>

        <div
          className="nav--links--container"
          id={showNavLinks ? 'hidden' : ''}
        >
          <div className="nav--select--container">
            <form>
              <select
                value={HSKLevel}
                onChange={(e) => toggleHSKLevel(e.target.value)}
                className="nav--select"
              >
                <option value="0">HSK Level</option>
                <option value="1">HSK 1</option>
                <option value="2">HSK 2</option>
                <option value="3">HSK 3</option>
                <option value="4">HSK 4</option>
                <option value="5">HSK 5</option>
                <option value="6">HSK 6</option>
              </select>
            </form>
          </div>
          {loggedIn === false ? (
            <button onClick={handleLogin}>Login</button>
          ) : (
            <>
              <Link to="/dashboard">Dashboard</Link>
              <button onClick={handleLogout}>Log Out</button>
              <button onClick={handleSave}>Save</button>
            </>
          )}
          <Link to="/admin-login">Admin Dashboard</Link>
          {HSKLevel !== 0 && (
            <div className="nav--difficulty">
              Unknown words:
              {!isNaN(difficulty)
                ? Math.round(difficulty * 100 + Number.EPSILON)
                : 0}
              %
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Nav;
