import React from 'react';
import './buttonshow.css';
const ButtonShow = ({ toggleDetailAndSelect }) => {
  return (
    <button className="show" onClick={toggleDetailAndSelect}>
      Show Answer
    </button>
  );
};

export default ButtonShow;
