import React from 'react';
import './gardenhead.css';
const GardenHead = (props) => {
  const today = (function () {
    let today = new Date();
    const d = String(today.getDate()).padStart(2, '0');
    const m = String(today.getMonth() + 1).padStart(2, '0');
    const y = today.getFullYear();

    const date = y + m + d;
    return parseInt(date);
  })();

  const [action, setAction] = React.useState(0);

  function handleActionChange(e) {
    if (e === 'plantsAndSeeds') {
    } else if (e === 'plants') {
      if (props.plantsList.length === 0) {
        alert('No words to download');
        setAction(0);
      } else {
        const downloadPlantsListFile = () => {
          const array = props.plantsList
            .map((e, i) => `${i + 1}. ${e} \n`)
            .join('');
          const element = document.createElement('a');
          const file = new Blob([array], {
            type: 'text/csv;charset=utf-8',
          });
          element.href = URL.createObjectURL(file);
          element.download = `${today}-plants`;
          document.body.appendChild(element); // Required for this to work in FireFox
          element.click();
        };

        downloadPlantsListFile();

        setAction(0);
      }
    } else if (e === 'seeds') {
      if (props.seedsList.length === 0) {
        alert('No words to download');
        setAction(0);
      } else {
        const downloadSeedsListFile = () => {
          const array = props.seedsList
            .map(
              (e, i) =>
                `${i + 1}. ${e[0]} / ${e[1]} / ${e[2].replace(/,/g, '，')}  \n`
            )
            .join('');
          const element = document.createElement('a');
          const file = new Blob([array], {
            type: 'text/csv;charset=utf-8',
          });
          element.href = URL.createObjectURL(file);
          element.download = `${today}-seeds`;
          document.body.appendChild(element); // Required for this to work in FireFox
          element.click();
        };

        downloadSeedsListFile();
        setAction(0);
      }
    }
  }

  //Streaks
  function streaksFireSizeLogic() {
    if (props.streak > 1 && props.streak <= 3) {
      return '1em';
    } else if (props.streak > 3 && props.streak <= 5) {
      return '1.25em';
    } else if (props.streak > 5 && props.streak <= 7) {
      return '1.5em';
    } else if (props.streak > 7 && props.streak <= 10) {
      return '1.75em';
    } else if (props.streak > 10 && props.streak <= 15) {
      return '2em';
    } else if (props.streak > 15) {
      return '2.5em';
    }
  }

  return (
    <div className="garden--plantsList--head">
      <div className="garden--plantsList--meta">
        <div className="garden--seedcount--section">
          Plants ({props.plantsList.length})
          <span>{props.streak > 1 && props.streak}</span>
        </div>
        <div className="garden--streak--section">
          {props.streak > 1 && (
            <div
              style={{
                fontSize: streaksFireSizeLogic(),
              }}
              className="pulsate-fwd"
            >
              🔥
            </div>
          )}
        </div>
        <div className="garden--download--text">
          <form>
            <select
              value={action}
              onChange={(e) => handleActionChange(e.target.value)}
              className="nav--select"
            >
              <option value="0">Select Download</option>
              <option value="plantsAndSeeds">Download All</option>
              <option value="plants">Download Plants</option>
              <option value="seeds">Download Seeds</option>
            </select>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GardenHead;
