//React
import { useState, useEffect } from 'react';
import './admindashboard.css';
import WordSort from '../dashboard/components/wordsort/WordSort';

//Firebase
import { db } from '../../firebase-config';
import { collection, getDocs } from 'firebase/firestore';

//Routing
import { Navigate } from 'react-router-dom';

//Icons
import { IoIosArrowDropdownCircle } from 'react-icons/io';

const AdminDashboard = ({ adminLoggedIn }) => {
  //State
  const [allUserData, setAllUserData] = useState([]);
  const [showHideUserWordSort, setShowHideUserWordSort] = useState({});

  //Users list
  const allUserEmail = ['hellotaukelim@gmail.com', 'gretadynra@gmail.com'];

  //Functions
  function setToggleSortedWords(index) {
    setShowHideUserWordSort((p) => {
      return {
        ...p,
        [allUserEmail[index]]: !p[allUserEmail[index]],
      };
    });
  }

  //Call firestore
  useEffect(() => {
    allUserEmail.forEach((e) => {
      setShowHideUserWordSort((p) => {
        return {
          ...p,
          [e]: false,
        };
      });
    });

    // Use Promise.all to retrieve data from each collection in parallel
    Promise.all(
      allUserEmail.map((collectionName) =>
        getDocs(collection(db, collectionName))
      )
    ).then((querySnapshots) => {
      // Map the query snapshots to an array of objects containing collection names and data
      const userCollectionData = querySnapshots.map((querySnapshot, index) => {
        //1. Get the collection name corresponding to the current query snapshot

        //2. Declare an array to store all documents
        const userCollectionData = [];

        //3. Map the document snapshots in the query snapshot to an array of data objects
        querySnapshot.forEach((doc) => {
          userCollectionData.push(doc.data());
        });

        //4. Return an object containing the collection name and data array
        return userCollectionData;
      });

      // Set the retrieved data in state
      setAllUserData(userCollectionData);
    });
  }, []);

  //Routing
  if (!adminLoggedIn) {
    return <Navigate to="/admin-login" replace={true} />;
  }

  const sessionRender = allUserData.map((sessionData, index) => {
    return (
      <div className="adminWordSortComponent" key={index}>
        <div className="adminWordSortComponent--header">
          <h2> {allUserEmail[index]}</h2>
          <IoIosArrowDropdownCircle
            onClick={() => setToggleSortedWords(index)}
            className="adminWordSortComponent--downArrow"
          />
        </div>
        {showHideUserWordSort[allUserEmail[index]] && (
          <WordSort sessionData={sessionData} />
        )}
      </div>
    );
  });

  return (
    <div className="admin--dashboard">
      <h1>Admin Dashboard</h1>
      {sessionRender}
    </div>
  );
};

export default AdminDashboard;
