//Imports
import { useState, useEffect } from 'react';
import './body.css';
import Data from '../../data.js';

//Components
import Garden from './components/garden/Garden';
import WordDisplayContainer from './components/wordDisplayContainer/WordDisplayContainer';
import { useHSKLevelContext } from '../../context/hskLevelContext.jsx';

const Body = ({ plantsList, seedsList, setPlantsList, setSeedsList }) => {
  //Context
  const { HSKLevel, toggleHSKLevel } = useHSKLevelContext();

  //State
  const [sessionWordQueue, setSessionWordQueue] = useState({});
  const [streak, setStreak] = useState(0);

  //*Variables
  const displayWord =
    HSKLevel > 0 && sessionWordQueue[HSKLevel]
      ? sessionWordQueue[HSKLevel][0]
      : null;
  //The word displayed in the Field is the first in line from our sessionWordQueue
  //Everytime we rerender as long as there is a HSKLevel selected, there will be a word assigned

  //*Effects
  useEffect(() => {
    // Shuffle all words
    // At the start of every session we shuffle the queue from our word bank
    function shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }

      return array;
    }

    setSessionWordQueue({
      1: shuffle(Data[1]),
      2: shuffle(Data[2]),
      3: shuffle(Data[3]),
      4: shuffle(Data[4]),
      5: shuffle(Data[5]),
      6: shuffle(Data[6]),
    });
  }, []);

  //*Functions
  function addPlantsList() {
    setSessionWordQueue((p) => ({
      ...p,
      [HSKLevel]: [...p[HSKLevel].slice(1, p[HSKLevel].length)],
    }));

    setStreak((p) => p + 1);

    setPlantsList((p) => [...p, displayWord.Hanzi]);
  }

  function addSeedsList() {
    //Insert the seed 15 + random number spaces behind the queue
    //Why 15? no reason
    const sliceIndex = Math.floor(Math.random() * (4 - 0) + 0);

    setSessionWordQueue((p) => ({
      ...p,
      [HSKLevel]: [
        ...p[HSKLevel].slice(1, sliceIndex + 16),
        p[HSKLevel][0],
        ...p[HSKLevel].slice(sliceIndex + 16),
      ],
    }));

    setStreak(0);

    setSeedsList((p) => [
      ...p,
      [displayWord.Hanzi, displayWord.Pinyin, displayWord.Definitions],
    ]);
  }

  return (
    <>
      <main>
        {HSKLevel > 0 ? (
          <WordDisplayContainer
            HSKLevel={HSKLevel}
            displayWord={displayWord}
            addPlantsList={addPlantsList}
            addSeedsList={addSeedsList}
          />
        ) : (
          <div className="body--select--container">
            <form>
              <select
                value={HSKLevel}
                onChange={(e) => toggleHSKLevel(e.target.value)}
                className="body--select"
              >
                <option value="0">Select Your HSK Level</option>
                <option value="1">HSK 1</option>
                <option value="2">HSK 2</option>
                <option value="3">HSK 3</option>
                <option value="4">HSK 4</option>
                <option value="5">HSK 5</option>
                <option value="6">HSK 6</option>
              </select>
            </form>
          </div>
        )}
      </main>
      <Garden plantsList={plantsList} seedsList={seedsList} streak={streak} />
    </>
  );
};

export default Body;
