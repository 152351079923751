import { useState, useEffect } from 'react';
import './dashboard.css';

//Components
import WordSort from './components/wordsort/WordSort';

//Firebase
import { db } from '../../firebase-config';
import { collection, getDocs } from 'firebase/firestore';

const Dashboard = ({ user }) => {
  //State
  const [allUserActivityData, setAllUserActivityData] = useState();

  //Reference to collection
  const sessionRef = collection(db, user.email);

  //Call firestore
  useEffect(() => {
    const getAllUserActivityData = async () => {
      const data = await getDocs(sessionRef);
      const docs = data.docs;
      setAllUserActivityData(docs.map((e) => ({ ...e.data(), id: e.id })));
    };

    getAllUserActivityData();
  }, []);

  return (
    <div>
      <WordSort allUserActivityData={allUserActivityData} />
    </div>
  );
};

export default Dashboard;
