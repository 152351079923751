import React from 'react';
import './worddisplaycontainer.css';

//Components
import Buttons from './components/buttons/Buttons';
import WordDisplay from './components/wordDisplay/WordDisplay';
import WordTranslated from './components/wordTranslated/WordTranslated';
import WordPinyin from './components/wordPinyin/WordPinyin';
import WordMeta from './components/wordMeta/WordMeta';

const WordDisplayContainer = ({ displayWord, addPlantsList, addSeedsList }) => {
  // State
  const [showDetailAndSelect, setShowDetailAndSelect] = React.useState(false);

  function toggleDetailAndSelect() {
    setShowDetailAndSelect((p) => !p);
  }

  function handleSound() {
    const sound = `https://hsk.academy/static/audio/mp3/words/${displayWord.Hanzi}.mp3`;
    const audio = new Audio(sound);

    if (!audio.paused) {
      audio.pause();
    } else {
      audio.play();
    }
  }

  return (
    <div className="word--display--container">
      <WordMeta
        handleSound={handleSound}
        showDetailAndSelect={showDetailAndSelect}
        displayWord={displayWord}
      />

      <WordDisplay word={displayWord.Hanzi} />

      <WordPinyin
        pinyin={displayWord.Pinyin}
        showDetailAndSelect={showDetailAndSelect}
      />

      <WordTranslated
        translatedWord={displayWord.Definitions}
        showDetailAndSelect={showDetailAndSelect}
      />

      <Buttons
        addPlantsList={addPlantsList}
        addSeedsList={addSeedsList}
        showDetailAndSelect={showDetailAndSelect}
        toggleDetailAndSelect={toggleDetailAndSelect}
      />
    </div>
  );
};

export default WordDisplayContainer;
